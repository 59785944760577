import { UserRole } from 'api-client/enums'
import { IUser } from 'api-client/types'

export const userKeys = {
    id: 'string',
    email: 'string',
    role: 'UserRole',
    phoneNumber: 'string',
    firstName: 'string',
    lastName: 'string',
}

export class User implements IUser {
    id?: string | undefined
    email: string
    role: UserRole
    phoneNumber: string
    firstName: string
    lastName: string

    constructor(p: IUser) {
        for (const key in p) {
            if (Object.prototype.hasOwnProperty.call(userKeys, key)) {
                this[key as keyof this] = p[key as keyof typeof p] as any
            }
        }
    }

    toJSON() {
        return { ...this }
    }
}