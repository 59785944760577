import { Box, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { SIDE_BAR_APP_ROUTES } from "config/sidebar"
import { useNavigate } from "react-router"
import SideBarActionButtons from "./SideBarActionButtons"

const SideBar = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  return (
    <Box className={classes.root}>
      <Box className={classes.innerContainer}>
        <Box className={classes.menuContainer}>
          {SIDE_BAR_APP_ROUTES.map(
            (menuItem: { label: string; link: string; icon: any }, index) => (
              <SideBarActionButtons
                key={index}
                onClick={() => navigate(`${menuItem.link}`)}
                icon={menuItem.icon}
              >
                {menuItem.label}
              </SideBarActionButtons>
            )
          )}
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "width": theme.dashboardWidth,
    // padding: theme.spacing(1),
    "position": "fixed",
    "top": `${theme.headerHeight}px`,
    "height": `calc(100% - ${theme.footerHeight}px - ${theme.headerHeight}px)`,
    "background": theme.colors.primary.main,
    "zIndex": 9999,
    "overflow": "auto",
    "&:hover": {
      transition: "width 0.5s ease-in-out",
      width: 250,
    },
    // '& :hover':{
    //   transition: "width 0.5s ease-in-out",
    //   width: 250,
    // }
  },
  innerContainer: {
    width: 250,
    position: "relative",
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
  },
  menuContainer: {
    width: 250,
    position: "relative",
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
  },
  menuItem: {
    width: "100%",
    padding: `${theme.spacing(2, 2)} !important`,
    color: `${theme.colors.white} !important`,
    marginBottom: `${theme.spacing(2)} !important`,
    borderTop: `1px solid ${theme.colors.darkGrey} !important`,
    borderBottom: `1px solid ${theme.colors.darkGrey} !important`,
  },
}))

export default SideBar
