import React from "react"

type SnackbarProviderProps = {
  state: boolean
  type?: "error" | "success"
  message?: string
  handleClose: React.Dispatch<any>
  openSnackbar(value: SnackbarProps): void
}

const initialState: SnackbarProviderProps = {
  state: undefined,
  handleClose: undefined,
  openSnackbar: undefined,
}

type SnackbarProps = {
  type: "error" | "success"
  message: string
}

export const SnackbarContext = React.createContext(initialState)

const { Provider } = SnackbarContext

const SnackbarProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState(false)
  const [type, setType] = React.useState<"error" | "success">("error")
  const [message, setMessage] = React.useState("Something Went Wrong")
  const handleClose = () => setState(false)
  const openSnackbar = ({ type, message }: SnackbarProps) => {
    setType(type)
    setMessage(message)
    setState(true)
  }
  const values = {
    state,
    type,
    message,
    handleClose,
    openSnackbar,
  }

  return <Provider value={values}>{children}</Provider>
}

export { SnackbarProvider }
