import { FirebaseNewsletter } from "api-client/firebase/newsletter";
import { Newsletter } from "api-client/models/Newsletter";
import { INewsletter } from "api-client/types";
import { IPagination } from "api-client/utils";
import React, { useCallback } from "react";
import { csvToArray } from "utils";
import { SnackbarContext } from "./UseSnackbar";
import _ from "lodash";
import { NewsletterGroup } from "api-client/models/NewsletterGroup";

type NewsletterContextProps = {
  newsletters: Array<Newsletter>;
  selected: Array<any>;
  setSelected?: React.Dispatch<any>;
  filtered: Array<Newsletter>;
  setFiltered?: React.Dispatch<any>;
  isLoading: boolean;
  fetchNewsletter?(): Promise<any>;
  importFromFile?(file: File): void;
  addNewsletter?(letter: INewsletter): Promise<any>;
  fetchMoreNewsletters?(pagination: IPagination): Promise<any>;
  searchNewsletters?(value: string, pageSize: number): void;
  deleteNewsletter?(newsletter: Newsletter): void;
  batchDeleteNewsletter?(payload: Map<string, NewsletterGroup[]>): void;
};

const initialState: NewsletterContextProps = {
  newsletters: [],
  selected: [],
  filtered: [],
  isLoading: false,
};

const newsletterApi = new FirebaseNewsletter();

export const NewsletterContext = React.createContext(initialState);

const { Consumer: NewsletterConsumer, Provider } = NewsletterContext;

const NewsletterProvider: React.FC = ({ children }) => {
  const [newsletters, setNewsletters] = React.useState(
    initialState.newsletters
  );
  const [isLoading, setIsLoading] = React.useState(initialState.isLoading);
  const [selected, setSelected] = React.useState(initialState.selected);
  const [filtered, setFiltered] = React.useState(initialState.filtered);
  const [searchTerm, setSearchTerm] = React.useState("");

  const useSnackbar = React.useContext(SnackbarContext);
  const { openSnackbar } = useSnackbar;

  const fetchNewsletter = useCallback(async () => {
    setIsLoading(true);
    const res = await newsletterApi.getNewsletters();
    if (res.type === "error")
      return openSnackbar({
        type: "error",
        message: "Something went wrong",
      });

    setNewsletters(res.data);
    setFiltered(res.data);
    setIsLoading(false);
  }, []);

  const searchNewsletters = async (value: string, pageSize: number) => {
    setSearchTerm(value);
    if (!value?.trim()) return setFiltered(newsletters);

    setIsLoading(true);

    const res = await newsletterApi.searchNewsletters(
      value.trim().toLowerCase(),
      pageSize
    );
    if (res.type === "error") {
      setFiltered([]);
    } else {
      setFiltered(res.data);
    }
    setIsLoading(false);
  };

  const fetchMoreNewsletters = useCallback(
    async (pagination: IPagination) => {
      const res = await newsletterApi.getNewsletters(pagination);
      if (res.type === "error") return;
      const temp = [...newsletters, ...res.data];
      setNewsletters(temp);
      setFiltered(temp);
    },
    [newsletters]
  );

  const addNewsletter = async (value: Newsletter) => {
    const res = await newsletterApi.createNewsletter(value);
    if (res.type === "error")
      return openSnackbar({
        type: "error",
        message: res.error?.message ?? "Something went wrong",
      });
    newsletters.push(value);
    setNewsletters(newsletters);
    if (!searchTerm) setFiltered(newsletters);
  };

  const batchDeleteNewsletter = async (payload: Map<string, NewsletterGroup[]>) => {
    await newsletterApi.batchDeleteNewsletters(payload);
    const list = Object.keys(payload);
    let temp = _.filter(newsletters, (item) => !list.includes(item.email));
    setNewsletters(temp);

    if (searchTerm) temp = _.filter(filtered, (item) => !list.includes(item.email));
    setFiltered(temp);
  };

  const deleteNewsletter = async (newsletter: Newsletter) => {
    await newsletterApi.deleteNewsletter(newsletter);
    let temp = _.filter(newsletters, (item) => item.email !== newsletter.email);
    setNewsletters(temp);

    if (searchTerm) temp = _.filter(filtered, (item) => item.email !== newsletter.email);
    setFiltered(temp);
  };

  const importFromFile = (file: File) => {
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const payload = csvToArray(e.target.result,",",true);
      const temp = [];
      const size = 500;
      for (var i = 0; i < payload.length; i += size)
        temp.push(payload.slice(i, i + size));
      openSnackbar({
        type: "success",
        message: "Newsletter Import started.",
      });
      let res = await newsletterApi.importNewsletters(temp);
      if (res.type === "error") {
        return openSnackbar({
          type: "error",
          message: res.error?.message ?? "Something went wrong",
        });
      }

      fetchNewsletter();
      openSnackbar({
        type: "success",
        message: "Newsletter imported successfully",
      });
    };
    fileReader.readAsText(file);
  };

  const values = {
    newsletters,
    setNewsletters,
    isLoading,
    selected,
    setSelected,
    filtered,
    setFiltered,
    fetchNewsletter,
    searchNewsletters,
    fetchMoreNewsletters,
    importFromFile,
    batchDeleteNewsletter,
    deleteNewsletter,
    addNewsletter,
  };

  return <Provider value={values}>{children}</Provider>;
};

export { NewsletterConsumer, NewsletterProvider };
