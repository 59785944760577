import {
  TableHead as MuiTableHead,
  TableHeadTypeMap,
  Theme,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import React, { CSSProperties } from "react"

type CN = { className?: string; style?: any | never | unknown | CSSProperties }

const TableHead: React.FC<Partial<TableHeadTypeMap> & CN> = ({
  children,
  className,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <MuiTableHead className={clsx(className, classes.tableHead)} {...rest}>
      {children}
    </MuiTableHead>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  tableHead: {
    color: theme.colors.common.black,
    width: "100%",
    display: "block !important",
    height: 60,
  },
}))

export default TableHead
