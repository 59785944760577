/* eslint-disable react-hooks/exhaustive-deps */
import { GroupAdd } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Newsletter } from "api-client/models/Newsletter";
import { NewsletterGroup } from "api-client/models/NewsletterGroup";
import { IPagination } from "api-client/utils";
import AddEmailToGroupForm from "components/AddEmailToGroupForm";
import AddNewsletterForm from "components/Newsletter/AddNewsletterForm";
import ActionCell from "components/Table/ActionCell";
import Table from "components/Table/Table";
import TableToolBar from "components/Table/TableToolBar";
import { NewsletterContext } from "contexts/NewsletterContext";
import { NewsletterGroupContext } from "contexts/NewsletterGroupContext";
import DashboardLayout from "layout/DashboardLayout";
import React, { useEffect, useState } from "react";
import { Row } from "react-table";
import _ from 'lodash';
import Chip from "@mui/material/Chip";

let timer;

const NewsletterView = () => {
  const [addEmailModal, setAddEmailModal] = React.useState(false);
  const [addEmailGroupModal, setAddEmailGroupModal] = React.useState(false);
  const [pagination, setPagination] = React.useState<IPagination>({
    pageSize: 10,
  });
  const [groups, setGroups] = React.useState<Map<string, NewsletterGroup[]>>(new Map<string, NewsletterGroup[]>());
  const useNewsletter = React.useContext(NewsletterContext);

  const {
    newsletters,
    selected,
    filtered,
    isLoading,
    setSelected,
    setFiltered,
    fetchNewsletter,
    importFromFile,
    searchNewsletters,
    deleteNewsletter,
    batchDeleteNewsletter,
    fetchMoreNewsletters
  } = useNewsletter;

  const {groupMembers, deleteNewslettersFromGroup} = React.useContext(NewsletterGroupContext);

  useEffect(() => {
    evaluateMemberShip();
  }, [groupMembers]);

  useEffect(() => {
    if (newsletters.length !== 0) {
      setFiltered(newsletters);
      return;
    }
    fetchNewsletter();
  }, []);

  useEffect(() => {
    evaluateMemberShip()
  }, [filtered]);

  const evaluateMemberShip = () => {
    let keys = Object.keys(groupMembers);
    let groups = {};
    filtered.forEach(newsletter => {
      let email = newsletter.email.trim().toLowerCase();
      keys.forEach(key => {
        if(!groupMembers[key].members){
          return;
        }
        let index = _.findIndex(groupMembers[key].members, (item : Newsletter) => email === item.email.trim());
        if(index === -1) return;
        if(groups[email]){
          groups[email].push(groupMembers[key].group);
        }else{
          groups[email] = [groupMembers[key].group];
        }
      })
    })
    setGroups({...groups as any});
  }

  const handleDelete = async (payload: Newsletter) => {
    deleteNewsletter(payload);
    let email = payload.email.trim().toLowerCase();
    if(groups[email])
      deleteNewslettersFromGroup(groups[email], email);
  };

  const handleSelectedDelete = async (
    e: React.MouseEvent<HTMLButtonElement>,
    selected: Array<Row>
  ) => {
    e.preventDefault();
    let map = {};
    selected.forEach(row => {
      const newsletter: Newsletter = row.original as any;
      map[newsletter.email] = groups[newsletter.email.toLowerCase() as string]
    });
    batchDeleteNewsletter(map as any);
  };

  const handleOnImportClick = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files[0];
    if (!file) return;

    importFromFile(file);
  };

  const handleSearch = (value: string) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(
      () => searchNewsletters(value, pagination.pageSize),
      750
    );
  };

  const columns: Array<any> = [
    { Header: "ID", accessor: "id", width: 100 },
    { Header: "Email", accessor: "email", flex: 1 },
    { Header: "Groups", flex: 1, Cell: ({row}) => {
      return <>
        {(groups[row.original.email.trim().toLowerCase()] ?? []).map((group) => (
          <Chip style={{padding: '3px'}} label={`${group?.label ?? ''}`} />
        ))}
        </>
    } },
    {
      Header: "Actions",
      width: 140,
      disableSortBy: true,
      disableFilters: true,
      disableResizing: true,
      maxWidth: 160,
      Cell: ({ row }: any) => (
        <ActionCell row={row} onDelete={() => handleDelete(row.original)} />
      ),
    },
  ];
  const [value, setSearchTerm] = useState("")
  return (
    <DashboardLayout>
      <Box
        sx={{
          display: "flex !important",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TableToolBar
          onAdd={() => setAddEmailModal(true)}
          onImport={(e) => handleOnImportClick(e)}
          onSearch={(value) => {
            handleSearch(value)
            setSearchTerm(value)
          }}
          searchValue={value}
        />
        <Table
          columns={columns}
          name="properties-table"
          data={filtered}
          loading={isLoading}
          pagination
          onSelect={() => undefined}
          onSelectedDelete={handleSelectedDelete}
          onLoadMore={(pagination) => {
            setPagination(pagination);
            fetchMoreNewsletters(pagination);
          }}
          useNonMemoizedRow
          action={{
            label: "Add to group",
            onClick: (e, rows) => {
              setAddEmailGroupModal(true);
              setSelected(rows);
            },
            icon: <GroupAdd />,
          }}
        />
      </Box>
      {addEmailModal && (
        <AddNewsletterForm
          state={addEmailModal}
          onClose={() => setAddEmailModal(false)}
        />
      )}
      {addEmailModal && (
        <AddNewsletterForm
          state={addEmailModal}
          onClose={() => setAddEmailModal(false)}
        />
      )}
      {addEmailModal && (
        <AddNewsletterForm
          state={addEmailModal}
          onClose={() => setAddEmailModal(false)}
        />
      )}
      <AddEmailToGroupForm
        state={addEmailGroupModal}
        onClose={() => setAddEmailGroupModal(false)}
        selectedEmails={selected}
      />
    </DashboardLayout>
  );
};
export default NewsletterView;
