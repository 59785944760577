import { FirebaseEmailTemplate } from "api-client/firebase/template/FirebaseEmailTemplate"
import { IEmailTemplate } from "api-client/types"
import React, { useEffect } from "react"

type EmailTemplatesProps = {
  templates: Array<IEmailTemplate>
  setTemplates: React.Dispatch<any>
}

const initialState: EmailTemplatesProps = {
  templates: [],
  setTemplates: undefined,
}

export const EmailTemplatesContext = React.createContext(initialState)

const { Consumer: UserConsumer, Provider } = EmailTemplatesContext

const emailAPI = new FirebaseEmailTemplate()

const EmailTemplatesProvider: React.FC = ({ children }) => {
  const [templates, setTemplates] = React.useState(initialState.templates)
  const values = {
    templates, setTemplates
  }
  useEffect(()=>{
    emailAPI.getTemplates().then((data) => {
      if(data.type === "data"){
        setTemplates(data.data)
      }
    })
  },[])

  return <Provider value={values}>{children}</Provider>
}

export { UserConsumer, EmailTemplatesProvider }
