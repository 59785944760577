import { alpha, Box, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"

interface SideBarActionButtons {
  icon: JSX.Element
  onClick(e: React.MouseEvent<HTMLDivElement>): void
  children: React.ReactChild
}
const SideBarActionButtons: React.FC<SideBarActionButtons> = ({
  icon,
  onClick,
  children,
}: SideBarActionButtons) => {
  const classes = useStyles()
  return (
    <Box className={classes.button} onClick={onClick}>
      <Box className={classes.buttonIcon}>{icon}</Box>
      <Box className={classes.buttonContent}>
        <Typography>{children}</Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    "width": "100%",
    "display": "flex",
    "cursor": "pointer",
    "alignItems": "center",
    "height": 60,
    "&:hover": {
      backgroundColor: alpha(theme.colors.darkGrey, 0.1),
      borderTop: `1px solid ${theme.colors.grey}`,
      borderBottom: `1px solid ${theme.colors.grey}`,
    },
  },
  buttonIcon: {
    width: "60px",
    display: "flex",
    justifyContent: "center",
    color: "white",
  },
  buttonContent: {
    color: theme.colors.white,
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
}))
export default SideBarActionButtons
