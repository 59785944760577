import { Button, TextField, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useFormik } from "formik"
import React from "react"
import * as yup from "yup"
type BOPFormView = {
  onCreateNewForm(values: any): void
  loading: boolean
}

const BuyerBrokerForm: React.FC<BOPFormView> = ({
  onCreateNewForm,
  loading,
}: BOPFormView) => {
  const classes = useStyles()
  const formik = useFormik({
    initialValues: {
      address: "",
      purchasePrice: "",
      sellers: "",
      purchaser: "",
      commission: "",
      data: "",
      note: "",
    },
    validationSchema: ValidationScheme,
    onSubmit: onCreateNewForm,
  })
  return (
    <div className={classes.root}>
      <div className={classes.formContent}>
        <div className={classes.formTextField}>
          <TextField
            label={"Seller"}
            name="sellers"
            onChange={formik.handleChange}
            helperText={formik.errors.formTitle}
            fullWidth
          />
        </div>
        <div className={classes.formTextField}>
          <TextField
            label={"purchaser"}
            name="purchaser"
            onChange={formik.handleChange}
            helperText={formik.errors.formDescription}
            fullWidth
          />
        </div>
        <div className={classes.formTextField}>
          <TextField
            label={"commission"}
            name="commission"
            onChange={formik.handleChange}
            helperText={formik.errors.formDescription}
            fullWidth
          />
        </div>
        <div className={classes.formTextField}>
          <TextField
            label={"purchasePrice"}
            name="purchasePrice"
            onChange={formik.handleChange}
            type="number"
            helperText={formik.errors.formDescription}
            fullWidth
          />
        </div>
        <div className={classes.formTextField}>
          <TextField
            label={"address"}
            name="address"
            onChange={formik.handleChange}
            helperText={formik.errors.formDescription}
            fullWidth
          />
        </div>
        <div className={classes.formTextField}>
          <Typography>Date</Typography>
          <TextField
            name="data"
            type="date"
            onChange={formik.handleChange}
            helperText={formik.errors.formDescription}
            fullWidth
          />
        </div>
        <div className={classes.formTextField}>
          <Typography>Note</Typography>
          <TextField
            name="note"
            type="note"
            onChange={formik.handleChange}
            minRows={4}
            multiline
            helperText={formik.errors.formDescription}
            fullWidth
          />
        </div>
      </div>
      <div className={classes.formAction}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => formik.handleSubmit()}
          disableRipple
          disabled={loading}
        >
          Generate Form
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => formik.handleSubmit()}
          disableRipple
          disabled={loading}
        >
          Save Form
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    padding: theme.spacing(2),
  },
  headerTitle: {
    fontSize: "2rem",
    margin: 0,
  },
  closeBtn: {
    position: "absolute !important" as any,
    right: 0,
    top: 0,
  },
  formContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: theme.spacing(2),
  },
  formTextField: {
    padding: theme.spacing(1),
  },
  formAction: {
    padding: theme.spacing(2),
    backgroundColor: theme.colors.lightGrey,
    borderTop: `1px solid ${theme.colors.lightBlack}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
}))

const ValidationScheme = yup.object().shape({
  address: yup.string().required("Required"),
  sellers: yup.string().required("Required"),
  purchasePrice: yup.number().required("Required"),
  commission: yup.string().required(),
})

export default BuyerBrokerForm
