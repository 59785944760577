import { storage } from "api-client/config/firebase"
import { FirebaseBucket } from "api-client/enums"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {v4 as uuid} from "uuid"

export class FirebaseStorage {
  uploadImage = async (file: File) => {
    try {
      const storageRef = ref(storage, `${FirebaseBucket.Properties}/${uuid()}-${file.name}`)
      await uploadBytes(storageRef, file, {
        contentType: file.type,
      })
      const link = await getDownloadURL(storageRef);
      return {
        type: "data",
        data: link,
      }
    } catch (error) {
      return {
        type: "error",
        data: error,
      }
    }
  }
}
