import DashboardLayout from "layout/DashboardLayout";
import { makeStyles } from "@mui/styles";
import { Box, Button, Theme, Typography } from "@mui/material";
import Table from "components/Table/Table";
import { Column } from "react-table";
import { useContext, useEffect, useState } from "react";
import { IUser } from "api-client/types";
import { FirebaseUser } from "api-client/firebase/user";
import { UserRole } from "api-client/enums";
import { SnackbarContext } from "contexts/UseSnackbar";
import _ from "lodash";

const userApi = new FirebaseUser();

const UsersView = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Array<IUser>>([]);
  const { openSnackbar } = useContext(SnackbarContext);

  const fetchUsers = async () => {
    setLoading(true);
    const res = await userApi.fetchUsers();
    if (res.type === "data") setUsers(res.data);
    setLoading(false);
  };

  const updateUserClaim = async (userId: string, role: UserRole) => {
    try {
      const newRole = role === UserRole.ADMIN ? UserRole.USER : UserRole.ADMIN;
      await userApi.updateAdminStatus(
        userId,
        newRole
      );
      openSnackbar({
        type: "success",
        message: "User role updated",
      });
      setLoading(true);
      const index = _.findIndex(users, (user) => user.id === userId);
      users[index].role = newRole;
      setUsers(users);
      setLoading(false);
    } catch (e) {
      openSnackbar({
        type: "error",
        message: "Error updating user role",
      });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const columns: Array<Column<any>> = [
    { Header: "ID", accessor: "id", width: 100 },
    { Header: "Email", accessor: "email", width: 80 },
    {
      Header: "Actions",
      width: 160,
      disableSortBy: true,
      disableFilters: true,
      disableResizing: true,
      Cell: ({ row }) => (
        <div>
          <Button
            variant="contained"
            onClick={() => {
              updateUserClaim(row.original.id, row.original.role);
            }}
          >
            {row.original.role === UserRole.USER
              ? "Make Admin"
              : "Remove as Admin"}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box>
          <Typography component={"h2"} variant="h4">
            Users
          </Typography>
        </Box>
        <Table
          name="users-table"
          data={users}
          pagination
          loading={loading}
          columns={columns}
        />
      </Box>
    </DashboardLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
  },
}));

export default UsersView;
