import { Newsletter } from "../Newsletter"

export class NewsletterGroup {
  id?: string
  label: string
  members?: Array<Newsletter>
  constructor(p?: any) {
    if(!p) return
    this.id = p.id
    this.label = p?.label
  }
}

