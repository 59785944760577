import { TableBody as MuiTableBody, TableBodyTypeMap } from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import React, { CSSProperties } from "react"

type CN = { className?: string; style?: any | never | unknown | CSSProperties }

const TableBody: React.FC<Partial<TableBodyTypeMap> & CN> = ({
  children,
  className,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <MuiTableBody className={clsx(className, classes.tableBody)} {...rest}>
      {children}
    </MuiTableBody>
  )
}

const useStyles = makeStyles(() => ({
  tableBody: {
    display: "block !important",
    height: "calc(100vh - 450px) !important",
    overflow: "hidden",
    overflowY: "scroll",
    flex: 1,
  },
}))

export default TableBody
