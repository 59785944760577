import { FirebaseProperty } from "api-client/firebase/property"
import { IProperty } from "api-client/types"
import { IPagination, propertyImportCast } from "api-client/utils"
import React, { useCallback, useEffect } from "react"
import { csvToArray } from "utils"
import { SnackbarContext } from "./UseSnackbar"
import { Status } from "interfaces"
import _ from "lodash"
import { Row } from "react-table"
import { geohashForLocation } from "geofire-common"
import { parseAddressToLatLng } from "geocode"
import { create, insertBatch, search } from "@lyrasearch/lyra"

type PropertyContextProps = {
  properties: Array<IProperty>
  filtered: Array<IProperty>
  setFiltered?: React.Dispatch<any>
  isLoading: boolean
  setIsLoading?: React.Dispatch<any>
  getProperties?(status?: string): Promise<any>
  importFromFile?(file: File): void
  createProperty?(letter: IProperty): Promise<any>
  fetchMoreProperties?(pagination: IPagination): Promise<any>
  searchProperties?(value: string, pageSize: number): void
  deleteProperty?(property: IProperty): void
  deleteProperties?(list: Array<Row<IProperty>>): void
  republishProperty?(id: string): Promise<any>
  copyProperty?(property: IProperty): Promise<any>
  updatePropertyStatus?(property: IProperty, status: Status): Promise<any>
  updateProperty?(property: IProperty): Promise<any>
  updatePropertiesStatus?(
    selected: Array<Row<IProperty>>,
    status: Status
  ): Promise<any>
  searchTerm?: string
}

const initialState: PropertyContextProps = {
  properties: [],
  filtered: [],
  isLoading: false,
}

const propertyApi = new FirebaseProperty()

const db = create({
  schema: {
    id: "string",
    title: "string",
    sourceName: "string",
    streetAddress: "string",
    city: "string",
    state: "string",
    zipCode: "string",
  },
})

export const PropertyContext = React.createContext(initialState)

const { Consumer: PropertyConsumer, Provider } = PropertyContext

const PropertyProvider: React.FC = ({ children }) => {
  const [properties, setProperties] = React.useState(initialState.properties)
  const [isLoading, setIsLoading] = React.useState(initialState.isLoading)
  const [filtered, setFiltered] = React.useState(initialState.filtered)
  const [searchTerm, setSearchTerm] = React.useState("")

  const init = async () => {
    const doc = _.map(properties, (property) => ({
      title: property.title,
      id: property.id,
      sourceName: _.lowerCase(property.sourceName),
      streetAddress: property.streetAddress,
      city: property.city,
      state: property.state,
      zipCode: property.zipCode,
    }))
    await insertBatch(db,  doc, {
      batchSize: Number(properties.length),
      language: "english",
    })
  }

  useEffect(()=>{
    init()
  },[properties])

  const useSnackbar = React.useContext(SnackbarContext)
  const { openSnackbar } = useSnackbar

  const getProperties = useCallback(async (status = 'all') => {
    setIsLoading(true)
    const res = await propertyApi.readProperties(undefined, status)
    if (res.type === "error")
      return openSnackbar({
        type: "error",
        message: "Something went wrong",
      })

    const temp = [...res.data]

    setProperties(temp)
    setFiltered(temp)
    setIsLoading(false)
  }, [openSnackbar])

  const searchProperties = async (value: string, pageSize: number) => {
    const trimmed = _.trim(value)
    setIsLoading(true)
    setSearchTerm(value)
    if (trimmed === "") {
      setIsLoading(false)
      return setFiltered(properties)
    }
    const {hits} = search(db, {
      term: trimmed,
      properties: "*",
      limit: 1000000,
    });
    
    const filteredProperties = _.filter(properties, (properties) => hits.some(({document})=> document.id === properties.id ))
    setFiltered(filteredProperties)
    setInterval(() => setIsLoading(false), 1000)
    
  }

  const createProperty = async (property: IProperty) => {
    if (property.streetAddress) {
      const { lat, lng } = await parseAddressToLatLng(
        `${property.streetAddress} ${property.city} ${property.zipCode} ${property.state}`
      )
      const geohash = geohashForLocation([lat, lng])
      property.lat = lat
      property.lng = lng
      property.geohash = geohash
    }
    const res = await propertyApi.createProperty(property)
    if (res.type === "error") throw Error(res.error as string)

    properties.push(property)
    setProperties(properties)
  }

  const fetchMoreProperties = async (pagination: IPagination) => {
    const res = await propertyApi.readProperties(pagination)
    if (res.type === "error") return
    if (res.data.length == 0) return
    const temp = [...properties, ...res.data]
    setProperties(temp)
    setFiltered(temp)
  }

  const deleteProperties = async (list: Array<Row<IProperty>>) => {
    try {
      const rows = list.map((row) => row.original)
      const res = await propertyApi.deleteProperties(rows)
      if (res.type === "error") throw Error(res.error)

      const ids = rows.map((item) => item.id)
      let temp = _.filter(properties, (item) => !ids.includes(item.id))
      setProperties(filtered)

      if (searchTerm)
        temp = _.filter(filtered, (item) => !ids.includes(item.id))
      setFiltered(temp)

      openSnackbar({
        type: "success",
        message: "Properties deleted successfully",
      })
    } catch (error) {
      openSnackbar({
        type: "error",
        message: "Error deleting properties",
      })
    }
  }

  const deleteProperty = async (property: IProperty) => {
    try {
      const res = await propertyApi.deleteProperty({ id: property.id })
      if (res.type === "error") throw new Error(res.error)

      let temp = _.filter(properties, (item) => item.id !== property.id)
      setProperties(temp)

      if (searchTerm)
        temp = _.filter(filtered, (item) => item.id !== property.id)
      setFiltered(temp)

      openSnackbar({
        type: "success",
        message: res.data,
      })
    } catch (e) {
      openSnackbar({
        type: "error",
        message: "Something went wrong",
      })
    }
  }

  const importFromFile = (file: File) => {
    const fileReader = new FileReader()
    fileReader.onload = async (e) => {
      const payload = csvToArray(
        e.target.result,
        ",",
        false,
        propertyImportCast
      )
      const temp = []
      const size = 500
      for (var i = 0; i < payload.length; i += size)
        temp.push(payload.slice(i, i + size))
      openSnackbar({
        type: "success",
        message: "Properties Import started.",
      })

      let res = await propertyApi.importProperties(temp)
      if (res.type === "error") {
        return openSnackbar({
          type: "error",
          message: res.error?.message ?? "Something went wrong",
        })
      }

      getProperties()

      openSnackbar({
        type: "success",
        message: "Newsletter imported successfully",
      })
    }
    fileReader.readAsText(file)
  }

  const republishProperty = async (id) => {
    const res = await propertyApi.republishProperty(id)
    if (res.type === "error") {
      openSnackbar({
        type: "error",
        message: "Error publishing property",
      })
    } else {
      openSnackbar({
        type: "success",
        message: "Property republished successfully",
      })
    }
  }

  const copyProperty = async (property: any) => {
    try {
      const res = await propertyApi.duplicateProperty(property)
      if (res.type === "error") {
        return openSnackbar({
          type: "error",
          message: "Something went wrong",
        })
      }
      properties.push(res.data)
      setProperties(properties)
      setFiltered(properties)
    } catch {
      openSnackbar({
        type: "error",
        message: "Something went wrong",
      })
    }
  }

  const updateProperty = async (property: IProperty) => {
    if (property.streetAddress) {
      const { lat, lng } = await parseAddressToLatLng(
        `${property.streetAddress} ${property.city} ${property.zipCode} ${property.state}`
      )
      const geohash = geohashForLocation([lat, lng])
      property.lat = lat
      property.lng = lng
      property.geohash = geohash
    }
    const res = await propertyApi.updateProperty({
      id: property.id,
      data: property,
    })
    if (res.type === "data") {
      const index = _.findIndex(properties, (item) => item.id === property.id)
      properties[index] = property
      setProperties(properties)
      setFiltered(properties)
    } else {
      throw Error(res.error as string)
    }
  }

  const updatePropertyStatus = async (property: IProperty, status: Status) => {
    try {
      const res = await propertyApi.updatePropertyStatus(property, status)
      if (res.type === "error") {
        openSnackbar({
          type: "error",
          message: "Error updating property",
        })
      }
      const index = _.findIndex(properties, (item) => item.id === property.id)
      properties[index].status = status

      setProperties(properties)
      setFiltered(properties)
    } catch (error) {
      openSnackbar({
        type: "error",
        message: "Something went wrong",
      })
    }
  }

  const updatePropertiesStatus = async (
    selected: Array<Row<IProperty>>,
    status: Status
  ) => {
    try {
      const temp = selected.map((item) => item.original)
      const res = await propertyApi.updatePropertiesStatus(temp, status)
      if (res.type === "error") throw Error(res.error)

      const ids = temp.map((item) => item.id)
      for (var i = 0; i < properties.length; i++) {
        if (ids.includes(properties[i].id)) {
          properties[i].status = status
        }
      }

      setProperties(properties)
      setFiltered(properties)

      openSnackbar({
        type: "success",
        message: res.data,
      })
    } catch (error) {
      openSnackbar({
        type: "error",
        message: "Error updating status",
      })
    }
  }

  const values = {
    properties,
    setProperties,
    isLoading,
    filtered,
    setIsLoading,
    setFiltered,
    getProperties,
    searchProperties,
    fetchMoreProperties,
    importFromFile,
    deleteProperties,
    deleteProperty,
    createProperty,
    republishProperty,
    copyProperty,
    updatePropertyStatus,
    updatePropertiesStatus,
    updateProperty,
    searchTerm
  }

  return <Provider value={values}>{children}</Provider>
}

export { PropertyConsumer, PropertyProvider }
