import { FormField } from "./forms"

const CREATE_BROKER: Array<FormField> = [
  {
    label: "Full Name",
    name: "name",
    formFieldtype: "input",
    inputType: "text",
    col: true,
  },
  {
    label: "Email",
    name: "email",
    formFieldtype: "input",
    inputType: "email",
    col: true,
  },
  {
    label: "Phone Number",
    name: "phoneNumber",
    formFieldtype: "input",
    inputType: "text",
    col: true,
  },
  {
    label: "Broker Title",
    name: "title",
    formFieldtype: "input",
    inputType: "text",
    col: true,
  },
  {
    label: "Brokerage Name",
    name: "brokerageName",
    formFieldtype: "input",
    inputType: "text",
    col: true,
  },
]

export { CREATE_BROKER }
