import { Button, Divider, Grid, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Theme } from "@mui/system"
import { FirebaseStorage } from "api-client/firebase/storage"
import { Storage } from "aws-amplify"
import clsx from "clsx"
import FormInputField from "components/Atoms/FormField"
import { FormField } from "CONSTANTS/forms"
import { SnackbarContext } from "contexts/UseSnackbar"
import { useFormik } from "formik"
import _ from "lodash"
import React from "react"

const storageApi = new FirebaseStorage();

type FormProps = {
  formikProps: {
    validationSchema: unknown
    initialValues: any
    onSubmit: any
  }
  formData: Array<FormField>
  submitLabel: string
  fieldsDisabled?: boolean
  name?: string
  hideButton?: boolean
}

const Form: React.FC<FormProps> = ({
  formikProps,
  formData,
  submitLabel,
  fieldsDisabled,
  name,
  hideButton,
}: FormProps) => {
  const classes = useStyles()
  const [propertyImages, setPropertyImage] = React.useState([
    ...(formikProps.initialValues.images || ""),
  ])
  const formik = useFormik({ ...formikProps })
  const [isImageLoading, setIsImageLoading] = React.useState(false)
  const [deletedFiles, setDeletedFiles] = React.useState<Array<any>>([])

  const useSnackbar = React.useContext(SnackbarContext);
  const { openSnackbar } = useSnackbar;

  React.useEffect(() => {
    if (formik.dirty || fieldsDisabled) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [formik.dirty])

  const onMediaSelect = async (values: Array<any>) => {
    setIsImageLoading(true)
    const temp = _.clone(propertyImages)
    const keys = _.clone(formik.values.images)
    _.mapValues(values, async (file, index) => {
      const res = await storageApi.uploadImage(file);

      if(res.type == 'error'){
          openSnackbar({
            type: "error",
            message: "Something went wrong",
          });
          return setIsImageLoading(false);
      } 

      formik.setValues({
        ...formik.values,
        featuredImage: res.data,
      })
      keys.push(res.data)
      temp.push(file)
      formik.setValues({
        ...formik.values,
        images: keys,
      })

      if (values.length - 1 === Number(index)) setIsImageLoading(false)
    })
    setPropertyImage(temp)
  }

  const deleteImage = async (index: string | number) => {
    setIsImageLoading(true)
    const temp = _.clone(propertyImages)
    const deletedFilesArray = _.clone(deletedFiles)
    deletedFilesArray.push(temp[index])
    _.remove(temp, (val) => temp[index] === val)
    formik.setValues({
      ...formik.values,
      images: temp,
      featuredImage: temp[0] ? temp[0] : "",
    })
    setPropertyImage(temp)
    setIsImageLoading(false)
    setDeletedFiles(deletedFilesArray)
  }

  const deleteImagesFromStorage = async () => {
    _.map(deletedFiles, async (value) => {
      await Storage.remove(value)
    })
  }

  React.useEffect(() => {
    if (formik.isSubmitting) deleteImagesFromStorage()
  }, [formik.isSubmitting])

  return (
    <Grid container direction="row" component="div" className={classes.root}>
      {formData.map((input: FormField) => {
        if (input.children) {
          return (
            <React.Fragment key={input.label}>
              <Grid item className={classes.section} md={12} sm={12}>
                <Typography variant="h4">{input.label}</Typography>
                <Divider />
              </Grid>
              {input.children.map((childInput) => {
                const props = {
                  ...childInput,
                  selectedMedia: propertyImages,
                  onMediaSelect: onMediaSelect,
                  mediaSelectProps: {
                    isLoading: isImageLoading,
                  },
                  handleDeleteFile: deleteImage,
                }
                return (
                  <FormInputField
                    key={childInput.label}
                    props={props}
                    onChange={formik.handleChange}
                    value={formik.values[childInput.name]}
                    error={Boolean(formik.errors[childInput.name])}
                    disabled={formik.isSubmitting || fieldsDisabled}
                  />
                )
              })}
            </React.Fragment>
          )
        }
        const props = {
          ...input,
        }
        return (
          <FormInputField
            key={input.label}
            props={props}
            onChange={formik.handleChange}
            value={formik.values[input.name]}
            error={Boolean(formik.errors[input.name])}
            disabled={formik.isSubmitting || fieldsDisabled}
          />
        )
      })}

      <Grid item md={12} className={classes.section}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => formik.handleSubmit()}
          id={`${name}-submit-button`}
          className={clsx({ [classes.hideButton]: hideButton })}
          fullWidth
        >
          {submitLabel}
        </Button>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    position: "relative",
    padding: theme.spacing(0, 2, 2, 2),
  },
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  closeBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 36,
    height: 36,
  },
  hideButton: {
    display: "none !important",
  },
  textFieldContainer: {
    padding: theme.spacing(2, 1),
  },
  section: {
    padding: theme.spacing(2, 1),
  },
  textFieldLabel: {
    padding: theme.spacing(2, 0),
  },
}))

export default Form
