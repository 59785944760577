import { IPromotion } from "api-client/types";

export class Promotion implements IPromotion {
  
  id?: string;
  link: string;
  name: string;
  status: string;
  createdAt: Date;
  
  constructor(p: IPromotion) {
    for (const key in p) {
      this[key as keyof this] = p[key as keyof typeof p] as any;
    }
  }
}
