import { db } from "api-client/config/firebase"
import { FirebaseCollection } from "api-client/enums"
import { Broker } from "api-client/models/Broker"
import { FirebaseResponse, IBroker } from "api-client/types"
import { collection, deleteDoc, doc, getDocs, setDoc } from "firebase/firestore"
import {v4 as uuid} from "uuid"

export class FirebaseBroker {

  createBroker = async (params: IBroker) => {
    try {
      if(!params.id) params.id = uuid();
      const docRef = doc(db, FirebaseCollection.Broker, params.id)
      await setDoc(docRef, params)
      return {
        type: "data",
        data: params,
      } as FirebaseResponse<IBroker>
    } catch (error) {
      return {
        type: "error",
        data: error,
      } as unknown as FirebaseResponse<string>
    }
  }

  async readBrokers(): Promise<FirebaseResponse<Broker[]>> {
    const result: Array<Broker> = []
    const collectionRef = collection(db, FirebaseCollection.Broker)
    const snapshot = await getDocs(collectionRef)
    snapshot.forEach((doc) => {
      const broker = new Broker({ id: doc.id, ...doc.data() } as IBroker)
      result.push(broker);
    })
    return {
      type: "data",
      data: result,
    }
  }

  async updateBroker(params: IBroker) {
    try {
      const docRef = doc(db, FirebaseCollection.Broker, params.id)
      await setDoc(docRef, params)
      return {
        type: "data",
        data: params,
      } as FirebaseResponse<IBroker>
    } catch (error) {
      return {
        type: "error",
        data: error,
      } as unknown as FirebaseResponse<string>
    }
  }

  async deleteBroker(brokerID: string) {
    try {
      const docRef = doc(db, FirebaseCollection.Broker, brokerID)
      await deleteDoc(docRef)
      return {
        type: "data",
        data: "Broker Deleted Successfully",
      } as FirebaseResponse<string>
    } catch (error) {
      return {
        type: "error",
        data: error,
      } as unknown as FirebaseResponse<string>
    }
  }
}
