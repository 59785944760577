import DashboardLayout from "layout/DashboardLayout";
import { makeStyles } from "@mui/styles";
import { Box, Button, Theme, Typography } from "@mui/material";
import Table from "components/Table/Table";
import { Column } from "react-table";
import { useEffect, useState } from "react";
import { IOffer } from "api-client/types";
import { FirebasePropertyOffer } from "api-client/firebase/offer";
import { useNavigate } from "react-router-dom";

const offerApi = new FirebasePropertyOffer();

const OffersView = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState<Array<IOffer>>([]);

  const fetchOffers = async () => {
    setLoading(true);
    const res = await offerApi.getLatestOffers();
    if(res.type === 'data')
      setOffers(res.data);
    setLoading(false)
  }

  useEffect(() =>{
    fetchOffers()
  }, [])

  const columns: Array<Column<any>> = [
    { Header: "Property Address", accessor: "propertyAddress", width: 250 },
    { Header: "Buyer Name", accessor: "buyerName", width: 140 },
    { Header: "Offer Price", width: 140, accessor: "offerAmount" },
    { Header: "Contact", width: 120, accessor: "contact" },
    { Header: "Offer Type", width: 80, accessor: "offerType" },
    {
      Header: "Actions",
      width: 180,
      disableSortBy: true,
      disableFilters: true,
      disableResizing: true,
      Cell: ({row}) => (
        <div>
          <Button variant="contained" onClick={() => {
              navigate(`/property/${row.original.propertyId}`)
          }}>
            View Property
          </Button>
          
        </div>
      ),
    }
  ];

  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box>
          <Typography component={"h2"} variant="h4">
            Latest Offers
          </Typography>
        </Box>
        <Table
          name="offers-table"
          data={offers}
          loading={loading}
          columns={columns}
        />
      </Box>
    </DashboardLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
  },
}));

export default OffersView;
