import { Box, Button, Dialog, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Form from "components/Atoms/Form"
import { CREATE_BROKER } from "CONSTANTS/broker"
import { BrokerContext } from "contexts/BrokerContext"
import { SnackbarContext } from "contexts/UseSnackbar"
import React from "react"
import * as yup from "yup"
import "yup-phone"

type EditBrokerDialogProps = {
  state: boolean
  onClose(): void
  broker: any
  onBrokerUpdated?(): void
}


const EditBrokerDialog: React.FC<EditBrokerDialogProps> = ({
  state,
  onClose,
  broker,
  onBrokerUpdated,
}: EditBrokerDialogProps) => {
  const classes = useStyles()
  const handleClose = () => {
    onClose()
  }

  const { openSnackbar } = React.useContext(SnackbarContext);
  const { updateBroker } = React.useContext(BrokerContext);

  const handleFormSubmit = async (values: any) => {
    try {
      await updateBroker(values);
      onClose()
      
    } catch (error) {
      openSnackbar({
        type: "error",
        message: "Error updating broker",
      });
    }
  }

  const handleUpdate = () => {
    const button = document.getElementById("update-broker-submit-button")
    button.click()
  }

  return (
    <Dialog
      open={state}
      onClose={handleClose}
      className={classes.root}
      classes={{
        paper: classes.container,
      }}
    >
      <Box>
        <Box className={classes.header}>
          <Typography variant="h4" component="h4">
            Edit Broker
          </Typography>
          <Box className={classes.headerActions}>
            <Button variant="contained" color="error" onClick={handleClose}>
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              Update
            </Button>
          </Box>
        </Box>
        <Form
          formData={CREATE_BROKER}
          formikProps={{
            initialValues: {
              id: broker.id,
              email: broker.email,
              name: broker.name,
              title: broker.title,
              brokerageName: broker.brokerageName,
              phoneNumber: broker.phoneNumber
            },
            validationSchema: validationSchema,
            onSubmit: (value) => handleFormSubmit(value),
          }}
          hideButton
          name="update-broker"
          submitLabel="Submit"
        />
      </Box>
    </Dialog>
  )
}

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  email: yup.string().email().required(),
  name: yup.string().required(),
  phoneNumber: yup.string().required(),
  title: yup.string().required(),
  brokerageName: yup.string().required(),
})

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 99999,
  },
  container: {
    minWidth: "80%",
    maxHeight: "100vh",
  },
  header: {
    display: "flex",
    width: "100%",
    borderBottom: "1px solid #000",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerActions: {
    "& button": {
      margin: 10,
    },
  },
}))

export default EditBrokerDialog
