import { Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'

type Table = {
    children:
        | Array<JSX.Element>
        | JSX.Element
        | React.ReactChildren
        | React.ReactChild
}

const Table: React.FC<Table> = ({ children }: Table) => {
    const classes = useStyles()
    return <Box className={classes.root}>{children}</Box>
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}))

export default Table
