import { Box, Dialog, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { IOffer } from 'api-client/types'
import { bool } from 'aws-sdk/clients/redshiftdata'
import _ from 'lodash'
import React from 'react'
import Table from './Atoms/Table'
import TableHeadCell from './Atoms/TableHeadCell'
import TableHeadRow from './Atoms/TableHeadRow'
import OfferTableRow from './OfferTableRow'

interface OfferTableProps {
    offers: Array<IOffer>
    openOffers: bool
    setOpenOffer(value: boolean): void
}

const OffersTable: React.FC<OfferTableProps> = ({offers, openOffers, setOpenOffer}) => {

    const classes = useStyles();

    return (
        <Dialog open={openOffers} onClose={() => {
            setOpenOffer(false)
        }}  >
        <Box className={classes.root}>
            <Table>
                <TableHeadRow>
                    <TableHeadCell>Property Address</TableHeadCell>
                    <TableHeadCell>Offer Amount</TableHeadCell>
                    <TableHeadCell>Offer Type</TableHeadCell>
                </TableHeadRow>
                <Box>
                    {_.map(offers, (offer: IOffer) => (
                        <OfferTableRow
                            key={offer.id}
                            offer={offer}
                        />
                    ))}
                </Box>
            </Table>
        </Box>
        </Dialog>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 20,
    },
    headerItem: {
        display: 'flex',
        gap: 10,
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        flex: 1,
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative',
    },
    tableHeader: {
        height: 40,
        display: 'flex',
        position: 'sticky',
        top: 0,
        backgroundColor: '#eee',
    },
    tableHeaderCell: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        padding: theme.spacing(1),
        color: theme.colors.overlayBlack,
        textTransform: 'uppercase',
        borderBottom: '1px solid #ccccd2',
        borderTop: '1px solid #ccccd2',
    },
}))
export default OffersTable
