import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { ColumnInstance } from 'react-table'
import useStyles from './TableStyles'

export const ResizeHandle = ({
  column,
}: {
  column: ColumnInstance<any>
}): ReactElement => {
  const classes = useStyles()
  return (
    <div
      {...column.getResizerProps()}
      style={{ cursor: 'col-resize' }} // override the useResizeColumns default
      className={clsx({
        [classes.resizeHandle]: true,
        handleActive: column.isResizing,
      })}
    />
  )
}
