import { Status } from "interfaces"

const PROPERTY_STATUS: Array<any> = [
  { label: Status.DRAFT, value: Status.DRAFT },
  { label: Status.CLOSED, value: Status.CLOSED },
  { label: Status.PUBLISHED, value: Status.PUBLISHED },
  { label: Status.PRIVATE, value: Status.PRIVATE },
  { label: Status.SOLD, value: Status.SOLD },
]

export { PROPERTY_STATUS }
