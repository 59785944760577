import { Checkbox, Theme } from "@mui/material"
import { styled } from "@mui/styles"
import React from "react"
import theme from "styles/theme"

const areEqual = (prevProps: any, nextProps: any) =>
  prevProps.checked === nextProps.checked &&
  prevProps.indeterminate === nextProps.indeterminate

const appTheme: Theme = theme as unknown as Theme

const HeaderCheckbox = React.memo(
  styled(Checkbox)({
    "fontSize": "0.8rem",
    "margin": `${appTheme.spacing(-2, -2, -2, -2)} !important `,
    "padding": appTheme.spacing(1),
    "color": `${appTheme.colors.common.white} !important`,
    "backgroundColor": `inherit !important`,
    "& svg": {
      width: appTheme.spacing(3),
      height: appTheme.spacing(3),
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  }),
  areEqual
)

export default HeaderCheckbox
