export enum FirebaseCollection {
    Newsletters = "Newsletters",
    NewsletterGroups = "NewsletterGroups",
    NewsletterGroupMembers = "members",
    Properties = "Properties",
    Broker = "Broker",
    Mail = "mail",
    Offers = "PropertyOffers",
    EmailTemplate = "EmailTemplates",
    Users = "Users",
    TeamMembers = "TeamMembers",
    Promotions = "Promotions",
    LendingInquiry = "LendingInquiries"
}

export enum FirebaseBucket{
    Properties = "properties"
}

export enum PropertyType {
    land = "land",
    reo = "reo",
    estate_sale = "estate_sale",
    mortgage = "mortgage",
    short_sale = "short_sale",
    contract_flip = "contract_flip",
    assigned_bid = "assigned_bid",
    foreclosure = "foreclosure",
    commercial = "commercial"
}

export enum LoanType {
  refinance = "REFINANCE"
}

export enum SourceStatus {
    CONTRACT = "CONTRACT",
    SOLD = "SOLD",
    AVAILABLE = "AVAILABLE",
  }

  export enum PropertyStatus {
    VACANT = "VACANT",
    OCCUPIED = "OCCUPIED",
  }

  export enum MailType {
    Property = "property",
    Properties = "Properties",
    Template = "Template"
  }

  export enum OfferType{
    MORTGAGE = 'mortgage',
    CASH = 'cash'
  }

  export enum FirebaseAuthErrors{
    USER_ALREADY_EXISTS = 'auth/email-already-in-use',
    INVALID_PASSWORD = 'auth/wrong-password',
    USER_NOT_FOUND = 'auth/user-not-found'
  }
  
  export enum UserRole{
    USER = 'USER',
    ADMIN = 'ADMIN'
  }