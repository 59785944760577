/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { INewsletterGroup } from "api-client/types";
import { NewsletterGroupContext } from "contexts/NewsletterGroupContext";
import { useFormik } from "formik";
import React from "react";

interface AddNewsletterFormProps {
  onClose(): void;
  state: boolean;
  selectedEmails: Array<any>;
}

const AddEmailToGroupForm = ({
  onClose,
  state,
  selectedEmails,
}: AddNewsletterFormProps) => {
  const useNewsletterGroup = React.useContext(NewsletterGroupContext);
  const { groups, addNewslettersToGroup, fetchGroups } = useNewsletterGroup;

  React.useEffect(() => {
    if (groups.length == 0) fetchGroups();
  }, []);

  const handleFormSubmit = async (values) => {
    const { group: groupID } = values;
    const emails = selectedEmails.map((item) => {
      return { email: item.original.email };
    });
    addNewslettersToGroup(groupID, emails);
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      group: "",
    },
    onSubmit: handleFormSubmit,
  });

  return (
    <Dialog open={state} onClose={onClose}>
      <Box
        sx={{
          minWidth: 600,
          display: "flex",
          padding: "1rem",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            padding: "1rem",
            fontSize: "1.4rem",
          }}
        >
          Add New Selected Subscribers to group
        </Typography>
        <Divider />
        <Typography
          sx={{
            marginTop: "1rem",
          }}
        >
          Groups
        </Typography>
        <TextField
          fullWidth
          onChange={formik.handleChange}
          name="group"
          value={formik.values.group}
          select
        >
          <MenuItem value={""}></MenuItem>
          {groups.map((group: INewsletterGroup) => (
            <MenuItem value={group.id}>{group.label}</MenuItem>
          ))}
        </TextField>
        <Button
          sx={{
            marginTop: "1rem",
          }}
          variant="contained"
          onClick={() => formik.handleSubmit()}
        >
          Submit
        </Button>
      </Box>
    </Dialog>
  );
};

export default AddEmailToGroupForm;
