import { Alert, Snackbar as MUISnackbar } from "@mui/material"
import { SnackbarContext } from "contexts/UseSnackbar"
import React from "react"

const SnackBar: React.FC = () => {
  const useSnackbar = React.useContext(SnackbarContext)
  const { state, type, handleClose, message } = useSnackbar
  return (
    <MUISnackbar
      open={state}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </MUISnackbar>
  )
}
export default SnackBar
