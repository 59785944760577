import { UploadFile } from "@mui/icons-material"
import { Button, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import FormModel from "bop-entities/form/formModel"
import useForm, { FormInput } from "bop-entities/form/useForm"
import Loading from "components/Atoms/Loading/Loading"
import AddNewForm from "components/FormView/AddNewForm"
import BOPFormView from "components/FormView/BOPFormView"
import FormCard from "components/FormView/FormCard"
import { SnackbarContext } from "contexts/UseSnackbar"
import DashboardLayout from "layout/DashboardLayout"
import React from "react"

const FormView = () => {
  const classes = useStyles()
  const { createNewForm, getFormsList } = useForm()
  const [formList, setFormList] = React.useState<Array<FormModel>>([])
  const [newFormDialogState, setNewFormDialogState] = React.useState(false)
  const [formDialogState, setFormDialogState] = React.useState(false)

  const useSnackbar = React.useContext(SnackbarContext)
  const { openSnackbar } = useSnackbar

  const { api: newFormApi, loading } = createNewForm(
    () => {
      openSnackbar({ type: "success", message: "Form Uploaded" })
      handleNewFormClose()
    },
    () => openSnackbar({ type: "error", message: "Something Went Wrong" })
  )

  const { api: fetchForms, loading: formsLoading } = getFormsList({
    onSuccess: (res) => setFormList(res),
    onError: () =>
      openSnackbar({ type: "error", message: "Something Went Wrong" }),
  })

  const handleNewFormClose = () => setNewFormDialogState(false)
  const handleNewFormOpen = () => setNewFormDialogState(true)
  const handleAddNewFormSubmit = async (values: FormInput) => {
    await newFormApi(values)
    fetchForms()
  }

  const { deleteForm } = useForm()
  const { api: deleteFormAPI, loading: deletingForm } = deleteForm({
    onSuccess: () => {
      openSnackbar({ type: "success", message: "Form Deleted" })
      fetchForms()
    },
    onError: () =>
      openSnackbar({ type: "error", message: "Something Went Wrong" }),
  })

  const handleFormDelete = async (form) => {
    await deleteFormAPI(form)
  }

  React.useEffect(() => {
    fetchForms()
  }, [])

  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box className={classes.appBar}>
          <Box className={classes.titleSection}>
            <Typography variant="h4">Forms</Typography>
          </Box>

          <Box className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<UploadFile />}
              onClick={handleNewFormOpen}
            >
              Upload New Form
            </Button>
          </Box>
        </Box>
        <Box className={classes.formBar}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setFormDialogState(true)}
          >
            Brokers buyer
          </Button>
        </Box>
        <Box className={classes.formContainer}>
          {(formsLoading || deletingForm) && <Loading />}
          {formList.length > 0 &&
            !formsLoading &&
            formList.map((form: FormModel) => (
              <FormCard
                key={form.id}
                form={form}
                handleDelete={() => handleFormDelete(form)}
              />
            ))}
          {formList.length === 0 && !formsLoading && (
            <Box className={classes.emptySection}>No Forms</Box>
          )}
        </Box>
      </Box>

      {newFormDialogState && (
        <AddNewForm
          state={newFormDialogState}
          handleClose={handleNewFormClose}
          onCreateNewForm={(values) => handleAddNewFormSubmit(values)}
          loading={loading}
        />
      )}
      {formDialogState && (
        <BOPFormView
          state={formDialogState}
          handleClose={() => setFormDialogState(false)}
          onCreateNewForm={(values) => console.log(values)}
          type="BROKER"
          loading={false}
        />
      )}
    </DashboardLayout>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
  },
  formBar: {
    padding: theme.spacing(4),
  },
  appBar: {
    position: "relative",
    top: "0",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
    zIndex: 9,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonsContainer: {
    width: 300,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  formContainer: {
    padding: theme.spacing(2),
    position: "relative",
    height: "100%",
    overflowX: "hidden",
    background: theme.palette.grey[200],
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    gap: 20,
  },
  titleSection: {
    display: "flex",
  },
  emptySection: {
    display: "grid",
    height: "100%",
    width: "100%",
    placeContent: "center",
  },
}))

export default FormView
