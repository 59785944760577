import { CircularProgress } from "@mui/material";
import { AuthContext } from "contexts/AuthContext";
import { useContext } from "react";
import LoginView from "views/Login";

const ProtectedRoute = ({ children }) => {
  const { user, isAdmin, isLoading } = useContext(AuthContext);

  if(isLoading)
    return <div style={{
      width: "100%",
      height: "100vh",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress />
    </div>

  if (!user || (user && !isAdmin)) {
    return <LoginView />;
  }

  return children;
};

export default ProtectedRoute;
