import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import Footer from "components/Footer"
import Header from "components/Header"
import SideBar from "components/Sidebar"
import SnackBar from "components/SnackBar/SnackBar"

type DashboardLayoutProps = {
  children: React.ReactNode | JSX.Element
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
}: DashboardLayoutProps) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <SideBar />
      <Header />
      <Box className={classes.container}>
        {children}
        <SnackBar />
      </Box>
      <Footer />
    </Box>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    padding: theme.spacing(0),
    backgroundColor: theme.colors.common.white,
  },
  container: {
    marginTop: `${theme.headerHeight}px`,
    marginLeft: `${theme.dashboardWidth}px`,
    marginBottom: `${theme.footerHeight}px`,
    width: "100%",
    height: `calc(100% - ${theme.footerHeight})`,
    boxShadow: `inset 0 0 5px 0 ${theme.colors.black}`,
    padding: theme.spacing(1),
    overflowY: "auto",
  },
}))
export default DashboardLayout
