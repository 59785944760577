import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import CopyrightsText from 'components/Atoms/Copyrights'
import React, { PropsWithChildren } from 'react'

interface LayoutProps {
  fullWidth?: boolean
}

const Layout: React.FC = ({ children }: PropsWithChildren<LayoutProps>) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      {children}
      <Box component='footer' className={classes.footer}>
        <CopyrightsText color='black' />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: `${theme.footerHeight}px`,
  },
}))

export default Layout
