import { TableCell as MuiTableCell, TableCellProps, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import React, { CSSProperties } from "react"

type CN = { className?: string; style?: any | never | unknown | CSSProperties }

const TableHeadCell: React.FC<Partial<TableCellProps> & CN> = ({
  children,
  className,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <MuiTableCell className={clsx(className, classes.tableHeadCell)} {...rest}>
      {children}
    </MuiTableCell>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  tableHeadCell: {
    textAlign: "left",
    verticalAlign: "center",
    color: `${theme.colors.common.white} !important`,
    fontWeight: 500,
    fontSize: "1rem !important",
    minWidth: 80,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.common.black,
    borderColor: theme.palette.common.white,
    border: "1px solid",
    display: "flex !important",
    alignItems: "center",
    padding: "0 !important",
    paddingLeft: "16px !important",
    height: "60px",
  },
}))

export default TableHeadCell
