import { db } from "api-client/config/firebase"
import { FirebaseCollection } from "api-client/enums"
import { FirebaseResponse, IEmailTemplate } from "api-client/types"
import { collection, doc, getDocs, orderBy, query, writeBatch } from "firebase/firestore"

export class FirebaseEmailTemplate {
  collectionRef = collection(db, FirebaseCollection.EmailTemplate)
  batch = writeBatch(db)
  docRef = (id: string) => doc(db, FirebaseCollection.Mail, id)

  

  getTemplates = async () => {
    try {
      const result: Array<IEmailTemplate> = []
      const collectionRef = collection(db, FirebaseCollection.EmailTemplate)
      const queries = [orderBy('createdAt', 'desc')];
      const docsQuery = query(collectionRef, ...queries );
      const snapshot = await getDocs(docsQuery);
      snapshot.forEach((doc) => {
        result.push({
          id: doc.id,
          ...doc.data() as IEmailTemplate
        })
      })
      return {
        type: "data",
        data: result,
      } as FirebaseResponse<Array<IEmailTemplate>>
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>
    }
  }
}
