import IconButton from "@mui/material/IconButton"
import { makeStyles } from "@mui/styles"
import { CopyAll as CopyIcon, Delete as DeleteIcon, Edit as EditIcon, Visibility as VisibilityIcon, Mail as MailIcon, Publish as PublishIcon } from "@mui/icons-material"
import React from "react"
import { Row } from "react-table"

type ActionCellProps = {
  row: Row
  onDelete?(): void
  onEdit?(): void
  onCopy?(): void
  onView?(): void,
  onRepublish?(): void,
  onSendMail?():void

}

const ActionCell: React.FC<ActionCellProps> = React.memo(({
  onCopy,
  onDelete,
  onEdit,
  onView,
  onRepublish,
  onSendMail
}: ActionCellProps) => {
  const classes = useStyles()
  return (
    <div className={classes.ActionCell}>
      {onCopy && (
        <IconButton onClick={onCopy}>
          <CopyIcon />
        </IconButton>
      )}
      {onDelete && (
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      )}
      {onEdit && (
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
      )}
      {onView && (
        <IconButton onClick={onView}>
          <VisibilityIcon />
        </IconButton>
      )}
      {onRepublish && (
        <IconButton onClick={onRepublish}>
          <PublishIcon/>
        </IconButton>
      )}
      {onSendMail && (
        <IconButton onClick={onSendMail}>
          <MailIcon />
        </IconButton>
      )}
    </div>
  )
})

const useStyles = makeStyles(() => ({
  ActionCell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 0,
  },
  ActionCellButton: {},
}))

export default ActionCell
