/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { API, Auth } from "aws-amplify"
import useStorage from "bop-entities/use-storage"
import { createFormsModel, deleteFormsModel } from "graphql/mutations"
import { listFormsModels } from "graphql/queries"
import React from "react"
import FormModel from "./formModel"

const AUTH_MODE = "AMAZON_COGNITO_USER_POOLS"

export type FormInput = {
  formTitle: string
  formDescription: string
  formFile: File
}

const useForm = () => {
  const createNewForm = (onSuccess: any, onError: any) => {
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const { uploadFile, getFileURL } = useStorage()
    const api = async (formInput: FormInput) => {
      try {
        setLoading(true)
        const file: File = formInput.formFile
        const key = await uploadFile(
          file,
          `forms/${formInput.formTitle}/${file.name}`
        )
        const url = await getFileURL(key)
        const token = (await Auth.currentSession()).getAccessToken()
        const form = {
          formTitle: formInput.formTitle,
          formDescription: formInput.formDescription,
          formFile: {
            key: key,
            url: url,
          },
        }
        const res = await API.graphql({
          query: createFormsModel,
          variables: {
            input: form,
          },
          authToken: token.getJwtToken(),
          authMode: AUTH_MODE,
        })
        setLoading(false)

        return onSuccess(res)
      } catch (error) {
        setError(true)
        setLoading(false)
      }
    }
    React.useEffect(() => {
      if (error) onError()
    }, [error])
    return {
      api,
      loading,
      error,
    }
  }

  type Action = {
    onSuccess: any
    onError: any
  }

  const getFormsList = ({ onSuccess, onError }: Action) => {
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const api = async () => {
      try {
        setLoading(true)
        const token = (await Auth.currentSession()).getAccessToken()
        const res: any = await API.graphql({
          query: listFormsModels,
          authToken: token.getJwtToken(),
          authMode: AUTH_MODE,
        })
        setLoading(false)
        return onSuccess(res.data.listFormsModels.items)
      } catch (error) {
        setError(true)
        setLoading(false)
      }
    }
    React.useEffect(() => {
      if (error) onError()
    }, [error])
    return {
      api,
      loading,
      error,
    }
  }

  const deleteForm = ({ onSuccess, onError }: Action) => {
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const api = async (form: FormModel) => {
      try {
        const { deleteFile } = useStorage()
        setLoading(true)
        await deleteFile(form.formFile.key)
        const token = (await Auth.currentSession()).getAccessToken()
        await API.graphql({
          query: deleteFormsModel,
          variables: {
            input: {
              id: form.id,
            },
          },
          authToken: token.getJwtToken(),
          authMode: AUTH_MODE,
        })
        setLoading(false)
        return onSuccess()
      } catch (error) {
        setError(true)
        setLoading(false)
      }
    }
    React.useEffect(() => {
      if (error) onError()
    }, [error])
    return {
      api,
      loading,
      error,
    }
  }

  const api = {
    createNewForm,
    getFormsList,
    deleteForm,
  }
  return api
}
export default useForm
