import { Box, Button, Dialog, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Form from "components/Atoms/Form"
import { CREATE_BROKER } from "CONSTANTS/broker"
import { BrokerContext } from "contexts/BrokerContext"
import { SnackbarContext } from "contexts/UseSnackbar"
import React from "react"
import * as yup from "yup"
import "yup-phone"
type CreateBrokerDialogProps = {
  state: boolean
  onClose(): void
  onBrokerCreated?(): void
}


const CreateBrokerDialog: React.FC<CreateBrokerDialogProps> = ({
  state,
  onClose
}: CreateBrokerDialogProps) => {
  const classes = useStyles()
  const handleClose = () => {
    onClose()
  }

  const { openSnackbar } = React.useContext(SnackbarContext);
  const { createBroker } = React.useContext(BrokerContext);

  const handleCreate = async (values: any) => {
    try {
      await createBroker(values);
      onClose();
    } catch (error) {
      openSnackbar({
        type: "error",
        message: "Error creating broker",
      });
    }
  }

  const handleUpdate = () => {
    const button = document.getElementById("create-broker-submit-button")
    button.click()
  }

  return (
    <Dialog
      open={state}
      onClose={handleClose}
      className={classes.root}
      classes={{
        paper: classes.container,
      }}
    >
      <Box>
        <Box className={classes.header}>
          <Typography variant="h4" component="h4">
            New Broker
          </Typography>
          <Box className={classes.headerActions}>
            <Button variant="contained" color="error" onClick={handleClose}>
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              Create Broker
            </Button>
          </Box>
        </Box>
        <Form
          formData={CREATE_BROKER}
          formikProps={{
            initialValues: {
              email: "",
              name: "",
              title: "",
              brokerageName: "",
              phoneNumber: ""
            },
            validationSchema: validationSchema,
            onSubmit: (value) => handleCreate(value),
          }}
          hideButton
          name="create-broker"
          submitLabel="Submit"
        />
      </Box>
    </Dialog>
  )
}

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  phoneNumber: yup.string().required(),
  title: yup.string().required(),
  brokerageName: yup.string().required(),
})

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 99999,
  },
  container: {
    minWidth: "80%",
    maxHeight: "100vh",
  },
  header: {
    display: "flex",
    width: "100%",
    borderBottom: "1px solid #000",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerActions: {
    "& button": {
      margin: 10,
    },
  },
}))

export default CreateBrokerDialog
