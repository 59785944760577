import { ExitToAppOutlined } from '@mui/icons-material'
import { Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import Logo from 'assets/images/logo.png'
import {auth} from 'api-client/config/firebase';
import { useNavigate } from 'react-router-dom'
import { signOut } from '@firebase/auth'

const Header = () => {
  const classes = useStyles()
  const navigate = useNavigate();
  const handleSignOut = async () => {
    await signOut(auth);
    navigate('/')
  }

  return (
    <Box component='header' className={classes.root}>
      <Box className={classes.innerContainer}>
        <Box className={classes.logoContainer}>
          <img
            src={Logo}
            alt='bank owned properties'
            className={classes.logoImage}
          />
        </Box>
        <Box>
          <Button
            color='secondary'
            variant='contained'
            startIcon={<ExitToAppOutlined />}
            onClick={handleSignOut}
          >
            Sign Out
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: theme.headerHeight,
    width: '100%',
    position: 'fixed',
    top: 0,
    right: 0,
    backgroundColor: theme.colors.darkGrey,

    overflow: 'hidden',
  },
  innerContainer: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    padding: theme.spacing(0, 2),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoContainer: {
    position: 'relative',
  },
  logoImage: {
    aspectRatio: 'auto',
    height: `calc(${theme.headerHeight}px - ${theme.spacing(4)})`,
    width: 'auto',
  },
}))

export default Header
