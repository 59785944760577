import { Close } from "@mui/icons-material"
import { IconButton, Skeleton, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { alpha, Box } from "@mui/system"
import useStorage from "bop-entities/use-storage"
import React, { useEffect } from "react"

type FileImageProps = {
  file: any
  handleDeleteFile(): void
  disabled?: boolean
}
export const FileImage: React.FC<FileImageProps> = ({
  file,
  handleDeleteFile,
  disabled,
}: FileImageProps) => {
  const classes = useStyles()
  const [imageURL, setImageURL] = React.useState<any>("")
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    if (typeof file === "string") {
      setImageURL(file)
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setImageURL(reader.result)
    }
  }, [file])

  return (
    <Box className={classes.root}>
      <IconButton
        className={classes.button}
        onClick={handleDeleteFile}
        sx={{
          position: "absolute !important",
          backgroundColor: "#fff !important",
          zIndex: 99,
        }}
        disabled={disabled}
      >
        <Close />
      </IconButton>
      {loading && <Skeleton height={150} width={200} variant="rectangular" />}

      <img
        src={imageURL}
        className={classes.imgStyle}
        onLoad={() => setLoading(false)}
        alt="bank owned property"
      />
    </Box>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    height: "auto",
    padding: theme.spacing(1),
    border: `1px solid ${alpha(theme.colors.black, 0.1)}`,
    margin: theme.spacing(1),
  },
  imgStyle: {
    aspectRatio: "auto",
    height: 150,
  },
  button: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}))
