import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FirebaseAuthErrors } from "api-client/enums";
import { FirebaseAuth } from "api-client/firebase/auth";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";

const authApi = new FirebaseAuth();

const LoginForm: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await authApi.login({
          email: values.email,
          password: values.password,
        });
        navigate("/");
      } catch (e) {
        if (e.code === FirebaseAuthErrors.INVALID_PASSWORD)
          formik.setFieldError("password", "Please enter the correct password");
        else if (e?.code === FirebaseAuthErrors.USER_NOT_FOUND)
          formik.setFieldError("email", "Please enter correct email");
      }
    },
  });

  return (
    <Grid
      container
      component={Paper}
      direction="column"
      className={classes.root}
      elevation={6}
    >
      <Typography component="h2" variant="h4" textAlign="center">
        Login
      </Typography>
      <Box className={classes.textField}>
        <Typography>Email</Typography>
        <TextField
          type="email"
          fullWidth
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          helperText={formik.touched.email && formik.errors.email}
          error={Boolean(formik.errors.email)}
          disabled={formik.isSubmitting}
        />
      </Box>
      <Box className={classes.textField}>
        <Typography>Password</Typography>
        <TextField
          type="password"
          fullWidth
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          helperText={formik.touched.password && formik.errors.password}
          error={Boolean(formik.errors.password)}
          disabled={formik.isSubmitting}
        />
      </Box>
      <Box className={classes.butttons}>
        <Button
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          fullWidth
        >
          {formik.isSubmitting ? (
            <CircularProgress size={24} color="secondary" />
          ) : (
            <>Login</>
          )}
        </Button>
      </Box>

      {/* <Box className={classes.butttons}>
        <Button
          onClick={() => navigate('/set-password')}
          variant="text"
          color="primary"
          fullWidth
        >
          Forgot Password
        </Button>
      </Box> */}
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 600,
    padding: theme.spacing(4, 4, 2, 4),
  },
  textField: {
    margin: theme.spacing(2, 0),
  },
  butttons: {
    margin: theme.spacing(2, 0),
  },
}))

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Please Enter your Email"),
  password: Yup.string().required("Please enter the password"),
})

export default LoginForm
