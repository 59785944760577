import {
  FirebaseCollection,
  MailType,
  PropertyStatus,
  PropertyType,
  SourceStatus,
} from "api-client/enums"
import { FirebaseMail } from "api-client/firebase/mail"
import { FirebaseNewsletterGroup } from "api-client/firebase/newsletterGroup"
import { Property } from "api-client/models/Property"
import { PropertyEmailHTML } from "api-client/templates/propertyEmail"
import { getPropertiesTemplateContent } from "api-client/templates/singlePropertyTemplate"
import { SendMailFunctionReq } from "api-client/types"
import { statesKeyValue } from "CONSTANTS/states"
import { Status } from "interfaces"

export const generateDocPath = (
  collection: FirebaseCollection,
  docId: string
) => `${collection}/${docId}` as string

export const generateCollectionPath = (
  data: Array<FirebaseCollection | string>
) => {
  const path = data.toLocaleString().replaceAll(",", "/")
  return path as string
}

export interface IPagination {
  pageSize?: number
  startAt?: any
}

export class Pagination implements IPagination {
  pageSize?: number
  startAt?: any

  constructor(p?: IPagination) {
    if (!p) return
    this.pageSize = p.pageSize
    this.startAt = p.startAt
  }
}

export const propertyImportCast = (value: string, context: any) => {
  switch (context.column as keyof Property) {
    case "description":
      if (!value) return ""
      return value.replaceAll("\\\n", " ")
    case "images":
      if (!value) return []
      return value.split("\n")
    case "garage":
      if (!value) return false
      return value.toLowerCase() === "yes"
    case "privateDriveway":
      if (!value) return false
      return value.toLowerCase() === "yes"
    case "propertyStatus":
      if (!value) return PropertyStatus.OCCUPIED
      return value.toLowerCase() === "vacant"
        ? PropertyStatus.VACANT
        : PropertyStatus.OCCUPIED
    case "status":
      if (!value) return Status.PUBLISHED
      const statuses = {
        sold: Status.SOLD,
        draft: Status.DRAFT,
        live: Status.LIVE,
        private: Status.PRIVATE,
        closed: Status.CLOSED,
        published: Status.PUBLISHED,
      }
      return statuses[value.toLowerCase()] ?? Status.PUBLISHED
    case "sourceStatus":
      if (!value) return SourceStatus.AVAILABLE
      const sourceStatuses = {
        contract: SourceStatus.CONTRACT,
        sold: SourceStatus.SOLD,
        available: SourceStatus.AVAILABLE,
      }
      return sourceStatuses[value.toLowerCase()] ?? SourceStatus.AVAILABLE
    case "propertyType":
      const propertyTypes = {
        land: PropertyType.land,
        reo: PropertyType.reo,
        estate_sale: PropertyType.estate_sale,
        mortgage: PropertyType.mortgage,
        short_sale: PropertyType.short_sale,
        contract_flip: PropertyType.contract_flip,
        assigned_bid: PropertyType.assigned_bid,
        foreclosure: PropertyType.foreclosure,
      }
      return propertyTypes[value.toLowerCase()] ?? PropertyType.estate_sale
    case "state":
      if (!value) return statesKeyValue["new york"]
      return statesKeyValue[value.toLowerCase()] || statesKeyValue["new york"]
    default:
      if (!value) return "N/A"
      return value
  }
}

export const handleSendEmails = async (param: SendMailFunctionReq) => {
  // const newsletterAPI = new FirebaseNewsletter()
  const newsletterGroupAPI = new FirebaseNewsletterGroup()
  const sendMailAPI = new FirebaseMail()
  const { type, content } = param
  if (type === MailType.Properties) {
    const toType = content.to.type
    const properties = content.properties
    if (toType === "group") {
      const groupId = content.to.data
      const newslettersApiRes = await newsletterGroupAPI.getGroupMembers(
        groupId
      )
      if (newslettersApiRes.type === "error") return
      await sendMailAPI.sendMail({
        to: newslettersApiRes.data,
        content: getPropertiesTemplateContent(properties),
        subject: "NEW INVESTMENT OPPORTUNITY- Bank Owned Property USA",
      })
    }
  } else if (type === MailType.Property) {
    const toType = content.to.type
    const property = content.property
    if (toType === "group") {
      const groupId = content.to.data
      const newslettersApiRes = await newsletterGroupAPI.getGroupMembers(
        groupId
      )
      if (newslettersApiRes.type === "error") return
      await sendMailAPI.sendMail({
        to: newslettersApiRes.data,
        content: PropertyEmailHTML(property),
        subject: `NEW INVESTMENT OPPORTUNITY: ${property.title}`,
      })
    }
  } else if (type === MailType.Template) {
    const toType = content.to.type
    const html = content.content
    if (toType === "group") {
      const groupId = content.to.data
      const newslettersApiRes = await newsletterGroupAPI.getGroupMembers(
        groupId
      )
      if (newslettersApiRes.type === "error") return
      await sendMailAPI.sendMail({
        to: newslettersApiRes.data,
        content: html,
        subject: `BANK OWNED PROPERTY USA - Happy Holidays`,
      })
    }
  }
}
