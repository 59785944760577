import { db } from "api-client/config/firebase";
import { FirebaseCollection } from "api-client/enums";
import { LendingInquiry } from "api-client/models/LendingInquiry";
import { FirebaseResponse, ILendingInquiry } from "api-client/types";
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";

export class FirebaseLendingInquiry {

  fetchLendingInquirys = async (propertyId: string): Promise<FirebaseResponse<Array<ILendingInquiry>>> => {
    try {
      const promotions: Array<ILendingInquiry> = [];
      const collectionRef = collection(db, FirebaseCollection.LendingInquiry);
      const snapshot = await getDocs(collectionRef);
      
      snapshot.docs.forEach((doc) => {
        promotions.push(
          new LendingInquiry({ ...doc.data(), id: doc.id } as ILendingInquiry)
        );
      });
      return {
        type: "data",
        data: promotions,
      };
    } catch (e) {
      return {
        type: "error",
        error: [],
      };
    }
  };

  createLendingInquiry = async (
    promotion: ILendingInquiry
  ): Promise<FirebaseResponse<string>> => {
    try {
      const collectionRef = collection(db, FirebaseCollection.LendingInquiry);
      await addDoc(collectionRef, promotion);
      return {
        type: "data",
        data: "LendingInquiry added successfully",
      };
    } catch (e) {
        console.log(e);
      return {
        type: "error",
        error: "Error saving promotion",
      };
    }
  };

  updateLendingInquiry = async (promotion: ILendingInquiry) => {
    try{
        const collectionRef = collection(db, FirebaseCollection.LendingInquiry);
        const docRef = doc(collectionRef, promotion.id);
        await updateDoc(docRef, {...promotion});
        return {
            type: 'success',
            data: 'LendingInquiry updated'
        }
    }catch(e){
        return {
            type: 'error',
            error: 'Error updating promotion'
        }
    }
  }

  deleteLendingInquiry = async (promotionId: string) : Promise<FirebaseResponse<string>> => {
    try{
        const collectionRef = collection(db, FirebaseCollection.LendingInquiry);
        const docRef = doc(collectionRef, promotionId);
        await deleteDoc(docRef);
        return {
            type: 'data',
            data: 'LendingInquiry deleted successfully'
        }
    }catch(e){
        return {
            type: 'error',
            error: 'Error deleting promotion'
        }
    }
  }

}
