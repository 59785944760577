import { alpha, Box, CircularProgress, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

const Loading = () => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <CircularProgress color="primary" />
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    width: "100% !important",
    height: "100% !important",
    position: "absolute",
    background: alpha(theme.palette.common.white, 0.2),
    top: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
}))

export default Loading
