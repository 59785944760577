import { User } from "firebase/auth";
import { auth } from "api-client/config/firebase";
import React, { useEffect, useState } from "react";

type AuthProviderProps = {
  user: User;
  isAdmin: boolean;
  isLoading: boolean;
};

const initialState: AuthProviderProps = {
  user: null,
  isAdmin: false,
  isLoading: true,
};

export const AuthContext = React.createContext(initialState);

const { Consumer: AuthConsumer, Provider } = AuthContext;

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState(initialState.user);
  const [isAdmin, setIsAdmin] = useState(initialState.isAdmin);
  const [isLoading, setIsLoading] = useState(initialState.isLoading);

  useEffect(() => {
    auth.onAuthStateChanged((newUser) => {

      setIsLoading(false);
      if (!newUser) {
        if (user) setUser(newUser);
        return;
      }

      if(newUser)
        checkAdminRole(newUser);

      setUser(newUser);
    });
  }, [user]);

  const checkAdminRole = async (user) => {
    const token = await user.getIdTokenResult();
    setIsAdmin(token.claims.admin);
  };

  const values = {
    user,
    isAdmin,
    isLoading,
  };

  return <Provider value={values}>{children}</Provider>;
};

export { AuthConsumer, AuthProvider };
