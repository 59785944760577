import { FirebaseNewsletterGroup } from "api-client/firebase/newsletterGroup"
import { NewsletterGroup } from "api-client/models/NewsletterGroup"
import { INewsletter, INewsletterGroup } from "api-client/types"
import React from "react"
import { SnackbarContext } from "./UseSnackbar"
import _ from "lodash"
import { Newsletter } from "api-client/models/Newsletter"

type NewsletterGroupContextProps = {
  groups: Array<NewsletterGroup>
  loading: boolean
  filteredGroups: Array<NewsletterGroup>
  selectedGroup:  Array<Newsletter>
  setGroups?: React.Dispatch<any>
  fetchGroups?(): Promise<any>
  setFilteredGroups?: React.Dispatch<any>
  deleteGroupById?(id: string): void
  searchGroupsByTitle?(title: string, pageSize: number): void
  createNewsletterGroup?(values: INewsletterGroup): Promise<any>
  fetchGroupMembers?(groupId: string, skipSelected?: boolean): void
  addNewslettersToGroup?(
    groupID: string,
    emails: Array<INewsletter>
  ): Promise<any>,
  groupMembers: Map<string, NewsletterGroupMemberHolder>,
  deleteNewslettersFromGroup?(groups: NewsletterGroup[], email: string): void
}

type NewsletterGroupMember = {
  members: Array<Newsletter>
  group: NewsletterGroup
}

type NewsletterGroupMemberHolder = {
  groupId: string
  data: NewsletterGroupMember
}

const initialState: NewsletterGroupContextProps = {
  groups: [],
  loading: false,
  filteredGroups: [],
  selectedGroup: null,
  groupMembers: new Map<string, NewsletterGroupMemberHolder>(),
}

const newsletterGroupApi = new FirebaseNewsletterGroup()

export const NewsletterGroupContext = React.createContext(initialState)

const { Consumer: NewsletterGroupConsumer, Provider } = NewsletterGroupContext

const NewsletterGroupProvider: React.FC = ({ children }) => {
  const [groups, setGroups] = React.useState(initialState.groups)
  const [loading, setLoading] = React.useState(initialState.loading)
  const [filteredGroups, setFilteredGroups] = React.useState(
    initialState.filteredGroups
  )
  const [searchTerm, setSearchTerm] = React.useState("")
  const [groupMembers, setGroupMembers] = React.useState(new Map<string, NewsletterGroupMemberHolder>());

  const [selectedGroup, setSelectedGroup] = React.useState(null)

  const useSnackbar = React.useContext(SnackbarContext)
  const { openSnackbar } = useSnackbar

  const deleteNewslettersFromGroup = async(groups: NewsletterGroup[], email: string) => {
      let promises = groups.map(group => newsletterGroupApi.deleteNewsletterFromGroup(group.id, email));
      await Promise.allSettled(promises);
      fetchGroups();
  }

  const fetchGroupMembers = async (groupId: string) => {
    let group = groupMembers[groupId]?.group;
    if(!group){
      const test = await newsletterGroupApi.getNewsletterGroup(groupId)
      if(test.type === 'data')
        group = test.data;
    }
    if (group?.members) return setSelectedGroup(group?.members)
    const res = await newsletterGroupApi.getGroupMembers(groupId)
    if (res.type === "error") return []
    groupMembers[groupId] = {
      group: group,
      members: res.data
    }
    setGroupMembers({...groupMembers});
    setSelectedGroup(res.data)
    return res.data
  }

  const fetchGroups = React.useCallback(async () => {
    setLoading(true)
    const res = await newsletterGroupApi.getNewsletterGroups()
    if (res.type === "error") return
    let promises = [];
    res.data.forEach(group => {
      if(groupMembers[group.id]){
        if(groupMembers[group.id].members) return;
      }else{
        groupMembers[group.id] = {group: group}
      }
      promises.push(fetchGroupMembers(group.id))
    });
    setGroupMembers({...groupMembers});
    Promise.allSettled(promises);
    setGroups(res.data)
    setFilteredGroups(res.data)
    setLoading(false)
  }, [])

  const deleteGroupById = async (id: string) => {
    await newsletterGroupApi.deleteNewsletterGroup(id)

    /* first remove group from main list */
    let temp = _.filter(groups, (item) => item.id !== id)
    setGroups(temp)

    /* if table is in filter mode, remove it from filteredGroups as well */
    if (searchTerm) temp = _.filter(filteredGroups, (item) => item.id !== id)

    setFilteredGroups(temp)
  }

  const searchGroupsByTitle = async (title: string, pageSize: number) => {
    setSearchTerm(title)
    if (!title?.trim()) return setFilteredGroups(groups)

    setLoading(true)

    const res = await newsletterGroupApi.searchGroupsByTitle(
      title.trim(),
      pageSize
    )

    if (res.type === "error") {
      setFilteredGroups([])
    } else {
      setFilteredGroups(res.data)
    }
    setLoading(false)
  }

  const createNewsletterGroup = async (values: INewsletterGroup) => {
    const res = await newsletterGroupApi.createNewsletterGroup(values)
    if (res.type === "error")
      return openSnackbar({
        type: "error",
        message: "Error create newsletter group",
      })
    groups.push(res.data)
    setGroups(groups)
    if (!searchTerm) setFilteredGroups(groups)
  }

  const addNewslettersToGroup = async (
    groupID: string,
    emails: Array<INewsletter>
  ) => {
    await newsletterGroupApi.addNewsletterToGroup(emails, groupID)
    fetchGroupMembers(groupID)
  }

  const values = {
    groups,
    loading,
    filteredGroups,
    selectedGroup,
    setFilteredGroups,
    fetchGroups,
    deleteGroupById,
    searchGroupsByTitle,
    createNewsletterGroup,
    fetchGroupMembers,
    addNewslettersToGroup,
    groupMembers,
    deleteNewslettersFromGroup
  }

  return <Provider value={values}>{children}</Provider>
}

export { NewsletterGroupConsumer, NewsletterGroupProvider }
