import { Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
type TableHeadRow = {
    children:
        | Array<JSX.Element>
        | JSX.Element
        | React.ReactChildren
        | React.ReactChild
}

const TableHeadRow: React.FC<TableHeadRow> = ({ children }: TableHeadRow) => {
    const classes = useStyles()
    return <Box className={classes.root}>{children}</Box>
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        minHeight: 60,
        borderBottom: `2px solid #ccc`,
        padding: theme.spacing(1),
    },
}))

export default TableHeadRow
