import { FirebaseCollection } from "api-client/enums";
import { db } from "api-client/config/firebase";
import {
  collection,
  setDoc,
  doc,
  getDocs,
  getDoc,
  writeBatch,
  deleteDoc,
  query,
  limit,
  orderBy,
  startAfter,
  where,
} from "firebase/firestore";
import { INewsletter, FirebaseResponse } from "api-client/types";
import { Newsletter } from "api-client/models/Newsletter";
import { v4 as uuidv4 } from "uuid";
import { IPagination } from "api-client/utils";
import { NewsletterGroup } from "api-client/models/NewsletterGroup";

export class FirebaseNewsletter {

  importNewsletters = async (payload: Array<Array<any>>) => {

    try {
      const collectionRef = collection(db, FirebaseCollection.Newsletters);

      if(payload.length === 0)
        return;

      let promises = [];  
        
      payload.forEach(chunk => {
        const batch = writeBatch(db);
        chunk.forEach(item => {
          if(item.emails)
            batch.set(doc(collectionRef, item.emails), {
              "id" : uuidv4(),
              "email": item.emails
            });
        });
        promises.push(batch.commit());
      });

      await Promise.allSettled(promises);

      return {
        type: "data",
        data: "Newsletters added successfully",
      } as FirebaseResponse<String>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };

  createNewsletter = async (payload: INewsletter) => {
    try {
      const docRef = doc(
        db,
        FirebaseCollection.Newsletters,
        payload.email.toLowerCase()
      );

      if ((await getDoc(docRef)).exists())
        throw Error("Newsletter already exists");

      const _doc = new Newsletter({
        id: uuidv4(),
        email: payload.email,
      });
      await setDoc(docRef, _doc.toJSON());
      return {
        type: "data",
        data: _doc,
      } as FirebaseResponse<Newsletter>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };

  deleteNewsletter = async (payload: INewsletter) => {
    try {
      const collectionRef = collection(db, FirebaseCollection.Newsletters);
      const docRef = doc(collectionRef, payload.email.toLowerCase());
      await deleteDoc(docRef);
      return {
        type: "data",
        data: "Newsletter deleted successfully",
      } as FirebaseResponse<String>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };

  batchDeleteNewsletters = async (payload: Map<string, NewsletterGroup[]>) => {
    try {

      const collectionRef = collection(db, FirebaseCollection.Newsletters);
      
      const batch = writeBatch(db);
      Object.keys(payload).forEach(key => {
        batch.delete(doc(collectionRef, key))
        if(payload[key])
          payload[key].forEach(group => {
            const collectionRef = collection(db,
              FirebaseCollection.NewsletterGroups,
              group.id,
              FirebaseCollection.NewsletterGroupMembers,
            );
            batch.delete(doc(collectionRef, key));
          })
      })
      await batch.commit();
      return {
        type: "data",
        data: "Newsletters deleted successfully",
      } as FirebaseResponse<String>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };

  searchNewsletters = async (search: string, pageSize: number) => {
    try{
      const result: Array<Newsletter> = [];
      const collectionRef = collection(db, FirebaseCollection.Newsletters);
      const docsQuery = query(collectionRef, where('email', '>=', search), where('email','<=',search+'\uf8ff'), limit(pageSize));
      const snapshot = await getDocs(docsQuery);
      snapshot.forEach((doc) => {
        const newsletter = new Newsletter({
          ...(doc.data() as INewsletter),
        });
        result.push(newsletter);
      });

      return {
        type: "data",
        data: result,
      } as FirebaseResponse<Array<Newsletter>>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  }

  getNewsletters = async (pagination: IPagination = {pageSize: 10, startAt: undefined}) => {
    try {
      const result: Array<Newsletter> = [];
      const collectionRef = collection(db, FirebaseCollection.Newsletters);
      const queries = [orderBy('email'),  limit(pagination.pageSize * 3)];
      if(pagination.startAt){
        queries.push(startAfter(pagination.startAt.email))
      }
      const docsQuery = query(collectionRef, ...queries );
      const snapshot = await getDocs(docsQuery);
      snapshot.forEach((doc) => {
        const newsletter = new Newsletter({
          ...(doc.data() as INewsletter),
        });
        result.push(newsletter);
      });
      return {
        type: "data",
        data: result,
      } as FirebaseResponse<Array<Newsletter>>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };
}
