import { db } from "api-client/config/firebase";
import { FirebaseCollection, UserRole } from "api-client/enums";
import { User } from "api-client/models/User";
import { FirebaseResponse, IUser } from "api-client/types";
import { collection, getDocs } from "firebase/firestore";
import { auth } from "api-client/config/firebase";

const WebAppURL = process.env.REACT_APP_WEB_APP_URL;

export class FirebaseUser {
  fetchUsers = async (): Promise<FirebaseResponse<IUser[]>> => {
    try {
      const users: IUser[] = [];
      const collectionRef = collection(db, FirebaseCollection.Users);
      const snapshot = await getDocs(collectionRef);
      snapshot.docs.forEach((doc) => {
        if (doc.id !== auth.currentUser?.uid)
          users.push(new User({ id: doc.id, ...doc.data() } as IUser));
      });
      return {
        type: "data",
        data: users,
      };
    } catch (error) {
      return {
        type: "error",
        error: error,
      };
    }
  };

  updateAdminStatus = async (userId: string, userRole: UserRole) => {
     const response = await fetch(
        `${WebAppURL}/api/auth/userClaims?` +
          new URLSearchParams({
            userId,
            role: userRole,
          })
      );

      const res = await response.json();
      return res.message;
  };
}
