import { TablePagination as _MuiTablePagination } from "@mui/material";
import { IPagination } from "api-client/utils";
import React, { useCallback } from "react";
import { TableInstance } from "react-table";

const rowsPerPageOptions = [10, 50, 100, 150, 250];

// avoid all of the redraws caused by the internal withStyles
const interestingPropsEqual = (prevProps: any, nextProps: any) =>
  prevProps.count === nextProps.count &&
  prevProps.rowsPerPage === nextProps.rowsPerPage &&
  prevProps.page === nextProps.page &&
  prevProps.onChangePage === nextProps.onChangePage &&
  prevProps.onChangeRowsPerPage === nextProps.onChangeRowsPerPage;

// a bit of a type hack to keep OverridableComponent working as desired
type T = typeof _MuiTablePagination;
const MuiTablePagination: T = React.memo(
  _MuiTablePagination,
  interestingPropsEqual
) as T;

type TablePaginationProps = {
  tableInstance: TableInstance<any>;
  onLoadMore?(pagination: IPagination, pageSizeChange: boolean): void;
};

const TablePagination: React.FC<TablePaginationProps> = ({
  tableInstance,
  onLoadMore,
}: TablePaginationProps) => {
  const {
    state: { pageIndex, pageSize, rowCount = tableInstance.rows.length },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = tableInstance;

  const handleChangePage = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      newPage: number
    ) => {
      if (newPage === pageIndex + 1) {
        nextPage();
      } else if (newPage === pageIndex - 1) {
        previousPage();
      } else {
        gotoPage(newPage);
      }

      if (onLoadMore && rowCount / pageSize - newPage <= 2) {
        onLoadMore({
          pageSize: pageSize,
          startAt: tableInstance.rows[tableInstance.rows.length - 1].original,
        }, false);
      }
    },
    [gotoPage, nextPage, pageIndex, previousPage, pageSize, rowCount]
  );

  return rowCount ? (
    <MuiTablePagination
      component="div"
      count={rowCount}
      page={pageIndex}
      onPageChange={handleChangePage}
      rowsPerPage={pageSize}
      onRowsPerPageChange={(event) => {
        const perPage = Number(event.target.value);
        setPageSize(perPage);
        if (rowCount / perPage < 2) {
          onLoadMore(
            {
              pageSize: perPage,
              startAt:
                tableInstance.rows[tableInstance.rows.length - 1].original,
            },
            true
          );
        }
      }}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  ) : null;
};
export default TablePagination;
