import { Button, Dialog, Divider, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { INewsletterGroup } from "api-client/types"
import { NewsletterGroupContext } from "contexts/NewsletterGroupContext"
import { useFormik } from "formik"
import React from "react"
import * as yup from "yup"

interface AddNewsletterGroupFormProps {
  onClose(): void
  state: boolean
}


const AddNewsletterGroupForm = ({
  onClose,
  state,
}: AddNewsletterGroupFormProps) => {

  const useNewsletterGroup = React.useContext(NewsletterGroupContext);
  const {createNewsletterGroup} = useNewsletterGroup;


  const handleFormSubmit = async (values: INewsletterGroup) => {
    await createNewsletterGroup(values)
    onClose()
  }

  const formik = useFormik({
    initialValues: {
      label: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  })

  return (
    <Dialog open={state} onClose={onClose}>
      <Box
        sx={{
          minWidth: 600,
          display: "flex",
          padding: "1rem",
          flexDirection: "column",
        }}>
        <Typography
          sx={{
            padding: "1rem",
            fontSize: "1.4rem",
          }}>
          Create New Group
        </Typography>
        <Divider />
        <Typography
          sx={{
            marginTop: "1rem",
          }}>
          Name
        </Typography>
        <TextField
          fullWidth
          onChange={formik.handleChange}
          name='label'
          value={formik.values.label}
        />
        <Button
          sx={{
            marginTop: "1rem",
          }}
          variant='contained'
          onClick={() => formik.handleSubmit()}>
          Create new Group
        </Button>
      </Box>
    </Dialog>
  )
}

const validationSchema = yup.object().shape({
  label: yup.string().required(),
})

export default AddNewsletterGroupForm
