import { Box, Button, TextField } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Theme } from "@mui/system"
import React, { useRef } from "react"

type TableToolBarProps = {
  onSearch?(value: string): void
  onAdd?(): void
  onImport?(e: React.ChangeEvent<HTMLInputElement>): void
  onDelete?(): void
  customControl?: React.ReactNode,
  searchValue: string
}

const TableToolBar: React.FC<TableToolBarProps> = ({
  onAdd,
  onSearch,
  onImport,
  onDelete,
  customControl,
  searchValue,
}: TableToolBarProps) => {
  const classes = useStyles()
  const importInputRef = useRef(null)

  React.useEffect(() => {}, [])
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onSearch(e.currentTarget.value)

  const handleOnImportClick = () => {
    importInputRef.current.value = ""
    importInputRef.current.click()
  }
  return (
    <Box className={classes.root}>
      <Box className={classes.searchBox}>
        <TextField onChange={handleChange} value={searchValue} placeholder="Search" fullWidth />
      </Box>
      <Box className={classes.action}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onAdd}
        >
          Add
        </Button>
        {onImport && (
          <>
            <input
              type="file"
              onChange={onImport}
              ref={importInputRef}
              style={{
                display: "none",
              }}
              accept=".csv"
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleOnImportClick}
            >
              Import
            </Button>
          </>
        )}
        {onDelete && <Button>Delete Emails</Button>}
        {!!customControl && customControl}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2, 0),
  },
  searchBox: {
    minWidth: 350,
  },
  action: {
    display: "flex",
  },
  button: {
    margin: `${theme.spacing(1)} !important`,
    padding: theme.spacing(2),
  },
}))

export default TableToolBar
