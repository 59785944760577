/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { MailContext } from "contexts/MailContext";
import DashboardLayout from "layout/DashboardLayout";
import React from "react";
import { parseFirebaseDateTime } from "utils";

const MailView: React.FC = () => {
  const classes = useStyles();
  const { mails, isLoading, fetchAllMails } = React.useContext(MailContext);

  React.useEffect(() => {
    if(mails.length === 0)
      fetchAllMails();
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "to",
      headerName: "To",
      width: 300,
      renderCell: ({ row }) => <>{row?.to}</>,
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      renderCell: ({ row }) => <>{row?.message?.subject}</>,
    },
    {
      field: " ",
      headerName: "Status",

      renderCell: ({ row }) => <>{row?.delivery?.state}</>,
    },
    {
      field: "createdAt",
      headerName: "Date/Time",
      width: 300,
      renderCell: ({ row }) => <>{parseFirebaseDateTime(row.createdAt)}</>,
    },
  ];
  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "16px",
            backgroundColor: "#ccc",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "22px",
              fontWeight: "900",
            }}
          >
            Mails
          </Typography>
        </Box>
        <DataGrid
          rows={mails}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          loading={isLoading}
        />
      </Box>
    </DashboardLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
  },
  viewButton: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    width: "inherit",
  },
}));

export default MailView;
