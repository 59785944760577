/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@aws-amplify/ui-react"
import { Avatar, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import { FirebaseTeam } from "api-client/firebase/team"
import { ITeamMember } from "api-client/types"
import AddTeamMember from "components/AddTeamMember"
import ActionCell from "components/Table/ActionCell"
import Table from "components/Table/Table"
import { SnackbarContext } from "contexts/UseSnackbar"
import DashboardLayout from "layout/DashboardLayout"
import _ from "lodash"
import React, { useContext } from "react"
import { Column } from "react-table"

const teamApi = new FirebaseTeam();

const TeamView: React.FC = () => {
  const classes = useStyles()
  const [teamList, setTeamList] = React.useState<Array<ITeamMember>>([])
  const [loading, setLoading] = React.useState(false)
  const [teamMemberState, setTeamMemberState] = React.useState(false)
  const [team, setTeam] = React.useState<any>(undefined)

  const { openSnackbar } = useContext(SnackbarContext);

  const getAllTeamMember = async () => {
    setLoading(true)
    setTeamList([])
    const res = await teamApi.fetchTeamMembers();
    
    setLoading(false)
    if(res.type === 'error'){
      return openSnackbar({
        type: 'error',
        message: 'Error fetching team members'
      })
    }
    setTeamList(res.data);
  }

  const handleCloseDialog = () => {
    setTeamMemberState(false)
    setTeam(undefined)
  }

  const handleCloseDialogOnUpdate = () => {
    setTeamMemberState(false)
    setTeam(undefined)
    getAllTeamMember()
  }

  const handleOpenDialog = () => setTeamMemberState(true)

  const handleDeleteUser = async (team) => {
    setLoading(true)
    const res = await teamApi.deleteTeamMember(team.id);
    setLoading(false);
    if(res.type === 'error'){
      return openSnackbar({
        type: 'error',
        message: res.error
      })
    }

    const filtered = _.filter(teamList, (member) => member.id !== team.id);
    setTeamList(filtered);
    
  }

  const handleEditDialog = (team) => {
    setTeam(team)
    handleOpenDialog()
  }

  const handleAddNewDialog = () => {
    handleOpenDialog()
  }

  React.useEffect(() => {
    getAllTeamMember()
  }, [])

  const columns: Array<Column<any>> = [
    {
      Header: " ",
      width: 60,
      disableSortBy: true,
      disableFilters: true,
      disableResizing: true,
      Cell: ({ row }: any) => {
        return <Avatar src={row.original.image ?? ''} />
      },
    },
    {
      Header: "Name",
      disableSortBy: true,
      disableFilters: true,

      accessor: "name",
    },
    {
      Header: "Title",
      disableSortBy: true,
      disableFilters: true,

      width: 140,
      accessor: "title",
    },
    {
      Header: "Email",
      disableSortBy: true,
      disableFilters: true,

      width: 140,
      accessor: "email",
    },
    {
      Header: "Phone Number",
      disableSortBy: true,
      disableFilters: true,

      width: 140,
      accessor: "phoneNumber",
    },
    {
      Header: "Fax Number",
      disableSortBy: true,
      disableFilters: true,

      width: 140,
      accessor: "faxNumber",
    },
    {
      Header: "Office Number",
      disableSortBy: true,
      disableFilters: true,

      width: 140,
      accessor: "officeNumber",
    },

    {
      Header: "Actions",
      width: 140,
      disableSortBy: true,
      disableFilters: true,
      disableResizing: true,
      maxWidth: 160,
      Cell: ({ row }: any) => (
        <ActionCell
          row={row}
          onEdit={() => handleEditDialog(row.original)}
          onDelete={() => handleDeleteUser(row.original)}
        />
      ),
    },
  ]
  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box className={classes.appBar}>
          <Typography component={"h2"} variant="h4">
            Bank Owned Properties Team
          </Typography>
          <Box className={classes.appBarAction}>
            <Button
              backgroundColor="black"
              color="white"
              onClick={handleAddNewDialog}
            >
              Add New Member
            </Button>
          </Box>
        </Box>
        <Box className={classes.table}>
          <Table
            columns={columns}
            name="team-table"
            data={teamList}
            loading={loading}
            pagination
          />
        </Box>
        <AddTeamMember
          state={teamMemberState}
          onClose={handleCloseDialog}
          team={team}
          onJobDone={handleCloseDialogOnUpdate}
        />
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(0, 2, 4),
  },
  appBar: {
    position: "absolute",
    top: `${theme.headerHeight}px`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[2],
    zIndex: 9,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    width: `calc(100% - ${theme.dashboardWidth}px)`,
    marginLeft: "-24px",
    height: 80,
  },
  appBarAction: {
    "& *": {
      marginRight: theme.spacing(2),
    },
  },
  table: {
    marginTop: 90,
    height: "100%",
    display: "flex",
    overflowX: "hidden",
    overflowY: "auto",
  },
  viewButton: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    width: "inherit",
  },
}))
export default TeamView
