import { Edit } from "@mui/icons-material"
import { Avatar, Box, Button, Dialog, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { FirebaseStorage } from "api-client/firebase/storage"
import { FirebaseTeam } from "api-client/firebase/team"
import { ITeamMember } from "api-client/types"
import Form from "components/Atoms/Form"
import Loading from "components/Atoms/Loading/Loading"
import { FormField } from "CONSTANTS/forms"
import { SnackbarContext } from "contexts/UseSnackbar"
import React from "react"
import * as yup from "yup"

const teamApi = new FirebaseTeam();
const storageApi = new FirebaseStorage();

interface AddTeamMemberProps {
  state: boolean
  onClose(): void
  team?: ITeamMember | null | undefined
  onJobDone(member?: ITeamMember): void
}

const AddTeamMember: React.FC<AddTeamMemberProps> = ({
  state,
  onClose,
  team,
  onJobDone,
}: AddTeamMemberProps) => {
  const classes = useStyles()
  const [imageSRC, setImageSrc] = React.useState<any>("")
  const [imageFile, setImageFile] = React.useState<any>(null)
  const [imageLink, setImageLink] = React.useState<any>('');
  const [loading, setLoading] = React.useState<boolean>(false)
  const imageRef = React.useRef(null)
  const useSnackbar = React.useContext(SnackbarContext)
  const { openSnackbar } = useSnackbar

  const openErrorSnackBar = () =>
    openSnackbar({
      type: "error",
      message: "Something Went Wrong",
    })

  const handleClose = () => onClose()

  const updateTeam = async (value) => {
    setLoading(true)
    const user = { ...value}
    if(imageFile)
      user.image = imageLink 
    const res = await teamApi.updateTeamMember(user);
    setLoading(false)
    if (res.type === 'error') {
      return openErrorSnackBar()
    }

    openSnackbar({
      type: 'success',
      message: 'Team member updated'
    })

    onJobDone(user);

  }

  const createTeamMember = async (value) => {
    setLoading(true)
    const res = await teamApi.addTeamMember({...value, image: imageLink})
    setLoading(false)
    if(res.type === 'error'){
      return openSnackbar({
        type: 'error',
        message: res.error as string
      })
    }
    openSnackbar({
      type: 'success',
      message: 'Team member added'
    })
    onJobDone()
  }

  const handleSubmit = (value) => {
    if (team) return updateTeam(value)
    return createTeamMember(value)
  }

  const handleUpdate = async () => {
    if (imageFile) {
      setLoading(true);
      const res = await storageApi.uploadImage(imageFile);
      if(res.type === 'data'){
        setImageLink(res.data);
      }

    } 
    const button = document.getElementById("team-form-submit-button")
    button.click()
  }

  const handleImageEdit = () => {
    if (imageRef.current) imageRef.current.click()
  }

  const handleImageInputOnChange = (file: File) => {
    setImageFile(file)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setImageSrc(reader.result)
    }
  }

  return (
    <Dialog
      open={state}
      onClose={onClose}
      className={classes.root}
      classes={{
        paper: classes.container,
      }}
    >
      <Box>
        {loading && <Loading />}
        <Box className={classes.header}>
          <Typography variant="h4" component="h4">
            {team ? "Edit Team Member" : "Add a New Team Member"}
          </Typography>
          <Box className={classes.headerActions}>
            <Button variant="contained" color="error" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUpdate()}
            >
              {team ? "Update" : "Create"}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "grid",
            placeContent: "center",
            paddingTop: "16px",
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <input
              type="file"
              className={classes.inputField}
              ref={imageRef}
              accept="image/*"
              onChange={(e) =>
                handleImageInputOnChange(e.currentTarget.files[0])
              }
            />
            {(imageFile || team?.image) ? (
              <img src={imageFile ? imageSRC : team.image} className={classes.userImage} />
            ) : (
              <Avatar
                sx={{
                  height: 120,
                  width: 120,
                }}
              />
            )}
            <Box
              sx={{
                position: "absolute",
                top: "70%",
                right: "0px",
                cursor: "pointer",
              }}
              onClick={handleImageEdit}
            >
              <Edit
                sx={{
                  color: "#fff",
                  background: "#000",
                  borderRadius: "50%",
                  padding: "4px",
                  fontSize: "1.5rem",
                }}
              />
            </Box>
          </Box>
        </Box>
        <Form
          formData={TEAM_FORM}
          formikProps={{
            initialValues: {
              id: team ? team.id : "",
              email: team ? team.email : "",
              name: team ? team.name : "",
              title: team ? team.title : "",
              phoneNumber: team ? team.phoneNumber : "",
              officeNumber: team ? team.officeNumber : "",
              faxNumber: team ? team.faxNumber : "",
              image: team ? team.image : ''
            },
            validationSchema: validationSchema,
            onSubmit: (value) => handleSubmit(value),
          }}
          hideButton
          name="team-form"
          submitLabel="Submit"
        />
      </Box>
    </Dialog>
  )
}

const validationSchema = yup.object().shape({
  id: yup.string(),
  email: yup.string().email('Email is invalid').required('Email cannot be empty'),
  name: yup.string().required('Name cannot be empty'),
  phoneNumber: yup.string().required('Phone number is required'),
  title: yup.string().required('Title is required'),
  officeNumber: yup.string().required('Office number is required'),
  faxNumber: yup.string().required('Fax number is required'),
})

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 99999,
  },
  container: {
    minWidth: "80%",
    maxHeight: "100vh",
  },
  header: {
    display: "flex",
    width: "100%",
    borderBottom: "1px solid #000",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerActions: {
    "& button": {
      margin: 10,
    },
  },
  inputField: {
    display: "none",
  },
  userImage: {
    width: 120,
    height: 120,
    borderRadius: "50%",
  },
}))

const TEAM_FORM: Array<FormField> = [
  {
    label: "Full Name",
    name: "name",
    formFieldtype: "input",
    inputType: "text",
    col: true,
  },
  {
    label: "Title",
    name: "title",
    formFieldtype: "input",
    inputType: "text",
    col: true,
  },
  {
    label: "Email",
    name: "email",
    formFieldtype: "input",
    inputType: "text",
    col: true,
  },
  {
    label: "Phone Number",
    name: "phoneNumber",
    formFieldtype: "input",
    inputType: "text",
    col: true,
  },
  {
    label: "Office Number",
    name: "officeNumber",
    formFieldtype: "input",
    inputType: "text",
    col: true,
  },
  {
    label: "Fax Number",
    name: "faxNumber",
    formFieldtype: "input",
    inputType: "text",
    col: true,
  },
]
export default AddTeamMember
