import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  StandardTextFieldProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import { dropdownValue, FormField } from "CONSTANTS/forms"
import _ from "lodash"
import React from "react"
import { FileImage } from "./FileImage"

interface inputProps extends Partial<FormField> {
  label: string
  formFieldtype?: "input" | "dropdown" | "upload" | "Array"
  classes?: {
    root?: string
    label?: string
  }
  col?: boolean
  children?: Array<FormField>
  name?: string
  inputType?: "text" | "email" | "password" | "tel" | "number" | "file" | "date"
  dropdownValues?: Array<dropdownValue>
  endIcon?: JSX.Element
  selectedMedia?: Array<any>
  onMediaSelect?: React.Dispatch<any>
  handleDeleteFile?(index: number | string): void
  mediaSelectProps?: {
    isLoading?: boolean
  }
}
interface FormFieldProps extends Partial<StandardTextFieldProps> {
  props: Partial<StandardTextFieldProps> & inputProps
  value: any | unknown
}

const FormInputField: React.FC<FormFieldProps> = ({
  props,
  value,
  onChange,
  ...textFieldProps
}: FormFieldProps) => {
  const {
    label,
    className,
    classes,
    formFieldtype,
    selectedMedia,
    onMediaSelect,
    handleDeleteFile,
    ...rest
  } = props
  const cssStyles = useStyles()

  const ref = React.useRef<HTMLInputElement>()
  return (
    <Grid
      item
      md={props.col && props.formFieldtype !== "upload" ? 6 : 12}
      sm={12}
      className={clsx(cssStyles.root, classes?.root)}
    >
      <Box className={clsx(cssStyles.textFieldLabelContainer)}>
        <Typography
          className={clsx(classes?.label, cssStyles.textFieldLabel)}
          variant="subtitle1"
        >
          {_.upperFirst(label)}
        </Typography>
      </Box>
      {formFieldtype === "input" && (
        <TextField
          onChange={onChange}
          name={rest.name}
          type={rest.inputType}
          value={value}
          InputProps={{
            endAdornment: rest.endIcon,
          }}
          className={clsx(cssStyles.textField, className)}
          rows={rest.row ? rest.row : 1}
          multiline={rest.row ? true : false}
          fullWidth
          {...textFieldProps}
        />
      )}
      {formFieldtype === "dropdown" && (
        <TextField
          className={clsx(cssStyles.textField, className)}
          onChange={onChange}
          name={rest.name}
          type={rest.inputType}
          value={value}
          select
          fullWidth
          {...textFieldProps}
        >
          {rest.dropdownValues.map((item, index) => {
            return (
              <MenuItem key={`${item.value}-${index}`} value={item.value}>
                {item.label}
              </MenuItem>
            )
          })}
        </TextField>
      )}
      {formFieldtype === "upload" && (
        <Box
          sx={{
            position: "relative",
          }}
        >
          {props.mediaSelectProps && props.mediaSelectProps.isLoading && (
            <Box className={cssStyles.isImageLoading}>
              <CircularProgress color="secondary" />
            </Box>
          )}
          <Box
            className={clsx(cssStyles.selectedImage, {
              [cssStyles.noImage]: selectedMedia?.length === 0,
              [cssStyles.noOverFlow]: props.mediaSelectProps?.isLoading,
            })}
          >
            {props.mediaSelectProps &&
              selectedMedia?.length === 0 &&
              props.mediaSelectProps &&
              !props.mediaSelectProps.isLoading && (
                <Typography>No Image Selected</Typography>
              )}
            {selectedMedia?.map((file, index) => (
              <FileImage
                file={file}
                key={index}
                handleDeleteFile={() => handleDeleteFile(index)}
                disabled={textFieldProps.disabled}
              />
            ))}
          </Box>
          <Button
            className={cssStyles.selectImage}
            onClick={() => ref.current.click()}
            variant="contained"
            disabled={
              Boolean(props?.mediaSelectProps?.isLoading) ||
              textFieldProps.disabled
            }
          >
            Select Images
          </Button>
          <input
            name={rest.name}
            type="file"
            accept="image/*"
            multiple
            ref={ref}
            onChange={(e) => onMediaSelect(e.currentTarget.files)}
            style={{
              display: "none",
            }}
          />
        </Box>
      )}
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 1),
  },
  selectImage: {
    padding: theme.spacing(2, 2),
  },
  textFieldLabel: {
    fontWeight: "600 !important",
    color: theme.palette.common.black,
    textTransform: "capitalize",
  },
  textField: {},
  textFieldLabelContainer: {
    margin: theme.spacing(1, 0),
  },
  selectedImage: {
    height: "200px",
    backgroundColor: theme.colors.grey,
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.colors.overlayBlack}`,
    display: "flex",
    padding: theme.spacing(1),
    flexDirection: "row",
    flexWrap: "wrap",
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
  },
  noOverFlow: {
    overflow: "hidden !important",
  },
  noImage: {
    alignItems: "center",
    justifyContent: "center",
  },
  isImageLoading: {
    position: "absolute",
    width: "100%",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    zIndex: 999,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: alpha(theme.colors.lightBlack, 0.6),
    margin: `0px`,
  },
}))

export default FormInputField
