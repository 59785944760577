import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import CopyrightsText from 'components/Atoms/Copyrights'

const Footer = () => {
  const classes = useStyles()
  return (
    <Box component='footer' className={classes.root}>
      <Box className={classes.innerContainer}>
        <CopyrightsText color='white' />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: theme.footerHeight,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    right: 0,
    backgroundColor: theme.colors.darkGrey,
  },
  innerContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}))

export default Footer
