import DashboardLayout from "layout/DashboardLayout";
import { makeStyles } from "@mui/styles";
import { Box, Button, Theme, Typography } from "@mui/material";
import Table from "components/Table/Table";
import { Column } from "react-table";
import { useEffect, useState } from "react";
import { ILendingInquiry } from "api-client/types";
import { FirebaseLendingInquiry } from "api-client/firebase/lendingInquiry";
import { useNavigate, useParams } from "react-router-dom";

const LendingInquiryApi = new FirebaseLendingInquiry();

const LendingInquiryView = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [inquiries, setInquiries] = useState<Array<ILendingInquiry>>([]);
  const { id } = useParams()
  const navigate = useNavigate()

  const fetchOffers = async () => {
    setLoading(true);
    const res = await LendingInquiryApi.fetchLendingInquirys(id);
    if(res.type === 'data')
      setInquiries(res.data);
    setLoading(false)
  }

  useEffect(() =>{
    fetchOffers()
  }, [])

  const columns: Array<Column<any>> = [
    { Header: "Name", accessor: "name", width: 100 },
    { Header: "Email", accessor: "email", width: 140 },
    { Header: "Phone", accessor: "phone", width: 120 },
    { Header: "Loan Type", width: 100, accessor: "loanType" },
    { Header: "Property Address", width: 200, accessor: "propertyAddress" },
    { Header: "Message", width: 220, accessor: "message" },
  ];

  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box>
          <Typography component={"h2"} variant="h4">
            Lending Inquiries
          </Typography>
        </Box>
        <Table
          name="lending-inquiries"
          data={inquiries}
          loading={loading}
          columns={columns}
        />
      </Box>
    </DashboardLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
  },
}));

export default LendingInquiryView;
