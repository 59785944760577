import { db } from "api-client/config/firebase";
import { FirebaseCollection } from "api-client/enums";
import { Property } from "api-client/models/Property";
import {
  CreatePropertyReq,
  DeletePropertyReq,
  FirebaseResponse,
  IFirebaseProperty,
  IProperties,
  IProperty,
  ReadPropertyReq,
} from "api-client/types";
import { generateDocPath, IPagination } from "api-client/utils";
import {
  collection,
  deleteDoc,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { Status } from "interfaces";
import {v4 as uuid} from "uuid"

export class FirebaseProperty implements IFirebaseProperty {

  collectionRef = collection(db, FirebaseCollection.Properties);
  docRef = (id: string) => doc(db, FirebaseCollection.Properties, id);

  lastProperty: DocumentSnapshot;

  importProperties = async (payload: Array<Array<IProperty>>) => {

    try {
      if(payload.length === 0)
        return;

      let promises = [];  
        
      payload.forEach(chunk => {
        const batch = writeBatch(db);
        chunk.forEach(item => {
          const property = new Property(item);
          batch.set(this.docRef(property.id), {...property}); 
        });
        promises.push(batch.commit());
      });

      await Promise.allSettled(promises);

      return {
        type: "data",
        data: "Properties added successfully",
      } as FirebaseResponse<String>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };

  async readProperties(pagination: IPagination = {pageSize: 100, startAt: undefined}, status: string = ''): Promise<FirebaseResponse<IProperty[]>> {
    const result: IProperties = [];
    const collectionRef = this.collectionRef;
    const queries = [orderBy('publishedAt', 'desc')]
    if(status && status !== 'all'){
      queries.push(where('status', '==', status))
    }
    const docsQuery = query(collectionRef, ...queries);
    const snapshot = await getDocs(docsQuery);
    snapshot.forEach((doc) => {
      result.push({ id: doc.id, ...doc.data() } as IProperty);
    });
    if(!snapshot.empty)
      this.lastProperty = snapshot.docs[snapshot.docs.length - 1];
    return {
      type: "data",
      data: result,
    };
  }
  
  createProperty = async (payload: CreatePropertyReq) => {
    try {
      await setDoc(this.docRef(payload.id), payload);
      return {
        type: "data",
        data: payload,
      } as FirebaseResponse<IProperty>;
    } catch (error) {
      return {
        type: "error",
        data: error,
      } as unknown as FirebaseResponse<any>;
    }
  };

  async deleteProperty(payload: DeletePropertyReq) {
    try {
      const docRef = doc(
        db,
        generateDocPath(FirebaseCollection.Properties, payload.id)
      );
      await deleteDoc(docRef);
      return {
        type: "data",
        data: "Properties deleted successfully",
      } as FirebaseResponse<string>;
    } catch (error) {
      return {
        type: "error",
        data: error,
      } as unknown as FirebaseResponse<any>;
    }
  }

  async updateProperty(payload: { id: string; data: IProperty }) {
    try {
      const { id, data } = payload;
      if (!id || !data) throw new Error("payload is missing");
    
      const docRef = this.docRef(id);
      await setDoc(docRef, {...data});
      return {
        type: "data",
        data,
      } as FirebaseResponse<IProperty>;
    } catch (error) {
      return {
        type: "error",
        data: error,
      } as unknown as FirebaseResponse<string>;
    }
  }
  async readProperty(
    payload: ReadPropertyReq
  ): Promise<FirebaseResponse<IProperty>> {
    try {
      if (!payload.id) throw new Error("payload is missing");
      const { id } = payload;
      const docRef = doc(
        db,
        generateDocPath(FirebaseCollection.Properties, id)
      );
      const docRes = await getDoc(docRef);
      const property = new Property({
        id: docRes.id,
        ...(docRes.data() as IProperty),
      });
      return {
        type: "data",
        data: property,
      } as FirebaseResponse<IProperty>;
    } catch (error) {
      return {
        type: "error",
        data: error,
      } as unknown as FirebaseResponse<IProperty>;
    }
  }

  async duplicateProperty(payload: IProperty): Promise<FirebaseResponse<IProperty>>{
    payload.id = uuid();
    return this.createProperty({...payload});
  }

  deleteProperties = async (payload: Array<IProperty>) => {
    try {
      const batch = writeBatch(db);
      payload.forEach((item) => batch.delete(this.docRef(item.id)));
      await batch.commit();
      return {
        type: "data",
        data: "Properties deleted successfully",
      } as FirebaseResponse<String>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };

  updatePropertyStatus = async (property: IProperty, status: Status) => {
    try {
      if (!property.id) throw new Error("payload is missing");
      const docRef = this.docRef(property.id);
      await updateDoc(docRef, {status})
      return {
        type: "data",
        data: "Property status updated",
      } as FirebaseResponse<string>;
    } catch (error) {
      return {
        type: "error",
        data: error,
      } as unknown as FirebaseResponse<string>;
    }
  }

  updatePropertiesStatus = async (properties: Array<IProperty>, status: Status): Promise<FirebaseResponse<any>> => {
    try {
      const batch = writeBatch(db);
      properties.forEach((item) => batch.update(this.docRef(item.id), { status }));
      await batch.commit();
      return {
        type: "data",
        data: "Properties updated successfully",
      } as FirebaseResponse<String>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  }

  republishProperty = async (propertyId: string) : Promise<FirebaseResponse<any>> => {
    try{
      if(!propertyId) throw Error();
      updateDoc(this.docRef(propertyId), {publishedAt: new Date()});
      return {
        type: "data",
        data: "Property updated successfully",
      } as FirebaseResponse<String>;
    }catch(e){
      return {
        type: "error",
        data: "Something went wrong",
      } as unknown as FirebaseResponse<String>;
    }
    
  }
}
