import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

type CopyrightsProps = {
  color: 'black' | 'white'
}

const CopyrightsText: React.FC<CopyrightsProps> = ({
  color,
}: CopyrightsProps) => {
  const classes = useStyles({ color })
  return (
    <Typography variant='body2' className={classes.copyrightText}>
      Powered by Big O Soft LLC
    </Typography>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  copyrightText: ({ color }: CopyrightsProps) => ({
    color: color === 'white' ? theme.colors.white : theme.colors.black,
    fontSize: '8px',
  }),
}))

export default CopyrightsText
