import { Delete, Edit } from "@mui/icons-material";
import { Box, Button, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FirebasePromotion } from "api-client/firebase/promotion";
import { FirebaseStorage } from "api-client/firebase/storage";
import PromotionDialog from "components/Promotions/PromotionDialog";
import UpdatePromotionDialog from "components/Promotions/UpdatePromotionDialog";
import { SnackbarContext } from "contexts/UseSnackbar";
import { useFormik } from "formik";
import DashboardLayout from "layout/DashboardLayout";
import _ from "lodash";
import React, { useContext } from "react";

const promotionApi = new FirebasePromotion();
const storageApi = new FirebaseStorage();

const PromotionsView: React.FC = () => {
  const classes = useStyles();
  const [dialogState, setDialogState] = React.useState(false);
  const [updateDialogState, setUpdateDialogState] = React.useState(false);
  const [promotions, setPromotions] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({
    id: null,
    name: "",
    link: "",
    image: null,
    status: "ACTIVE",
    createdAt: new Date(),
  });

  const handleUpdateDialogClose = () => {
    setSelectedRow({
      id: null,
      name: "",
      link: "",
      image: null,
      status: "ACTIVE",
      createdAt: new Date(),
    });
    setUpdateDialogState(false);
  };
  const handleUpdateDialogOpen = () => setUpdateDialogState(true);

  const handleDialogOpen = () => {
    setDialogState(true);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    handleUpdateDialogOpen();
  };

  const handleDialogClose = () => setDialogState(false);

  const handleSubmit = async (values) => {
    const promotion = { ...values };
    if (promotion.image) {
      const res = await storageApi.uploadImage(promotion.image);
      if (res.type === "data") promotion.image = res.data;
    }

    const res = await promotionApi.createPromotion(promotion);

    if (res.type === "error") {
      return openSnackbar({
        type: "error",
        message: res.error,
      });
    }
    handleDialogClose();
    openSnackbar({
      type: "success",
      message: res.data,
    });
    fetchAllPromotions();
  };

  const { openSnackbar } = useContext(SnackbarContext);

  const fetchAllPromotions = async () => {
    setLoading(true);
    const res = await promotionApi.fetchPromotions();
    setLoading(false);
    if (res.type === "error") {
      return openSnackbar({
        type: "error",
        message: "Error fetching promotions",
      });
    }
    setPromotions(res.data);
  };

  const handleUpdateSubmit = async (values) => {
    const promotion = { ...values };
    if (promotion.image && typeof promotion.image !== "string") {
      const res = await storageApi.uploadImage(promotion.image);
      if (res.type === "data") promotion.image = res.data;
    }
    const res = await promotionApi.updatePromotion(promotion);
    if (res.type === "error")
      return openSnackbar({
        type: "error",
        message: res.error,
      });

    handleUpdateDialogClose();
    openSnackbar({
      type: "success",
      message: res.data,
    });
    fetchAllPromotions();
  };

  React.useEffect(() => {
    fetchAllPromotions();
  }, []);

  const formik = useFormik({
    initialValues: selectedRow,
    onSubmit: handleSubmit,
  });

  const handleDelete = async (id) => {
    setLoading(true);
    const res = await promotionApi.deletePromotion(id);
    setLoading(false);

    if (res.type === "error") {
      return openSnackbar({
        type: "error",
        message: res.error,
      });
    }

    openSnackbar({
      type: "success",
      message: res.data,
    });
    const filtered = _.filter(promotions, (promotion) => promotion.id !== id);
    setPromotions(filtered);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Start Date",
      flex: 1,
      valueFormatter: params => {
        let date = new Date(params.value.seconds * 1000);
        return date.toLocaleDateString()
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "xy",
      headerName: "Edit",
      width: 100,
      renderCell: ({ row }) => (
        <Edit
          sx={{
            pointer: "cursor",
          }}
          onClick={() => handleRowClick(row)}
        />
      ),
      disableColumnMenu: true,
    },
    {
      field: "",
      headerName: "Delete",
      width: 100,
      renderCell: ({ row }) => (
        <Delete
          sx={{
            pointer: "cursor",
          }}
          onClick={() => handleDelete(row.id)}
        />
      ),
      disableColumnMenu: true,
    },
  ];
  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "16px",
            backgroundColor: "#ccc",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "22px",
              fontWeight: "900",
            }}
          >
            Promotions
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={handleDialogOpen}
          >
            Create New Promotion
          </Button>
        </Box>
        <DataGrid
          rows={promotions}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          loading={isLoading}
        />

        {dialogState && (
          <PromotionDialog
            state={dialogState}
            onClose={handleDialogClose}
            formik={formik}
          />
        )}
        {updateDialogState && (
          <UpdatePromotionDialog
            state={updateDialogState}
            onClose={handleUpdateDialogClose}
            initState={selectedRow}
            handleFormSubmit={handleUpdateSubmit}
          />
        )}
      </Box>
    </DashboardLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
  },
  viewButton: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    width: "inherit",
  },
}));

export default PromotionsView;
