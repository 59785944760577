import { DollarIcon } from "components/Atoms/Icons"
import { FormField } from "CONSTANTS/forms"
import { STATES } from "CONSTANTS/states"
import { PropertyStatus, PropertyType } from "api-client/enums"
import * as React from "react"
import { BrokerContext } from "contexts/BrokerContext"

const useAddPropertyFormData = () => {

  const { brokersList, isLoadingBrokers } = React.useContext(BrokerContext);

  const brokers = brokersList.map(item => {
    return {
        label: `${item.name} (${item.brokerageName})`,
        value: item.id,
    }
  })

  const AddNewPropertyFormData: Array<FormField> = [
    {
      label: "Title",
      formFieldtype: "input",
      name: "title",
      inputType: "text",
    },
    {
      label: "Asking Price",
      formFieldtype: "input",
      name: "askingPrice",
      inputType: "number",
      endIcon: <DollarIcon />,
    },
    {
      label: "property Description",
      formFieldtype: "input",
      name: "description",
      inputType: "text",
      row: 4,
    },
    {
      label: "Zoning",
      formFieldtype: "input",
      name: "zoning",
      inputType: "text",
    },
    {
      label: "Broker",
      formFieldtype: "dropdown",
      name: "brokerID",
      dropdownValues: brokers,
    },
    {
      label: "Property Type",
      formFieldtype: "dropdown",
      name: "propertyType",
      dropdownValues: [
        {
          label: "None",
          value: "",
        },
        {
          label: "ESTATE SALE",
          value: PropertyType.estate_sale,
        },
        {
          label: "FORECLOSURE",
          value: PropertyType.foreclosure,
        },
        {
          label: "REO",
          value: PropertyType.reo,
        },
        {
          label: "SHORT SALE",
          value: PropertyType.short_sale,
        },
        {
          label: "LAND",
          value: PropertyType.land,
        },
        {
          label: "MORTGAGE",
          value: PropertyType.mortgage,
        },
        {
          label: "Contract Flip",
          value: PropertyType.contract_flip,
        },
        {
          label: "Commercial",
          value: PropertyType.commercial,
        },
      ],
    },
    {
      label: "Property Details",
      name: "PropertyDetails",
      formFieldtype: "Array",
      children: [
        {
          label: "bathrooms",
          formFieldtype: "input",
          name: "bathRooms",
          inputType: "text",
        },
        {
          label: "bedrooms",
          formFieldtype: "input",
          name: "bedRooms",
          inputType: "text",
        },
        {
          label: "lot Size",
          formFieldtype: "input",
          name: "lotSize",
          inputType: "text",
        },
        {
          label: "square Footage",
          formFieldtype: "input",
          name: "squareFootage",
          inputType: "text",
        },
        {
          label: "Property Status",
          formFieldtype: "dropdown",
          name: "propertyStatus",
          dropdownValues: [
            {
              label: "OCCUPIED",
              value: PropertyStatus.OCCUPIED,
            },
            {
              label: "VACANT",
              value: PropertyStatus.VACANT,
            },
          ],
        },
        {
          label: "garage",
          formFieldtype: "dropdown",
          name: "garage",
          dropdownValues: [
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ],
        },
        {
          label: "private Driveway",
          formFieldtype: "dropdown",
          name: "privateDriveway",
          dropdownValues: [
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ],
        },
      ],
    },

    {
      label: "Media",
      name: "PropertyMedia",
      formFieldtype: "Array",
      children: [
        {
          label: "property Images",
          formFieldtype: "upload",
          name: "images",
          inputType: "file",
        },
        {
          label: "property Video",
          formFieldtype: "input",
          name: "video",
          inputType: "text",
        },
      ],
    },

    {
      label: "Property Address",
      name: "PropertyAddress",
      formFieldtype: "Array",
      children: [
        {
          label: "property Street Address",
          formFieldtype: "input",
          name: "streetAddress",
          inputType: "text",
        },
        {
          label: "City",
          formFieldtype: "input",
          name: "city",
          inputType: "text",
        },
        {
          label: "Zip Code",
          formFieldtype: "input",
          name: "zipCode",
          inputType: "text",
        },
        {
          label: "State",
          formFieldtype: "dropdown",
          name: "state",
          inputType: "text",
          dropdownValues: STATES,
        },
      ],
    },
    {
      label: "Source Details",
      formFieldtype: "Array",
      children: [
        {
          label: "Full Name",
          inputType: "text",
          name: "sourceName",
          formFieldtype: "input",
        },
        {
          label: "Phone Number",
          inputType: "text",
          name: "sourcePhoneNumber",
          formFieldtype: "input",
        },
        {
          label: "source Email",
          inputType: "text",
          name: "sourceEmail",
          formFieldtype: "input",
        },
        {
          label: "source Property Status",
          formFieldtype: "dropdown",
          name: "sourceStatus",
          dropdownValues: [
            { label: "Sold", value: "SOLD" },
            { label: "Contract", value: "CONTRACT" },
            { label: "Available", value: "AVAILABLE" },
          ],
        },
        {
          label: "purchase Price",
          formFieldtype: "input",
          name: "purchasePrice",
          inputType: "text",
        },
        {
          label: "Sold Price",
          formFieldtype: "input",
          name: "soldPrice",
          inputType: "text",
        },
      ],
    },
  ]
  return {
    AddNewPropertyFormData,
    loading: isLoadingBrokers,
  }
}

export default useAddPropertyFormData
