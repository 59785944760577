/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";
import { PropertyStatus, PropertyType, SourceStatus } from "api-client/enums";
import { FirebaseStorage } from "api-client/firebase/storage";
import { Property } from "api-client/models/Property";
import { IProperty } from "api-client/types";
import FormInputField from "components/Atoms/FormField";
import Loading from "components/Atoms/Loading/Loading";
import { FormField } from "CONSTANTS/forms";
import { PropertyContext } from "contexts/PropertyContext";
import { SnackbarContext } from "contexts/UseSnackbar";
import { useFormik } from "formik";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { App_Routes } from "routes";
import * as yup from "yup";
import PropertyCreatedDialog from "./Atoms/SubmissionCompleted";
import useAddPropertyFormData from "./Form";

const storageApi = new FirebaseStorage();

const AddPropertyForm: React.FC = () => {
  const classes = useStyles();
  const [propertyImages, setPropertyImage] = React.useState([]);
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [isImageLoading, setIsImageLoading] = React.useState(false);
  const [deletedFiles, setDeletedFiles] = React.useState<Array<any>>([]);
  const { AddNewPropertyFormData, loading } = useAddPropertyFormData();

  const { createProperty } = React.useContext(PropertyContext);

  const { openSnackbar } = React.useContext(SnackbarContext);

  const navigate = useNavigate();

  const formik = useFormik({
    validationSchema: ValidationScheme,
    initialValues: initialValues,
    onSubmit: (values) => handleSubmit(values as any),
  });

  const deleteImage = async (index: string | number) => {
    setIsImageLoading(true);
    const temp = _.clone(propertyImages);
    const deletedFilesArray = _.clone(deletedFiles);
    deletedFilesArray.push(temp[index]);
    _.remove(temp, (val) => temp[index] === val);
    formik.setValues({
      ...formik.values,
      images: temp,
      featuredImage: temp[0] ? temp[0] : "",
    });
    setPropertyImage(temp);
    setIsImageLoading(false);
    setDeletedFiles(deletedFilesArray);
  };

  const handleSubmit = async (values: IProperty) => {
    try {
      const property = new Property(values);
      await createProperty(property.toJSON());
      navigate(App_Routes.PROPERTIES_PAGE);
    } catch (e) {
      return openSnackbar({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  const onMediaSelect = async (values: Array<any>) => {
    setIsImageLoading(true);
    const temp = _.clone(propertyImages);
    const keys = _.clone(formik.values.images);
    _.mapValues(values, async (file, index) => {
      const res = await storageApi.uploadImage(file);

      if (res.type === "error") {
        openSnackbar({
          type: "error",
          message: "Something went wrong",
        });
        return setIsImageLoading(false);
      }

      formik.setValues({
        ...formik.values,
        featuredImage: res.data,
      });
      keys.push(res.data);
      temp.push(file);
      formik.setValues({
        ...formik.values,
        images: keys,
      });
      if (values.length - 1 === Number(index)) setIsImageLoading(false);
    });
    setPropertyImage(temp);
    setIsImageLoading(false);
  };

  const handlePropertyDialogClose = () => {
    setFormSubmitted(false);
    setPropertyImage([]);
    setFormSubmitted(false);
    setDeletedFiles([]);
    formik.resetForm();
  };
  const handlePropertyDialogRetry = () => {
    setFormSubmitted(false);
    setFailed(false);
  };
  if (!loading)
    return (
      <Grid container direction="row" component="div" className={classes.root}>
        {AddNewPropertyFormData.map((input: FormField, index) => {
          if (input.children) {
            return (
              <React.Fragment key={`${index}-${input.label}`}>
                <Grid item className={classes.section} md={12} sm={12}>
                  <Typography variant="h4">{input.label}</Typography>
                  <Divider />
                </Grid>
                {input.children.map((childInput) => {
                  const props = {
                    ...childInput,
                    col: true,
                    selectedMedia: propertyImages,
                    onMediaSelect: onMediaSelect,
                    mediaSelectProps: {
                      isLoading: isImageLoading,
                    },
                    handleDeleteFile: deleteImage,
                  };
                  return (
                    <FormInputField
                      key={`${index}-${childInput.label}`}
                      props={props}
                      onChange={formik.handleChange}
                      value={formik.values[childInput.name]}
                      error={Boolean(formik.errors[childInput.name])}
                      disabled={formik.isSubmitting}
                    />
                  );
                })}
              </React.Fragment>
            );
          }
          const props = {
            ...input,
          };
          return (
            <FormInputField
              key={input.label}
              props={props}
              onChange={formik.handleChange}
              value={formik.values[input.name]}
              error={Boolean(formik.errors[input.name])}
              disabled={formik.isSubmitting}
            />
          );
        })}

        <Grid item md={12} className={classes.section}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => formik.handleSubmit()}
            disabled={formik.isSubmitting || !formik.dirty}
            fullWidth
          >
            Create Property
          </Button>
        </Grid>
        <PropertyCreatedDialog
          state={formSubmitted}
          onClose={handlePropertyDialogClose}
          error={failed}
          onRetry={handlePropertyDialogRetry}
        />
      </Grid>
    );
  return <Loading />;
};

const ValidationScheme = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  askingPrice: yup.number().required(),
  lotSize: yup.string().required(),
  zipCode: yup.string().required(),
  brokerID: yup.string().required(),
});

const initialValues = {
  title: "",
  description: "",
  askingPrice: "",
  featuredImage: "",
  images: [],
  video: "",
  propertyStatus: PropertyStatus.VACANT,
  lotSize: "",
  bedRooms: "",
  bathRooms: "",
  garage: false,
  privateDriveway: false,
  squareFootage: "",
  streetAddress: "",
  state: "",
  propertyType: PropertyType.estate_sale,
  zipCode: " ",
  city: "",
  sourceName: "",
  sourcePhoneNumber: "",
  sourceEmail: "",
  sourceStatus: SourceStatus.AVAILABLE,
  soldPrice: "",
  purchasePrice: "",
  zoning: "",
  brokerID: "",
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    position: "relative",
    padding: theme.spacing(2),
  },
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  closeBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 36,
    height: 36,
  },
  textFieldContainer: {
    padding: theme.spacing(2, 1),
  },
  section: {
    padding: theme.spacing(2, 2),
  },
  textFieldLabel: {
    padding: theme.spacing(2, 0),
  },
}));

export default AddPropertyForm;
