import { auth } from 'api-client/config/firebase'
import { LoginReq } from 'api-client/types'
import { signInWithEmailAndPassword } from 'firebase/auth'

export class FirebaseAuth {

    async login(req: LoginReq){
         await signInWithEmailAndPassword(auth, req.email, req.password);
    }
}
