import { IBroker } from "api-client/types"

export class Broker implements IBroker {
  id: string
  name: string
  profileImage: string
  phoneNumber: string
  email: string
  title: string
  brokerageName: string

  constructor(p: IBroker) {
    for (const key in this) {
      if (Object.prototype.hasOwnProperty.call(p, key)) {
        this[key as unknown as string] = p[key as string]
      }
    }
  }

  update = (b: Partial<IBroker>) => {
    for (const key in this) {
      if (Object.prototype.hasOwnProperty.call(b, key)) {
        this[key as string] = b[key as string]
      }
    }
  }

  toJSON() {
    return { ...this }
  }
}
