import { INewsletter } from "api-client/types"

export class Newsletter implements INewsletter {
  id?: string
  email: string
  constructor(p?: INewsletter) {
    if(!p) return
    this.id = p.id
    this.email = p.email
  }

  setEmail(email: string) {
    this.email = email
  }

  getEmail() {
    return this.email as string
  }

  setId(id: string) {
    this.id = id
  }

  setter(p: INewsletter){
    for (let key in p) {
        this[key] = p[key]
    }
  }

  toJSON() {
    return {
      id: this.id,
      email: this.email,
    } as INewsletter
  }
}

