import { PropertyStatus, PropertyType, SourceStatus } from "api-client/enums"

import { IProperty } from "api-client/types"
import { Status } from "interfaces"
import { v4 as uuid } from "uuid"

export class Property implements IProperty {
  id: string
  title: string
  featuredImage: string
  video: string
  images: string[]
  description: string
  askingPrice: string
  bathRooms: string
  bedRooms: string
  lotSize: string
  garage: boolean
  privateDriveway: boolean
  brokerID: string
  streetAddress: string
  city: string
  state: string
  zipCode: string
  createdAt: Date
  publishedAt: Date
  status: Status
  propertyStatus: PropertyStatus
  propertyType: PropertyType
  purchasePrice: string
  soldPrice: string
  sourceEmail: string
  sourceName: string
  sourcePhoneNumber: string
  sourceStatus: SourceStatus
  squareFootage: string
  zoning: string
  lat: number
  lng: number
  geohash: string

  constructor(p: Partial<IProperty>) {
    for (const key in this) {
      if ((key === "createdAt" || key === "publishedAt") && !this[key]) {
        this[key as unknown as string] = new Date()
      } else if (key === "status" && !this[key] && !p.status) {
        this[key as unknown as string] = Status.PUBLISHED
      } else if (key === "id" && !this.id && !p.id) {
        this.id = uuid()
      } else {
        this[key as unknown as string] = p[key as string]
      }
    }

    if ((!this.featuredImage || this.featuredImage === "N/A") && p?.images.length > 0) {
      this["featuredImage"] = p["images"][0]
    }
  }

  setCreatedAt() {
    this.createdAt = new Date()
  }

  setPublishAt() {
    this.publishedAt = new Date()
  }

  toJSON() {
    const { ...props } = this
    return {
      ...props,
    }
  }

  get(key: keyof IProperty) {
    return this[key]
  }
}
