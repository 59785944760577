import { ITeamMember } from "api-client/types";

export class TeamMember implements ITeamMember {
    
  title: string;
  email: string;
  name: string;
  phoneNumber: string;
  officeNumber: string;
  faxNumber: string;
  image?: string;

  constructor(p: ITeamMember) {
    for (const key in p) {
      this[key as keyof this] = p[key as keyof typeof p] as any;
    }
  }
}
