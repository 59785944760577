import { AmplifyProvider } from "@aws-amplify/ui-react";
import { AuthProvider } from "contexts/AuthContext";
import { ThemeProvider } from "@mui/system";
import { UserProvider } from "contexts/UserContext";
import { SnackbarProvider } from "contexts/UseSnackbar";
import amplifyTheme from "styles/amplifyUI/theme";
import theme from "styles/theme";
import { NewsletterProvider } from "contexts/NewsletterContext";
import { NewsletterGroupProvider } from "contexts/NewsletterGroupContext";
import { BrokerProvider } from "contexts/BrokerContext";
import { PropertyProvider } from "contexts/PropertyContext";
import { EmailTemplatesProvider } from "contexts/EmailTemplates";
import { MailProvider } from "contexts/MailContext";

const Providers = ({ children }) => {
  return (
    <AmplifyProvider theme={amplifyTheme} colorMode="dark">
      <SnackbarProvider>
        <AuthProvider>
          <UserProvider>
            <NewsletterGroupProvider>
              <NewsletterProvider>
                <BrokerProvider>
                  <PropertyProvider>
                    <MailProvider>
                      <EmailTemplatesProvider>
                        <ThemeProvider theme={theme}>{children}</ThemeProvider>
                      </EmailTemplatesProvider>
                    </MailProvider>
                  </PropertyProvider>
                </BrokerProvider>
              </NewsletterProvider>
            </NewsletterGroupProvider>
          </UserProvider>
        </AuthProvider>
      </SnackbarProvider>
    </AmplifyProvider>
  );
};

export default Providers;
