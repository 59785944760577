import { Box, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import DashboardLayout from "layout/DashboardLayout"

const DashboardView = () => {
  const classes = useStyles()
  return (
    <DashboardLayout>
      <Box className={classes.root}>
        <Typography>Welcome to BOP Admin Panel</Typography>
      </Box>
    </DashboardLayout>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.colors.lightGrey,
    padding: theme.spacing(2),
  },
}))
export default DashboardView
