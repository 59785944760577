import Geocode from "react-geocode";

Geocode.setApiKey('AIzaSyATNVgc1bynHw4RTuoB16dKY57chD9jyLE');
// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("us");
// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");
// Enable or disable logs. Its optional.
Geocode.enableDebug();

export const parseAddressToLatLng = async (address: string) => {
    try{
        const response = await Geocode.fromAddress(address);
        const { lat, lng } = response.results[0].geometry.location;
        return {lat, lng}
    }catch(e){
       console.log(e); 
    }
}