/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { NewsletterGroup } from "api-client/models/NewsletterGroup"
import { useFormik } from "formik"

interface SendEmailToGroupFormProps {
  onClose(): void
  state: boolean
  groups: Array<NewsletterGroup>
  onSubmit(values: { group: string }): void
}

const SendEmailToGroupForm = ({
  onClose,
  state,
  onSubmit,
  groups,
}: SendEmailToGroupFormProps) => {
  const formik = useFormik({
    initialValues: {
      group: "",
    },
    onSubmit: onSubmit,
  })

  return (
    <Dialog open={state} onClose={onClose}>
      <Box
        sx={{
          minWidth: 600,
          display: "flex",
          padding: "1rem",
          flexDirection: "column",
        }}>
        <Typography
          sx={{
            padding: "1rem",
            fontSize: "1.4rem",
          }}>
          Select the group you want to Send Email
        </Typography>
        <Divider />
        <Typography
          sx={{
            marginTop: "1rem",
          }}>
          Groups
        </Typography>
        <TextField
          fullWidth
          onChange={formik.handleChange}
          name='group'
          value={formik.values.group}
          select>
          <MenuItem value={""}></MenuItem>
          {groups.map((group) => (
            <MenuItem value={group.id}>{group.label}</MenuItem>
          ))}
        </TextField>
        <Button
          sx={{
            marginTop: "1rem",
          }}
          variant='contained'
          onClick={() => {
            formik.handleSubmit()
          }}>
          {formik.isSubmitting ? <CircularProgress color="secondary" /> : 'Submit'}
        </Button>
      </Box>
    </Dialog>
  )
}

export default SendEmailToGroupForm
