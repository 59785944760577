/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBlogModel = /* GraphQL */ `
  mutation CreateBlogModel(
    $input: CreateBlogModelInput!
    $condition: ModelBlogModelConditionInput
  ) {
    createBlogModel(input: $input, condition: $condition) {
      id
      title
      author
      dat
      createdBy
      createdAt
      updatedAt
    }
  }
`
export const updateBlogModel = /* GraphQL */ `
  mutation UpdateBlogModel(
    $input: UpdateBlogModelInput!
    $condition: ModelBlogModelConditionInput
  ) {
    updateBlogModel(input: $input, condition: $condition) {
      id
      title
      author
      dat
      createdBy
      createdAt
      updatedAt
    }
  }
`
export const deleteBlogModel = /* GraphQL */ `
  mutation DeleteBlogModel(
    $input: DeleteBlogModelInput!
    $condition: ModelBlogModelConditionInput
  ) {
    deleteBlogModel(input: $input, condition: $condition) {
      id
      title
      author
      dat
      createdBy
      createdAt
      updatedAt
    }
  }
`
export const createPropertyModel = /* GraphQL */ `
  mutation CreatePropertyModel(
    $input: CreatePropertyModelInput!
    $condition: ModelPropertyModelConditionInput
  ) {
    createPropertyModel(input: $input, condition: $condition) {
      id
      title
      description
      askingPrice
      details {
        propertyStatus
        lotSize
        bedRooms
        bathRooms
        swimmingPool
        sunRoom
        garage
        privateDriveway
        squareFootage
        yearBuilt
      }
      featuredImage
      images
      video
      address {
        streetAddress
        city
        state
        zipCode
      }
      propertyState
      addedOn
      propertyType
      propertySource {
        sourceName
        sourcePhoneNumber
        sourceEmail
        propertyStatus
        purchasePrice
        askingPrice
        soldPrice
      }
      zoning
      status
      brokerID
      broker {
        id
        name
        email
        phoneNumber
        title
        brokerageName
        licenseNumber
        image
        createdAt
        updatedAt
        owner
      }
      streetAddress
      city
      state
      zipCode
      propertyStatus
      lotSize
      beds
      baths
      garage
      privateDriveway
      squareFootage
      createdAt
      updatedAt
      owner
    }
  }
`
export const updatePropertyModel = /* GraphQL */ `
  mutation UpdatePropertyModel(
    $input: UpdatePropertyModelInput!
    $condition: ModelPropertyModelConditionInput
  ) {
    updatePropertyModel(input: $input, condition: $condition) {
      id
      title
      description
      askingPrice
      details {
        propertyStatus
        lotSize
        bedRooms
        bathRooms
        swimmingPool
        sunRoom
        garage
        privateDriveway
        squareFootage
        yearBuilt
      }
      featuredImage
      images
      video
      address {
        streetAddress
        city
        state
        zipCode
      }
      propertyState
      addedOn
      propertyType
      propertySource {
        sourceName
        sourcePhoneNumber
        sourceEmail
        propertyStatus
        purchasePrice
        askingPrice
        soldPrice
      }
      zoning
      status
      brokerID
      broker {
        id
        name
        email
        phoneNumber
        title
        brokerageName
        licenseNumber
        image
        createdAt
        updatedAt
        owner
      }
      streetAddress
      city
      state
      zipCode
      propertyStatus
      lotSize
      beds
      baths
      garage
      privateDriveway
      squareFootage
      createdAt
      updatedAt
      owner
    }
  }
`
export const deletePropertyModel = /* GraphQL */ `
  mutation DeletePropertyModel(
    $input: DeletePropertyModelInput!
    $condition: ModelPropertyModelConditionInput
  ) {
    deletePropertyModel(input: $input, condition: $condition) {
      id
      title
      description
      askingPrice
      details {
        propertyStatus
        lotSize
        bedRooms
        bathRooms
        swimmingPool
        sunRoom
        garage
        privateDriveway
        squareFootage
        yearBuilt
      }
      featuredImage
      images
      video
      address {
        streetAddress
        city
        state
        zipCode
      }
      propertyState
      addedOn
      propertyType
      propertySource {
        sourceName
        sourcePhoneNumber
        sourceEmail
        propertyStatus
        purchasePrice
        askingPrice
        soldPrice
      }
      zoning
      status
      brokerID
      broker {
        id
        name
        email
        phoneNumber
        title
        brokerageName
        licenseNumber
        image
        createdAt
        updatedAt
        owner
      }
      streetAddress
      city
      state
      zipCode
      propertyStatus
      lotSize
      beds
      baths
      garage
      privateDriveway
      squareFootage
      createdAt
      updatedAt
      owner
    }
  }
`
export const createBrokerModel = /* GraphQL */ `
  mutation CreateBrokerModel(
    $input: CreateBrokerModelInput!
    $condition: ModelBrokerModelConditionInput
  ) {
    createBrokerModel(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      title
      brokerageName
      licenseNumber
      image
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateBrokerModel = /* GraphQL */ `
  mutation UpdateBrokerModel(
    $input: UpdateBrokerModelInput!
    $condition: ModelBrokerModelConditionInput
  ) {
    updateBrokerModel(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      title
      brokerageName
      licenseNumber
      image
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteBrokerModel = /* GraphQL */ `
  mutation DeleteBrokerModel(
    $input: DeleteBrokerModelInput!
    $condition: ModelBrokerModelConditionInput
  ) {
    deleteBrokerModel(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      title
      brokerageName
      licenseNumber
      image
      createdAt
      updatedAt
      owner
    }
  }
`
export const createUserModel = /* GraphQL */ `
  mutation CreateUserModel(
    $input: CreateUserModelInput!
    $condition: ModelUserModelConditionInput
  ) {
    createUserModel(input: $input, condition: $condition) {
      userID
      firstName
      lastName
      profileImageURL {
        key
        url
      }
      email
      phoneNumber
      userStatus
      createdAt
      updatedAt
    }
  }
`
export const updateUserModel = /* GraphQL */ `
  mutation UpdateUserModel(
    $input: UpdateUserModelInput!
    $condition: ModelUserModelConditionInput
  ) {
    updateUserModel(input: $input, condition: $condition) {
      userID
      firstName
      lastName
      profileImageURL {
        key
        url
      }
      email
      phoneNumber
      userStatus
      createdAt
      updatedAt
    }
  }
`
export const deleteUserModel = /* GraphQL */ `
  mutation DeleteUserModel(
    $input: DeleteUserModelInput!
    $condition: ModelUserModelConditionInput
  ) {
    deleteUserModel(input: $input, condition: $condition) {
      userID
      firstName
      lastName
      profileImageURL {
        key
        url
      }
      email
      phoneNumber
      userStatus
      createdAt
      updatedAt
    }
  }
`
export const deleteLikedProperty = /* GraphQL */ `
  mutation DeleteLikedProperty(
    $input: DeleteLikedPropertyInput!
    $condition: ModelLikedPropertyConditionInput
  ) {
    deleteLikedProperty(input: $input, condition: $condition) {
      id
      userID
      propertyID
      createdAt
      updatedAt
      owner
    }
  }
`
export const createFormsModel = /* GraphQL */ `
  mutation CreateFormsModel(
    $input: CreateFormsModelInput!
    $condition: ModelFormsModelConditionInput
  ) {
    createFormsModel(input: $input, condition: $condition) {
      id
      formTitle
      formDescription
      formFile {
        key
        url
      }
      createdAt
      updatedAt
    }
  }
`
export const updateFormsModel = /* GraphQL */ `
  mutation UpdateFormsModel(
    $input: UpdateFormsModelInput!
    $condition: ModelFormsModelConditionInput
  ) {
    updateFormsModel(input: $input, condition: $condition) {
      id
      formTitle
      formDescription
      formFile {
        key
        url
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteFormsModel = /* GraphQL */ `
  mutation DeleteFormsModel(
    $input: DeleteFormsModelInput!
    $condition: ModelFormsModelConditionInput
  ) {
    deleteFormsModel(input: $input, condition: $condition) {
      id
      formTitle
      formDescription
      formFile {
        key
        url
      }
      createdAt
      updatedAt
    }
  }
`
export const createBOPForm = /* GraphQL */ `
  mutation CreateBOPForm(
    $input: CreateBOPFormInput!
    $condition: ModelBOPFormConditionInput
  ) {
    createBOPForm(input: $input, condition: $condition) {
      id
      address
      purchasePrice
      sellers
      purchaser
      commission
      data
      createdAt
      updatedAt
    }
  }
`
export const updateBOPForm = /* GraphQL */ `
  mutation UpdateBOPForm(
    $input: UpdateBOPFormInput!
    $condition: ModelBOPFormConditionInput
  ) {
    updateBOPForm(input: $input, condition: $condition) {
      id
      address
      purchasePrice
      sellers
      purchaser
      commission
      data
      createdAt
      updatedAt
    }
  }
`
export const deleteBOPForm = /* GraphQL */ `
  mutation DeleteBOPForm(
    $input: DeleteBOPFormInput!
    $condition: ModelBOPFormConditionInput
  ) {
    deleteBOPForm(input: $input, condition: $condition) {
      id
      address
      purchasePrice
      sellers
      purchaser
      commission
      data
      createdAt
      updatedAt
    }
  }
`
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      email
      title
      phoneNumber
      officeNumber
      faxNumber
      image
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      email
      title
      phoneNumber
      officeNumber
      faxNumber
      image
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      email
      title
      phoneNumber
      officeNumber
      faxNumber
      image
      createdAt
      updatedAt
      owner
    }
  }
`
export const createPromotion = /* GraphQL */ `
  mutation CreatePromotion(
    $input: CreatePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    createPromotion(input: $input, condition: $condition) {
      id
      name
      link
      image
      createdOn
      status
      createdAt
      updatedAt
      owner
    }
  }
`
export const updatePromotion = /* GraphQL */ `
  mutation UpdatePromotion(
    $input: UpdatePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    updatePromotion(input: $input, condition: $condition) {
      id
      name
      link
      image
      createdOn
      status
      createdAt
      updatedAt
      owner
    }
  }
`
export const deletePromotion = /* GraphQL */ `
  mutation DeletePromotion(
    $input: DeletePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    deletePromotion(input: $input, condition: $condition) {
      id
      name
      link
      image
      createdOn
      status
      createdAt
      updatedAt
      owner
    }
  }
`
export const createNewsletter = /* GraphQL */ `
  mutation CreateNewsletter(
    $input: CreateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    createNewsletter(input: $input, condition: $condition) {
      id
      email
      groups {
        items {
          id
          newsletterID
          newsletterGroupID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateNewsletter = /* GraphQL */ `
  mutation UpdateNewsletter(
    $input: UpdateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    updateNewsletter(input: $input, condition: $condition) {
      id
      email
      groups {
        items {
          id
          newsletterID
          newsletterGroupID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteNewsletter = /* GraphQL */ `
  mutation DeleteNewsletter(
    $input: DeleteNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    deleteNewsletter(input: $input, condition: $condition) {
      id
      email
      groups {
        items {
          id
          newsletterID
          newsletterGroupID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const createNewsletterGroup = /* GraphQL */ `
  mutation CreateNewsletterGroup(
    $input: CreateNewsletterGroupInput!
    $condition: ModelNewsletterGroupConditionInput
  ) {
    createNewsletterGroup(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          newsletterID
          newsletterGroupID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateNewsletterGroup = /* GraphQL */ `
  mutation UpdateNewsletterGroup(
    $input: UpdateNewsletterGroupInput!
    $condition: ModelNewsletterGroupConditionInput
  ) {
    updateNewsletterGroup(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          newsletterID
          newsletterGroupID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteNewsletterGroup = /* GraphQL */ `
  mutation DeleteNewsletterGroup(
    $input: DeleteNewsletterGroupInput!
    $condition: ModelNewsletterGroupConditionInput
  ) {
    deleteNewsletterGroup(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          newsletterID
          newsletterGroupID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const createOffer = /* GraphQL */ `
  mutation CreateOffer(
    $input: CreateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    createOffer(input: $input, condition: $condition) {
      id
      userID
      offerPrice
      user {
        userID
        firstName
        lastName
        profileImageURL {
          key
          url
        }
        email
        phoneNumber
        userStatus
        createdAt
        updatedAt
      }
      propertyID
      property {
        id
        title
        description
        askingPrice
        details {
          propertyStatus
          lotSize
          bedRooms
          bathRooms
          swimmingPool
          sunRoom
          garage
          privateDriveway
          squareFootage
          yearBuilt
        }
        featuredImage
        images
        video
        address {
          streetAddress
          city
          state
          zipCode
        }
        propertyState
        addedOn
        propertyType
        propertySource {
          sourceName
          sourcePhoneNumber
          sourceEmail
          propertyStatus
          purchasePrice
          askingPrice
          soldPrice
        }
        zoning
        status
        brokerID
        broker {
          id
          name
          email
          phoneNumber
          title
          brokerageName
          licenseNumber
          image
          createdAt
          updatedAt
          owner
        }
        streetAddress
        city
        state
        zipCode
        propertyStatus
        lotSize
        beds
        baths
        garage
        privateDriveway
        squareFootage
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateOffer = /* GraphQL */ `
  mutation UpdateOffer(
    $input: UpdateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    updateOffer(input: $input, condition: $condition) {
      id
      userID
      offerPrice
      user {
        userID
        firstName
        lastName
        profileImageURL {
          key
          url
        }
        email
        phoneNumber
        userStatus
        createdAt
        updatedAt
      }
      propertyID
      property {
        id
        title
        description
        askingPrice
        details {
          propertyStatus
          lotSize
          bedRooms
          bathRooms
          swimmingPool
          sunRoom
          garage
          privateDriveway
          squareFootage
          yearBuilt
        }
        featuredImage
        images
        video
        address {
          streetAddress
          city
          state
          zipCode
        }
        propertyState
        addedOn
        propertyType
        propertySource {
          sourceName
          sourcePhoneNumber
          sourceEmail
          propertyStatus
          purchasePrice
          askingPrice
          soldPrice
        }
        zoning
        status
        brokerID
        broker {
          id
          name
          email
          phoneNumber
          title
          brokerageName
          licenseNumber
          image
          createdAt
          updatedAt
          owner
        }
        streetAddress
        city
        state
        zipCode
        propertyStatus
        lotSize
        beds
        baths
        garage
        privateDriveway
        squareFootage
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteOffer = /* GraphQL */ `
  mutation DeleteOffer(
    $input: DeleteOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    deleteOffer(input: $input, condition: $condition) {
      id
      userID
      offerPrice
      user {
        userID
        firstName
        lastName
        profileImageURL {
          key
          url
        }
        email
        phoneNumber
        userStatus
        createdAt
        updatedAt
      }
      propertyID
      property {
        id
        title
        description
        askingPrice
        details {
          propertyStatus
          lotSize
          bedRooms
          bathRooms
          swimmingPool
          sunRoom
          garage
          privateDriveway
          squareFootage
          yearBuilt
        }
        featuredImage
        images
        video
        address {
          streetAddress
          city
          state
          zipCode
        }
        propertyState
        addedOn
        propertyType
        propertySource {
          sourceName
          sourcePhoneNumber
          sourceEmail
          propertyStatus
          purchasePrice
          askingPrice
          soldPrice
        }
        zoning
        status
        brokerID
        broker {
          id
          name
          email
          phoneNumber
          title
          brokerageName
          licenseNumber
          image
          createdAt
          updatedAt
          owner
        }
        streetAddress
        city
        state
        zipCode
        propertyStatus
        lotSize
        beds
        baths
        garage
        privateDriveway
        squareFootage
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      updatedAt
      owner
    }
  }
`
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      newsletter
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      newsletter
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      newsletter
      createdAt
      updatedAt
      owner
    }
  }
`
export const createNewsletterLogModal = /* GraphQL */ `
  mutation CreateNewsletterLogModal(
    $input: CreateNewsletterLogModalInput!
    $condition: ModelNewsletterLogModalConditionInput
  ) {
    createNewsletterLogModal(input: $input, condition: $condition) {
      id
      property
      group
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateNewsletterLogModal = /* GraphQL */ `
  mutation UpdateNewsletterLogModal(
    $input: UpdateNewsletterLogModalInput!
    $condition: ModelNewsletterLogModalConditionInput
  ) {
    updateNewsletterLogModal(input: $input, condition: $condition) {
      id
      property
      group
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteNewsletterLogModal = /* GraphQL */ `
  mutation DeleteNewsletterLogModal(
    $input: DeleteNewsletterLogModalInput!
    $condition: ModelNewsletterLogModalConditionInput
  ) {
    deleteNewsletterLogModal(input: $input, condition: $condition) {
      id
      property
      group
      createdAt
      updatedAt
      owner
    }
  }
`
export const createGroupedNewsletterUsers = /* GraphQL */ `
  mutation CreateGroupedNewsletterUsers(
    $input: CreateGroupedNewsletterUsersInput!
    $condition: ModelGroupedNewsletterUsersConditionInput
  ) {
    createGroupedNewsletterUsers(input: $input, condition: $condition) {
      id
      newsletterID
      newsletterGroupID
      newsletter {
        id
        email
        groups {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      newsletterGroup {
        id
        name
        users {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateGroupedNewsletterUsers = /* GraphQL */ `
  mutation UpdateGroupedNewsletterUsers(
    $input: UpdateGroupedNewsletterUsersInput!
    $condition: ModelGroupedNewsletterUsersConditionInput
  ) {
    updateGroupedNewsletterUsers(input: $input, condition: $condition) {
      id
      newsletterID
      newsletterGroupID
      newsletter {
        id
        email
        groups {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      newsletterGroup {
        id
        name
        users {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const deleteGroupedNewsletterUsers = /* GraphQL */ `
  mutation DeleteGroupedNewsletterUsers(
    $input: DeleteGroupedNewsletterUsersInput!
    $condition: ModelGroupedNewsletterUsersConditionInput
  ) {
    deleteGroupedNewsletterUsers(input: $input, condition: $condition) {
      id
      newsletterID
      newsletterGroupID
      newsletter {
        id
        email
        groups {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      newsletterGroup {
        id
        name
        users {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const createLikedProperty = /* GraphQL */ `
  mutation CreateLikedProperty(
    $input: CreateLikedPropertyInput!
    $condition: ModelLikedPropertyConditionInput
  ) {
    createLikedProperty(input: $input, condition: $condition) {
      id
      userID
      propertyID
      createdAt
      updatedAt
      owner
    }
  }
`
export const updateLikedProperty = /* GraphQL */ `
  mutation UpdateLikedProperty(
    $input: UpdateLikedPropertyInput!
    $condition: ModelLikedPropertyConditionInput
  ) {
    updateLikedProperty(input: $input, condition: $condition) {
      id
      userID
      propertyID
      createdAt
      updatedAt
      owner
    }
  }
`
