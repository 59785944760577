// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAGtHHm6CuZC2Loy0YzLTxU0QBOwjayxzE",
  authDomain: "bop-staging.firebaseapp.com",
  projectId: "bop-staging",
  storageBucket: "bop-staging.appspot.com",
  messagingSenderId: "316400194048",
  appId: "1:316400194048:web:749e0f906d65d6c0007ee5"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);