import { Error } from "@mui/icons-material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Button, Dialog, Paper, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box, Theme } from "@mui/system"
import { useNavigate } from "react-router-dom"

type PropertyCreatedDialogProps = {
  state: boolean
  onClose(): void
  error: boolean
  onRetry(): void
}

const PropertyCreatedDialog: React.FC<PropertyCreatedDialogProps> = ({
  state,
  onClose,
  error,
  onRetry,
}: PropertyCreatedDialogProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const handleClose = () => {
    onClose()
    navigate("/properties")
  }
  const handleAddMore = () => {
    onClose()
    navigate("/properties/add-property")
  }

  return (
    <Dialog open={state} maxWidth="md">
      <Box component={Paper} className={classes.root}>
        {error ? (
          <>
            <Box>
              <Error color="error" className={classes.icon} />
            </Box>
            <Typography variant="h6">Something went wrong</Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={onRetry}
              fullWidth
            >
              Try Again
            </Button>
          </>
        ) : (
          <>
            <Box>
              <CheckCircleIcon color="success" className={classes.icon} />
            </Box>
            <Typography variant="h6">New Property Created</Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleAddMore}
            >
              Add More
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              fullWidth
            >
              Close
            </Button>
          </>
        )}
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 250,
    padding: theme.spacing(2),
    width: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icon: {
    width: `80px !important`,
    height: `80px !important`,
  },
}))

export default PropertyCreatedDialog
