import { App_Routes } from "./routes";
import {
  Apartment,
  ContactPhone,
  CoPresent,
  Dashboard,
  EmailSharp,
  FeaturedVideo,
  Group,
  LocalOffer,
  Mail,
  QuestionAnswer,
  Settings,
  VerifiedUser,
} from "@mui/icons-material";

export const SIDE_BAR_APP_ROUTES = [
  {
    label: "Dashboard",
    link: App_Routes.DASHBOARD,
    icon: <Dashboard />,
  },
  {
    label: "Properties",
    link: App_Routes.PROPERTIES_PAGE,
    icon: <Apartment />,
  },
  {
    label: "Promotions",
    link: App_Routes.PROMOTIONS_VIEW,
    icon: <FeaturedVideo />,
  },
  {
    label: "Newsletter",
    link: App_Routes.NEWSLETTER_VIEW,
    icon: <Mail />,
  },
  {
    label: "Newsletter Groups",
    link: App_Routes.NEWSLETTER_GROUPS_VIEW,
    icon: <Group />,
  },
  {
    label: "Mails",
    link: App_Routes.MAIL_VIEW,
    icon: <ContactPhone />,
  },
  {
    label: "Email Templates",
    link: App_Routes.EMAIL_TEMPLATE,
    icon: <EmailSharp />,
  },
  {
    label: "Offers",
    link: App_Routes.OFFERS_VIEW,
    icon: <LocalOffer />,
  },
  {
    label: "Lending Inquiries",
    link: App_Routes.LENDING_INQUIRY,
    icon: <QuestionAnswer />,
  },
  {
    label: "BOP Team",
    link: App_Routes.TEAM_PAGE,
    icon: <VerifiedUser />,
  },
  {
    label: "Brokers",
    link: App_Routes.BROKERS_PAGE,
    icon: <CoPresent />,
  },
  {
    label: "Users",
    link: App_Routes.USERS_PAGE,
    icon: <Group />,
  },
  {
    label: "Settings",
    link: App_Routes.SETTINGS,
    icon: <Settings />,
  },
];
