// @ts-nocheck
import { Property } from "api-client/models/Property"
import _ from "lodash"
import { CSSProperties } from "react"
import { Row } from "react-table"

interface Styles {
  [key: string]: CSSProperties
}

export const SinglePropertyTemplate = ({
  properties,
}: {
  properties?: Array<Row<Property>>
}) => {
  const styles: Styles = {
    root: {
      width: "100%",
      backgroundColor: "#000",
      display: "flex",
      justifyContent: "center",
      minHeight: "100vh",
    },
    container: {
      width: "100%",
      backgroundColor: "#fff",
    },
    header: {
      height: "80px",
      boxShadow: "0px 5px 5px 0px #00000033",
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },
    logo: {
      height: "60px",
    },
    hero: {
      background:
        "url('https://www.bankownedpropertyusa.com/assets/heroImage.jpg')",
      minHeight: "300px",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    heroContainer: {
      backgroundColor: "#00000033",
      height: "300px",
      margin: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    heroText: {
      margin: 0,
      padding: "10px",
      color: "#fff",
      textAlign: "center",
    },
    propertiesSection: {
      padding: "20px 10px",
    },
    propertyCard: {
      padding: "20px 10px",
    },
  }
  return (
    <html>
      <head></head>
      <body>
        <div style={styles.root}>
          <div style={styles.container}>
            <header style={styles.header}>
              <img
                src='https://www.bankownedpropertyusa.com/bankowned-logo.png'
                alt='Bank Owned Properties Logo'
                style={styles.logo}
              />
            </header>
            <section style={styles.hero}>
              <div style={styles.heroContainer}>
                <h1 style={styles.heroText}>
                  Unlocking Best <br /> American Bank-Owned <br />
                  Properties For You
                </h1>
              </div>
            </section>
            <section style={styles.propertiesSection}>
              {properties && properties.length !== 0 ? (
                properties?.map((property) => (
                  <div style={styles.propertyCard} key={property.id}>
                    <div>
                      <img
                        src={property.original.featuredImage}
                        alt='featuredImage'
                      />
                    </div>
                    <div>{property.original.title}</div>
                  </div>
                ))
              ) : (
                <>no properties</>
              )}
            </section>
          </div>
        </div>
      </body>
    </html>
  )
}

export const getPropertiesTemplateContent = (
  properties: Array<Row<Property>>
) => {
  const propertiesHTML = getPropertiesHTML(properties)
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html lang="en">
    <div id="__react-email-preview" style="display:none;overflow:hidden;line-height:1px;opacity:0;max-height:0;max-width:0">
      <p style="font-size:14px;line-height:24px;margin:16px 0">New properties just added to our list! Take a look at the stunning homes now available with unbeatable prices. Hurry, these properties won&#x27;t last long. Check out the full list now.</p>
      <div></div>
    </div>
    <table style="width:100%;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif;background-color:#ffffff;margin:0 auto" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation">
      <tbody>
        <tr>
          <td>
            <table style="width:100%;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif;border:1px solid #eaeaea;border-radius:5px;margin:40px auto;padding:20px;max-width:520px;display:flex;flex-direction:column;align-items:center" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation">
              <tbody>
                <tr>
                  <td>
                  <td>
                    <table style="width:100%;margin:32px 0px 0" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                      <tbody>
                        <tr>
                          <td>
                          <td>
                            <div><!--[if mso | IE]>
              <table role="presentation" width="100%" align="center" style="max-width:37.5em;"><tr><td></td><td style="width:37.5em;background:#ffffff">
            <![endif]--></div>
                            <div style="max-width:37.5em"><img alt="bank owned property usa" src="https://www.bankownedpropertyusa.com/bankowned-logo.png" width="200" height="50" style="display:block;outline:none;border:none;text-decoration:none;margin:0 auto" /></div>
                            <div><!--[if mso | IE]>
            </td><td></td></tr></table>
            <![endif]--></div>
                            <div><!--[if mso | IE]>
              <table role="presentation" width="100%" align="center" style="max-width:37.5em;text-align:center;"><tr><td></td><td style="width:37.5em;background:#ffffff">
            <![endif]--></div>
                            <div style="max-width:37.5em;text-align:center">
                              <p style="font-size:24px;line-height:24px;margin:16px 0;font-weight:bold">New properties just added to our list!</p>
                            </div>
                            <div><!--[if mso | IE]>
            </td><td></td></tr></table>
            <![endif]--></div>
                          </td>
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="width:100%" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation">
              <tbody>
                <tr>
                  <td>
                  <td>
     <!----Properties come here-->
     ${propertiesHTML.join("")}
    </td>
    </td>
    </tr>
    </tbody>
    </table>
    <table style="width:100%" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation">
      <tbody>
        <tr>
          <td>
          <td>
            <div><!--[if mso | IE]>
              <table role="presentation" width="100%" align="center" style="max-width:37.5em;padding:8px 0px;display:block;"><tr><td></td><td style="width:37.5em;background:#ffffff">
            <![endif]--></div>
            <div style="max-width:37.5em;padding:8px 0px;display:block"><a target="_blank" style="color:#fff;text-decoration:none;background:#000;padding:8px 16px;border-radius:8px;width:200px;margin:0 auto;display:block;text-align:center" href="https://www.bankownedpropertyusa.com/properties">Explore More Properties</a></div>
            <div><!--[if mso | IE]>
            </td><td></td></tr></table>
            <![endif]--></div>
          </td>
          </td>
        </tr>
      </tbody>
    </table>
    <table style="width:100%;text-align:center;font-size:12px;color:#000;margin-top:30px;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation">
      <tbody>
        <tr>
          <td>Bank Owned Property USA <br /><a target="_blank" style="color:#484848;text-decoration:none;font-size:12px;margin-top:30px;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif" href="mailto:info@bankownedpropertyusa.com">info@bankownedpropertyusa.com<br /></a><a target="_blank" style="color:#484848;text-decoration:none;font-size:12px;margin-top:30px;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif" href="tel:+15163081617">+1 516-308-1617<br /></a>© ‌‌2023</td>
        </tr>
      </tbody>
    </table></td>
    </td>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>
  
  </html>`
}

function divideArrayInPairsAndMap(arr, mapFn) {
  return arr
    .reduce((acc, item, index) => {
      if (index % 2 === 0) {
        acc.push([item])
      } else {
        acc[acc.length - 1].push(item)
      }
      return acc
    }, [])
    .map((pair) => mapFn(pair))
}

const getPropertiesHTML = (properties) => {
  return _.map(properties, (property) => (
    `<table
    style="width:100%"
      align='center'
      border='0'
      cellPadding='0'
      cellSpacing='0'
      role='presentation'
      name='property-table'>
      <tbody>
        <tr>
          <td>${propertyHTML(property.original)}</td>
        </tr>
      </tbody>
    </table>`
  ))
}

const propertyHTML = (property: Property) => {
  return `


      <td name="property" style="padding:6px"><a target="_blank" style="color:#000;text-decoration:none;padding:0;width:100%;display:block" href="https://www.bankownedpropertyusa.com/properties/property/${property.id}">
          <table style="width:100%;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif;border:1px solid #d1d1d5;margin:20px 0;border-radius:5px" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation" name="property">
            <tbody>
              <tr>
                <td>
                <td>
                  <div><!--[if mso | IE]>
<table role="presentation" width="100%" align="center" style="max-width:37.5em;"><tr><td></td><td style="width:37.5em;background:#ffffff">
<![endif]--></div>
                  <div style="max-width:37.5em"><img alt="${property.title}" src="${property.images[0]}" width="100%" height="auto" style="display:block;outline:none;border:none;text-decoration:none;margin:0 auto" /></div>
                  <div><!--[if mso | IE]>
</td><td></td></tr></table>
<![endif]--></div>
                  <div><!--[if mso | IE]>
<table role="presentation" width="100%" align="center" style="max-width:37.5em;padding:8px;"><tr><td></td><td style="width:37.5em;background:#ffffff">
<![endif]--></div>
                  <div style="max-width:37.5em;padding:8px">
                    <table style="width:100%;padding:12px 0;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                      <tbody>
                        <tr>
                          <td>
                          <td style="font-size:14px;font-weight:700;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif">${property.bedRooms} Beds</td>
                          <td style="font-size:14px;font-weight:700;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif">${property.bathRooms} Baths</td>
                          <td style="font-size:14px;font-weight:700;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif">${property.squareFootage} sqft</td>
                </td>
              </tr>
            </tbody>
          </table>
          <div><!--[if mso | IE]>
<table role="presentation" width="100%" align="center" style="max-width:37.5em;"><tr><td></td><td style="width:37.5em;background:#ffffff">
<![endif]--></div>
          <div style="max-width:37.5em">
            <p style="font-size:14px;line-height:0;margin:16px 0;font-weight:600">${property.streetAddress}, ${property.city}, ${property.zipCode}</p>
          </div>
          <div><!--[if mso | IE]>
</td><td></td></tr></table>
<![endif]--></div>
          <table style="width:100%;border-top:1px solid #ccc" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation">
            <tbody>
              <tr>
                <td>
                <td>
                  <div><!--[if mso | IE]>
<table role="presentation" width="100%" align="center" style="max-width:37.5em;padding:10px 0;width:100%;"><tr><td></td><td style="width:37.5em;background:#ffffff">
<![endif]--></div>
                  <div style="max-width:37.5em;padding:10px 0;width:100%">
                    <p style="font-size:18px;line-height:0;margin:16px 0;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif;font-weight:600">${numberWithCommas(property.askingPrice)}</p>
                  </div>
                  <div><!--[if mso | IE]>
</td><td></td></tr></table>
<![endif]--></div>
                </td>
                <td>
                  <div><!--[if mso | IE]>
<table role="presentation" width="100%" align="center" style="max-width:37.5em;padding:10px 0;width:100%;"><tr><td></td><td style="width:37.5em;background:#ffffff">
<![endif]--></div>
                  <div style="max-width:37.5em;padding:10px 0;width:100%"><a target="_blank" style="color:#fff;text-decoration:none;background:#000;padding:8px 16px;border-radius:8px;float:right" href="https://www.bankownedpropertyusa.com/properties/property/${property.id}">View</a></div>
                  <div><!--[if mso | IE]>
</td><td></td></tr></table>
<![endif]--></div>
                </td>
      </td>
    </tr>
  </tbody>
</table>
</div>
<div><!--[if mso | IE]>
</td><td></td></tr></table>
<![endif]--></div>
</td>
</td>
</tr>
</tbody>
</table></a>
<td></td>
</td>

  
  `
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
