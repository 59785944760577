/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowDownward, ArrowUpward, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TableContainer,
  Typography,
} from "@mui/material";
import { IPagination } from "api-client/utils";
import clsx from "clsx";
import * as React from "react";
import {
  CellProps,
  Column,
  HeaderProps,
  Hooks,
  Row,
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { ResizeHandle } from "./Resizehandle";
import {
  HeaderCheckbox,
  RowCheckbox,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  NonMemoizedTableRow,
  TableTable,
} from "./TableAtoms";
import TablePagination from "./TablePagination/TablePagination";
import useStyles from "./TableStyles";

type TableProps = {
  name: string;
  pagination?: boolean;
  data: Array<any>;
  loading: boolean;
  columns: Column<any>[];
  useNonMemoizedRow?: boolean;
  classes?: {
    root?: string;
    head?: string;
    cell?: string;
    row?: string;
  };
  action?: {
    label: string;
    onClick(
      event: React.MouseEvent<HTMLButtonElement>,
      selectedFlatRows: Array<Row<any>>
    ): void;
    icon: React.ReactNode;
  };
  onSelectedDelete?(
    event: React.MouseEvent<HTMLButtonElement>,
    selectedFlatRows: Array<Row<any>>
  ): void;
  onSelectedEdit?(
    event: React.MouseEvent<HTMLButtonElement>,
    selectedFlatRows: Array<Row<any>>
  ): void;
  onSelect?(selectedFlatRows?: Array<Row<any>>): void;
  onLoadMore?(pagination: IPagination): void;
  onSendEmail?(selectedFlatRows: Array<Row<any>>): void;
};

const Table: React.FC<TableProps> = (props: TableProps) => {
  const cssStyles = useStyles();

  const {
    columns,
    data,
    classes,
    pagination,
    loading,
    onSelectedDelete,
    onSelectedEdit,
    onLoadMore,
    action,
    onSelect,
    onSendEmail,
    useNonMemoizedRow,
  } = props;

  const selectionHook = (hooks: Hooks<any>) => {
    hooks.visibleColumns.push((columns) => [
      // Column for selection
      {
        id: "_selector",
        disableResizing: true,
        disableGroupBy: true,
        sticky: "left",
        minWidth: 45,
        width: 45,
        maxWidth: 45,
        Aggregated: undefined,
        Header: ({ getToggleAllPageRowsSelectedProps }: HeaderProps<any>) => (
          <HeaderCheckbox {...(getToggleAllPageRowsSelectedProps() as any)} />
        ),
        Cell: ({ row }: CellProps<any>) => (
          <RowCheckbox {...(row.getToggleRowSelectedProps() as any)} />
        ),
      },
      ...columns,
    ]);
  };

  const defaultColumn = {
    minWidth: 60, // minWidth is only used as a limit for resizing
    width: 150, // width is used for both the flex-basis and flex-grow
    maxWidth: 350, // maxWidth is only used as a limit for resizing
  };

  const hooks = [
    useColumnOrder,
    useFilters,
    useSortBy,
    useExpanded,
    useFlexLayout,
    usePagination,
    useResizeColumns,
    useRowSelect,
    selectionHook,
    useSticky,
  ];
  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !onLoadMore,
    },
    ...hooks
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    selectedFlatRows,
    state: { rowCount = instance.rows.length },
  } = instance;

  const handleOnSelected = React.useCallback(() => {
    if (selectedFlatRows.length === 0) return;
    if (onSelect === undefined) return;
    onSelect(selectedFlatRows);
  }, [selectedFlatRows]);

  React.useEffect(() => {
    handleOnSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOnSelected]);

  return (
    <Grid container direction="column" className={cssStyles.root}>
      <Grid
        item
        container
        direction="row"
        alignItems={"flex-end"}
        justifyContent={"flex-end"}
        sx={{
          position: "relative",
          minHeight: 20,
        }}
      >
        <Grid
          item
          sx={{
            position: "absolute",
            bottom: "-16px",
            left: 0,
          }}
        >
          <Typography align="left">
            {instance.selectedFlatRows.length > 0 ? (
              <>
                <b>{instance.selectedFlatRows.length}</b> Items Selected
              </>
            ) : (
              <></>
            )}
          </Typography>
        </Grid>
        <Grid item>
          {action && (
            <Button
              disabled={Boolean(selectedFlatRows.length === 0)}
              variant="contained"
              color="primary"
              onClick={(e) => action.onClick(e, selectedFlatRows)}
              sx={{
                marginRight: "16px",
              }}
              startIcon={action.icon}
            >
              {action.label}
            </Button>
          )}
          {onSelectedDelete && (
            <Button
              disabled={Boolean(selectedFlatRows.length === 0)}
              variant="contained"
              color="error"
              onClick={(e) => onSelectedDelete(e, selectedFlatRows)}
              sx={{
                marginRight: "16px",
              }}
              startIcon={<Delete />}
            >
              Delete
            </Button>
          )}
          {onSelectedEdit && (
            <Button
              disabled={Boolean(selectedFlatRows.length === 0)}
              variant="contained"
              color="secondary"
              onClick={(e) => onSelectedEdit(e, selectedFlatRows)}
              startIcon={<Edit />}
            >
              Update Status
            </Button>
          )}
          {onSendEmail && (
            <Button
              variant="contained"
              disabled={Boolean(selectedFlatRows.length === 0)}
              onClick={() => onSendEmail(selectedFlatRows)}
              sx={{
                padding: "8px",
                margin: "8px",
              }}
            >
              Send Email
            </Button>
          )}
        </Grid>
      </Grid>
      <TableContainer className={cssStyles.tableContainer}>
        <TableTable
          className={clsx(classes?.root, cssStyles.tableRoot)}
          {...getTableProps()}
        >
          <TableHead className={clsx(classes?.head, cssStyles.tableHead)}>
            {headerGroups.map((headerGroup, index) => (
              <TableHeadRow
                {...headerGroup.getHeaderGroupProps()}
                className={classes?.head}
                key={index}
              >
                {headerGroup.headers.map((column, index) => {
                  const { ...columnSortByProps } =
                    column.getSortByToggleProps();
                  return (
                    <TableHeadCell
                      {...(column.getHeaderProps() as any)}
                      key={index}
                    >
                      {column.render("Header")}
                      {column.canSort && (
                        <IconButton
                          {...(columnSortByProps as any)}
                          className={clsx(cssStyles.sortButton, {
                            [cssStyles.isActive]: column.isSorted,
                          })}
                        >
                          {column.isSortedDesc ? (
                            <ArrowUpward color="secondary" />
                          ) : (
                            <ArrowDownward color="secondary" />
                          )}
                        </IconButton>
                      )}
                      {column.canResize && <ResizeHandle column={column} />}
                    </TableHeadCell>
                  );
                })}
              </TableHeadRow>
            ))}
          </TableHead>
          {!loading && (
            <TableBody {...(getTableBodyProps() as any)}>
              {(pagination ? page : rows).map((row) => {
                prepareRow(row);
                const {
                  key: rowKey,
                  role: rowRole,
                  ...getRowProps
                } = row.getRowProps();
                return useNonMemoizedRow ? (
                  <NonMemoizedTableRow
                    key={rowKey}
                    {...getRowProps}
                    className={clsx({ rowSelected: row.isSelected })}
                  >
                    {row.cells.map((cell, index) => (
                      <TableCell {...(cell.getCellProps() as any)} key={index}>
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </NonMemoizedTableRow>
                ) : (
                  <TableRow
                    key={rowKey}
                    {...getRowProps}
                    className={clsx({ rowSelected: row.isSelected })}
                  >
                    {row.cells.map((cell, index) => (
                      <TableCell {...(cell.getCellProps() as any)} key={index}>
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </TableTable>
        {loading && (
          <Box className={cssStyles.isloading}>
            <CircularProgress />
          </Box>
        )}
        {data.length === 0 && !loading && (
          <Box className={cssStyles.isloading}>
            <Typography>No Result Found</Typography>
          </Box>
        )}
        {pagination && (
          <TablePagination onLoadMore={onLoadMore} tableInstance={instance} />
        )}
      </TableContainer>
    </Grid>
  );
};

export default Table;
