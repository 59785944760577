export const App_Routes = {
  ADD_PROPERTY: "/properties/add-property",
  SET_NEW_PASSWORD: "/set-password",
  DASHBOARD: "/",
  PROPERTIES_PAGE: "/properties",
  USERS_PAGE: "/users",
  BROKERS_PAGE: "/brokers-view",
  MY_PROFILE: "/my-profile",
  LOGIN: "/login",
  EDIT_PROPERTY: "/edit-property/:id",
  VIEW_PROPERTY: "/property/:id",
  ADMIN_PAGE: "/admin-view",
  FORMS_PAGE: "/forms-view",
  TEAM_PAGE: "/bop-team",
  PROMOTIONS_VIEW: "/promotions",
  NEWSLETTER_VIEW: "/newsletter-view",
  NEWSLETTER_GROUPS_VIEW: "/newsletter-groups-view",
  NEWSLETTER_GROUP_EMAILS: "newsletter/group/:groupId",
  SETTINGS: "/settings-view",
  EMAIL_TEMPLATE_VIEW: "/email-template",
  MAIL_VIEW: "/mail-view",
  OFFERS: "/offers",
  LENDING_INQUIRY: "/lending-inquiry"
}
