import { db } from "api-client/config/firebase";
import { FirebaseCollection } from "api-client/enums";
import { TeamMember } from "api-client/models/Team";
import { FirebaseResponse, ITeamMember } from "api-client/types";
import { addDoc, collection, deleteDoc, doc, getDocs, setDoc } from "firebase/firestore";

export class FirebaseTeam {
  addTeamMember = async (
    member: ITeamMember
  ): Promise<FirebaseResponse<ITeamMember | string>> => {
    try {
      const collectionRef = collection(db, FirebaseCollection.TeamMembers);
      const doc = await addDoc(collectionRef, member);
      const data = new TeamMember({ id: doc.id, ...member });
      return {
        type: "data",
        data: data,
      };
    } catch (e) {
      return {
        type: "error",
        error: "Something went wrong",
      };
    }
  };

  fetchTeamMembers = async (): Promise<FirebaseResponse<ITeamMember[]>> => {
    try {
      const members: Array<ITeamMember> = [];
      const collectionRef = collection(db, FirebaseCollection.TeamMembers);
      const snapshot = await getDocs(collectionRef);
      snapshot.docs.forEach((doc) => {
        members.push(
          new TeamMember({ ...doc.data(), id: doc.id } as ITeamMember)
        );
      });
      return {
        type: "data",
        data: members,
      };
    } catch (e) {
      return {
        type: "error",
        error: [],
      };
    }
  };

  updateTeamMember = async (
    member: ITeamMember
  ): Promise<FirebaseResponse<ITeamMember>> => {
    try {
      const collectionRef = collection(db, FirebaseCollection.TeamMembers);
      const docRef = doc(collectionRef, member.id);
      await setDoc(docRef, member);
      return {
        type: "data",
        data: member,
      };
    } catch (e) {
      return {
        type: "error",
        error: member,
      };
    }
  };

  deleteTeamMember = async (
    memberId: string
  ): Promise<FirebaseResponse<string>> => {
    try {
      const collectionRef = collection(db, FirebaseCollection.TeamMembers);
      const docRef = doc(collectionRef, memberId);
      await deleteDoc(docRef);
      return {
        type: "data",
        data: "Member deleted successfully",
      };
    } catch (e) {
      return {
        type: "error",
        error: "Error deleting member",
      };
    }
  };
}
