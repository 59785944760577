/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserModel = /* GraphQL */ `
  query GetUserModel($userID: ID!) {
    getUserModel(userID: $userID) {
      userID
      firstName
      lastName
      profileImageURL {
        key
        url
      }
      email
      phoneNumber
      userStatus
      createdAt
      updatedAt
    }
  }
`
export const listUserModels = /* GraphQL */ `
  query ListUserModels(
    $userID: ID
    $filter: ModelUserModelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserModels(
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        firstName
        lastName
        profileImageURL {
          key
          url
        }
        email
        phoneNumber
        userStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getFormsModel = /* GraphQL */ `
  query GetFormsModel($id: ID!) {
    getFormsModel(id: $id) {
      id
      formTitle
      formDescription
      formFile {
        key
        url
      }
      createdAt
      updatedAt
    }
  }
`
export const listFormsModels = /* GraphQL */ `
  query ListFormsModels(
    $id: ID
    $filter: ModelFormsModelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFormsModels(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        formTitle
        formDescription
        formFile {
          key
          url
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getBOPForm = /* GraphQL */ `
  query GetBOPForm($id: ID!) {
    getBOPForm(id: $id) {
      id
      address
      purchasePrice
      sellers
      purchaser
      commission
      data
      createdAt
      updatedAt
    }
  }
`
export const listBOPForms = /* GraphQL */ `
  query ListBOPForms(
    $filter: ModelBOPFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBOPForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address
        purchasePrice
        sellers
        purchaser
        commission
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getOffer = /* GraphQL */ `
  query GetOffer($id: ID!) {
    getOffer(id: $id) {
      id
      userID
      offerPrice
      user {
        userID
        firstName
        lastName
        profileImageURL {
          key
          url
        }
        email
        phoneNumber
        userStatus
        createdAt
        updatedAt
      }
      propertyID
      property {
        id
        title
        description
        askingPrice
        details {
          propertyStatus
          lotSize
          bedRooms
          bathRooms
          swimmingPool
          sunRoom
          garage
          privateDriveway
          squareFootage
          yearBuilt
        }
        featuredImage
        images
        video
        address {
          streetAddress
          city
          state
          zipCode
        }
        propertyState
        addedOn
        propertyType
        propertySource {
          sourceName
          sourcePhoneNumber
          sourceEmail
          propertyStatus
          purchasePrice
          askingPrice
          soldPrice
        }
        zoning
        status
        brokerID
        broker {
          id
          name
          email
          phoneNumber
          title
          brokerageName
          licenseNumber
          image
          createdAt
          updatedAt
          owner
        }
        streetAddress
        city
        state
        zipCode
        propertyStatus
        lotSize
        beds
        baths
        garage
        privateDriveway
        squareFootage
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      updatedAt
      owner
    }
  }
`
export const listOffers = /* GraphQL */ `
  query ListOffers(
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        offerPrice
        user {
          userID
          firstName
          lastName
          email
          phoneNumber
          userStatus
          createdAt
          updatedAt
        }
        propertyID
        property {
          id
          title
          description
          askingPrice
          featuredImage
          images
          video
          propertyState
          addedOn
          propertyType
          zoning
          status
          brokerID
          streetAddress
          city
          state
          zipCode
          propertyStatus
          lotSize
          beds
          baths
          garage
          privateDriveway
          squareFootage
          createdAt
          updatedAt
          owner
        }
        status
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const getBlogModel = /* GraphQL */ `
  query GetBlogModel($id: ID!) {
    getBlogModel(id: $id) {
      id
      title
      author
      dat
      createdBy
      createdAt
      updatedAt
    }
  }
`
export const listBlogModels = /* GraphQL */ `
  query ListBlogModels(
    $filter: ModelBlogModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        author
        dat
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getPropertyModel = /* GraphQL */ `
  query GetPropertyModel($id: ID!) {
    getPropertyModel(id: $id) {
      id
      title
      description
      askingPrice
      details {
        propertyStatus
        lotSize
        bedRooms
        bathRooms
        swimmingPool
        sunRoom
        garage
        privateDriveway
        squareFootage
        yearBuilt
      }
      featuredImage
      images
      video
      address {
        streetAddress
        city
        state
        zipCode
      }
      propertyState
      addedOn
      propertyType
      propertySource {
        sourceName
        sourcePhoneNumber
        sourceEmail
        propertyStatus
        purchasePrice
        askingPrice
        soldPrice
      }
      zoning
      status
      brokerID
      broker {
        id
        name
        email
        phoneNumber
        title
        brokerageName
        licenseNumber
        image
        createdAt
        updatedAt
        owner
      }
      streetAddress
      city
      state
      zipCode
      propertyStatus
      lotSize
      beds
      baths
      garage
      privateDriveway
      squareFootage
      createdAt
      updatedAt
      owner
    }
  }
`
export const listPropertyModels = /* GraphQL */ `
  query ListPropertyModels(
    $filter: ModelPropertyModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertyModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        askingPrice
        details {
          propertyStatus
          lotSize
          bedRooms
          bathRooms
          swimmingPool
          sunRoom
          garage
          privateDriveway
          squareFootage
          yearBuilt
        }
        featuredImage
        images
        video
        address {
          streetAddress
          city
          state
          zipCode
        }
        propertyState
        addedOn
        propertyType
        propertySource {
          sourceName
          sourcePhoneNumber
          sourceEmail
          propertyStatus
          purchasePrice
          askingPrice
          soldPrice
        }
        zoning
        status
        brokerID
        broker {
          id
          name
          email
          phoneNumber
          title
          brokerageName
          licenseNumber
          image
          createdAt
          updatedAt
          owner
        }
        streetAddress
        city
        state
        zipCode
        propertyStatus
        lotSize
        beds
        baths
        garage
        privateDriveway
        squareFootage
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const searchPropertyModels = /* GraphQL */ `
  query SearchPropertyModels(
    $filter: SearchablePropertyModelFilterInput
    $sort: [SearchablePropertyModelSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePropertyModelAggregationInput]
  ) {
    searchPropertyModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        description
        askingPrice
        details {
          propertyStatus
          lotSize
          bedRooms
          bathRooms
          swimmingPool
          sunRoom
          garage
          privateDriveway
          squareFootage
          yearBuilt
        }
        featuredImage
        images
        video
        address {
          streetAddress
          city
          state
          zipCode
        }
        propertyState
        addedOn
        propertyType
        propertySource {
          sourceName
          sourcePhoneNumber
          sourceEmail
          propertyStatus
          purchasePrice
          askingPrice
          soldPrice
        }
        zoning
        status
        brokerID
        broker {
          id
          name
          email
          phoneNumber
          title
          brokerageName
          licenseNumber
          image
          createdAt
          updatedAt
          owner
        }
        streetAddress
        city
        state
        zipCode
        propertyStatus
        lotSize
        beds
        baths
        garage
        privateDriveway
        squareFootage
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const getBrokerModel = /* GraphQL */ `
  query GetBrokerModel($id: ID!) {
    getBrokerModel(id: $id) {
      id
      name
      email
      phoneNumber
      title
      brokerageName
      licenseNumber
      image
      createdAt
      updatedAt
      owner
    }
  }
`
export const listBrokerModels = /* GraphQL */ `
  query ListBrokerModels(
    $filter: ModelBrokerModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrokerModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phoneNumber
        title
        brokerageName
        licenseNumber
        image
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const getLikedProperty = /* GraphQL */ `
  query GetLikedProperty($id: ID!) {
    getLikedProperty(id: $id) {
      id
      userID
      propertyID
      createdAt
      updatedAt
      owner
    }
  }
`
export const listLikedProperties = /* GraphQL */ `
  query ListLikedProperties(
    $filter: ModelLikedPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikedProperties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        propertyID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      email
      title
      phoneNumber
      officeNumber
      faxNumber
      image
      createdAt
      updatedAt
      owner
    }
  }
`
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        title
        phoneNumber
        officeNumber
        faxNumber
        image
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const getPromotion = /* GraphQL */ `
  query GetPromotion($id: ID!) {
    getPromotion(id: $id) {
      id
      name
      link
      image
      createdOn
      status
      createdAt
      updatedAt
      owner
    }
  }
`
export const listPromotions = /* GraphQL */ `
  query ListPromotions(
    $filter: ModelPromotionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromotions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        link
        image
        createdOn
        status
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const searchPromotions = /* GraphQL */ `
  query SearchPromotions(
    $filter: SearchablePromotionFilterInput
    $sort: [SearchablePromotionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePromotionAggregationInput]
  ) {
    searchPromotions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        link
        image
        createdOn
        status
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const getNewsletter = /* GraphQL */ `
  query GetNewsletter($id: ID!) {
    getNewsletter(id: $id) {
      id
      email
      groups {
        items {
          id
          newsletterID
          newsletterGroupID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const listNewsletters = /* GraphQL */ `
  query ListNewsletters(
    $filter: ModelNewsletterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        groups {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const getNewsletterGroup = /* GraphQL */ `
  query GetNewsletterGroup($id: ID!) {
    getNewsletterGroup(id: $id) {
      id
      name
      users {
        items {
          id
          newsletterID
          newsletterGroupID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const listNewsletterGroups = /* GraphQL */ `
  query ListNewsletterGroups(
    $filter: ModelNewsletterGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletterGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        users {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      newsletter
      createdAt
      updatedAt
      owner
    }
  }
`
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        newsletter
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const searchSettings = /* GraphQL */ `
  query SearchSettings(
    $filter: SearchablesettingsFilterInput
    $sort: [SearchablesettingsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablesettingsAggregationInput]
  ) {
    searchSettings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        newsletter
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const getNewsletterLogModal = /* GraphQL */ `
  query GetNewsletterLogModal($id: ID!) {
    getNewsletterLogModal(id: $id) {
      id
      property
      group
      createdAt
      updatedAt
      owner
    }
  }
`
export const listNewsletterLogModals = /* GraphQL */ `
  query ListNewsletterLogModals(
    $filter: ModelNewsletterLogModalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletterLogModals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        property
        group
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const getGroupedNewsletterUsers = /* GraphQL */ `
  query GetGroupedNewsletterUsers($id: ID!) {
    getGroupedNewsletterUsers(id: $id) {
      id
      newsletterID
      newsletterGroupID
      newsletter {
        id
        email
        groups {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      newsletterGroup {
        id
        name
        users {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const listGroupedNewsletterUsers = /* GraphQL */ `
  query ListGroupedNewsletterUsers(
    $filter: ModelGroupedNewsletterUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupedNewsletterUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        newsletterID
        newsletterGroupID
        newsletter {
          id
          email
          createdAt
          updatedAt
          owner
        }
        newsletterGroup {
          id
          name
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
