import { Close } from "@mui/icons-material"
import { Button, Dialog, IconButton, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Loading from "components/Atoms/Loading/Loading"
import React from "react"
import BuyerBrokerForm from "../BuyerBrokerForm"

type BOPFormView = {
  state: boolean
  handleClose(): void
  onCreateNewForm(values: any): void
  loading: boolean
  type?: "BROKER"
}

const BOPFormView: React.FC<BOPFormView> = ({
  state,
  handleClose,
  onCreateNewForm,
  loading,
}: BOPFormView) => {
  const classes = useStyles()
  const onClick = async () => {
    // html2canvas(FILE as HTMLElement)
    //   .then((canvas) => {
    //     const imgData = canvas.toDataURL("image/png")
    //     console.log(imgData)
    //     const doc = new jsPDF()
    //     doc.addImage(imgData, "JPEG", 15, 40, 180, 180)
    //     // pdf.output('dataurlnewwindow');
    //     doc.save("download.pdf")
    //   })
    //   .catch((err) => console.log(err))
    // const html = btoa("<p>Hello world!</p>")
    // const result = await pdf.create({ html })
    // pdf.download("sample.pdf", result.pdf)
  }
  return (
    <Dialog open={state} maxWidth={"lg"}>
      {loading && <Loading />}
      <div className={classes.root}>
        <div className={classes.header}>
          <IconButton
            className={classes.closeBtn}
            onClick={handleClose}
            disableRipple
          >
            <Close />
          </IconButton>
          <h3 className={classes.headerTitle}>Add New Form</h3>
          <Button onClick={onClick}>PDF</Button>
        </div>
        <BuyerBrokerForm onCreateNewForm={onCreateNewForm} loading={loading} />
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 500,
    minWidth: 800,
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    padding: theme.spacing(2),
  },
  headerTitle: {
    fontSize: "2rem",
    margin: 0,
  },
  closeBtn: {
    position: "absolute !important" as any,
    right: 0,
    top: 0,
  },
  formContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: theme.spacing(2),
  },
  formTextField: {
    padding: theme.spacing(1),
  },
  formAction: {
    padding: theme.spacing(2),
    backgroundColor: theme.colors.lightGrey,
    borderTop: `1px solid ${theme.colors.lightBlack}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
}))

export default BOPFormView
