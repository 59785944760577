import { signOut } from "@firebase/auth";
import { ExitToAppOutlined } from "@mui/icons-material";
import { Button, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { auth } from "api-client/config/firebase";
import LoginForm from "components/login/LoginForm";
import { AuthContext } from "contexts/AuthContext";
import Layout from "layout";
import React, { useContext } from "react";

const LoginView: React.FC = () => {
  const classes = useStyles();
  const { user, isAdmin } = useContext(AuthContext);
  return (
    <Layout>
      <Container maxWidth="xl" className={classes.root}>
        {(user && !isAdmin) ? <UnauthorizedLogin /> : <LoginForm />}
      </Container>
    </Layout>
  );
};

const UnauthorizedLogin = () => {
  return (
    <div className="flex flex-column">
      <Typography component="h2" variant="h4" textAlign="center">
        You are not authorized to access this portal
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        startIcon={<ExitToAppOutlined />}
        onClick={() => {
          signOut(auth);
        }}
      >
        Sign Out
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "100%",
    height: "100%",
    backgroundColor: theme.colors.white,
  },
}));

export default LoginView;
