import { Theme } from "@aws-amplify/ui-react"

const amplifyTheme: Theme = {
  name: "my-theme",
  tokens: {
    components: {
      button: {
        fontWeight: { value: "{fontWeights.black.value}" },
        primary: {
          backgroundColor: { value: "black" },
          color: { value: "#fff" },
          _hover: {
            backgroundColor: { value: "#000" },
          },
        },
        link: {
          color: { value: "#000" },
          _hover: {
            color: { value: "#fff" },
            backgroundColor: { value: "rgba(0,0,0,0.6)" },
          },
        }, // te,
      },
    },
    colors: {
      font: {
        primary: { value: "#000" },
        // ...
      },
    },
  },
}

export default amplifyTheme
