import { Close } from "@mui/icons-material"
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Theme,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import Loading from "components/Atoms/Loading/Loading"
import { useFormik } from "formik"
import React from "react"
import * as yup from "yup"
type AddNewForm = {
  state: boolean
  handleClose(): void
  onCreateNewForm(values: any): void
  loading: boolean
}

const AddNewForm: React.FC<AddNewForm> = ({
  state,
  handleClose,
  onCreateNewForm,
  loading,
}: AddNewForm) => {
  const classes = useStyles()
  const formik = useFormik({
    initialValues: {
      formTitle: "",
      formDescription: "",
      formFile: "",
    },
    validationSchema: ValidationScheme,
    onSubmit: onCreateNewForm,
  })
  return (
    <Dialog open={state} onClose={handleClose} maxWidth={"lg"}>
      {loading && <Loading />}
      <div className={classes.root}>
        <div className={classes.header}>
          <IconButton
            className={classes.closeBtn}
            onClick={handleClose}
            disableRipple
          >
            <Close />
          </IconButton>
          <h3 className={classes.headerTitle}>Add New Form</h3>
        </div>
        <div className={classes.formContent}>
          <div className={classes.formTextField}>
            <TextField
              label={"Title"}
              name="formTitle"
              onChange={formik.handleChange}
              helperText={formik.errors.formTitle}
              fullWidth
            />
          </div>
          <div className={classes.formTextField}>
            <TextField
              label={"Description"}
              name="formDescription"
              onChange={formik.handleChange}
              minRows={4}
              multiline
              helperText={formik.errors.formDescription}
              fullWidth
            />
          </div>
          <div className={classes.formTextField}>
            <Typography variant="body2">Select Form File</Typography>
            <TextField
              name="formFile"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue("formFile", event.currentTarget.files[0])
              }}
              type={"file"}
              variant="outlined"
              inputProps={{
                accept: "application/pdf",
              }}
              helperText={formik.errors.formFile}
            />
          </div>
        </div>
        <div className={classes.formAction}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => formik.handleSubmit()}
            disableRipple
            disabled={loading}
          >
            Upload New Form
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 500,
    minWidth: 800,

    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    padding: theme.spacing(2),
  },
  headerTitle: {
    fontSize: "2rem",
    margin: 0,
  },
  closeBtn: {
    position: "absolute !important" as any,
    right: 0,
    top: 0,
  },
  formContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: theme.spacing(2),
  },
  formTextField: {
    padding: theme.spacing(1),
  },
  formAction: {
    padding: theme.spacing(2),
    backgroundColor: theme.colors.lightGrey,
    borderTop: `1px solid ${theme.colors.lightBlack}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
}))

const ValidationScheme = yup.object().shape({
  formTitle: yup.string().required("Form Title is required"),
  formDescription: yup.string().required("Description is required"),
  formFile: yup.mixed().required("File is required"),
})

export default AddNewForm
