import { ArrowBackIos, LocalOffer, QuestionAnswer, Update } from "@mui/icons-material"
import { Button, IconButton, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import { FirebasePropertyOffer } from "api-client/firebase/offer"
import { FirebaseProperty } from "api-client/firebase/property"
import { IProperty, IOffer } from "api-client/types"
import { EditIcon } from "components/Atoms/Icons"
import EditPropertyForm from "components/EditPropertyForm"
import OffersTable from "components/OffersTable"
import { SnackbarContext } from "contexts/UseSnackbar"
import DashboardLayout from "layout/DashboardLayout"
import React from "react"
import {  useNavigate, useParams } from "react-router-dom"

const propertyApi = new FirebaseProperty()
const offersApi = new FirebasePropertyOffer()

const PropertyView = () => {
  const [isEditing, setIsEditing] = React.useState(true)
  const [property, setProperty] = React.useState<IProperty | null>(null)
  const [offersModal, setOffersModal] = React.useState<boolean>(false)
  const [offers, setOffers] = React.useState<IOffer[]>();
  const { openSnackbar }= React.useContext(SnackbarContext);

  const classes = useStyles()
  const { id } = useParams()
  const nav = useNavigate()

  const fetch = async () => {
    try {
      const res = await propertyApi.readProperty({id: id});
      if(res.type === 'error'){
        return openSnackbar({
          type: "error",
          message: "Something went wrong",
        });
      }
      setProperty(res.data);
    } catch (error) {
      return openSnackbar({
        type: "error",
        message: error?.message ?? "Something went wrong",
      });
    }
  }

  const fetchPropertyOffers = async() => {
    const res = await offersApi.getOffersForProperty(id);
    if(res.type === 'data')
      setOffers(res.data);
  }

  React.useEffect(() => {
    fetch()
    fetchPropertyOffers()
  }, [id])

  const handleUpdate = () => {
    const button = document.getElementById("edit-form-submit-button")
    button.click()
  }

  const handleBack = () => nav(-1)

  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box className={classes.appBar}>
          <Box className={classes.titleSection}>
            <IconButton disableRipple onClick={handleBack}>
              <ArrowBackIos />
            </IconButton>
            <Typography variant="h4">Property</Typography>
          </Box>

          <Box className={classes.buttonsContainer}>
          <Button
              variant="contained"
              color="secondary"
              onClick={() => {setOffersModal(true)}}
              endIcon={<LocalOffer />}
            >
              View Offers
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsEditing(false)}
              disabled={!isEditing}
              endIcon={<EditIcon />}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              endIcon={<Update />}
              disabled={isEditing}
            >
              Update
            </Button>
          </Box>
        </Box>
        <Box className={classes.formContainer}>
          {property && (
            <EditPropertyForm
              property={property}
              isEditing={isEditing}
              setProperty={setProperty}
              name={"edit-form"}
              setIsEditing={setIsEditing}
            />
          )}
        </Box>
      </Box>
      {offersModal && <OffersTable
        openOffers = {offersModal}
        offers = {offers}
        setOpenOffer = {(value) => setOffersModal(value) }

      />}
    </DashboardLayout>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
  },
  appBar: {
    position: "relative",
    top: "0",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
    zIndex: 9,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonsContainer: {
    width: 400,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  formContainer: {
    padding: theme.spacing(2),
    position: "relative",
    height: "100%",
    overflowX: "hidden",
  },
  titleSection: {
    display: "flex",
  },
}))

export default PropertyView
