import { Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import AddPropertyForm from "components/AddPropertyForm"
import DashboardLayout from "layout/DashboardLayout"

const AddPropertyView = () => {
  const classes = useStyles()
  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box className={classes.appBar}>
          <Typography variant="h4">Add New Property</Typography>
        </Box>
        <Box className={classes.formContainer}>
          <AddPropertyForm />
        </Box>
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(0, 2, 4),
  },
  appBar: {
    position: "absolute",
    top: `${theme.headerHeight}px`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[2],
    zIndex: 9,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    width: `calc(100% - ${theme.dashboardWidth}px)`,
    marginLeft: "-24px",
    height: 80,
  },
  appBarAction: {
    "& *": {
      marginRight: theme.spacing(2),
    },
  },
  formContainer: {
    marginTop: 90,
    height: "100%",
    display: "flex",
    overflowX: "hidden",
    overflowY: "auto",
  },
}))

export default AddPropertyView
