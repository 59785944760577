import { Storage } from "aws-amplify"
import { AWS_BUCKET_URL } from "CONSTANTS"

const useStorage = () => {
  const uploadFile = async (file: File, path) => {
    try {
      const res = await Storage.put(path, file, {
        level: "public",
      })
      return res.key
    } catch (error) {
      throw error
    }
  }
  const getFileURL = (key: any) => {
    try {
      const fileURL = `${AWS_BUCKET_URL}${key}`
      return fileURL
    } catch (error) {
      return ""
    }
  }
  const deleteFile = async (key: any) => {
    try {
      await Storage.remove(key)
    } catch (error) {
      return error
    }
  }
  return {
    uploadFile,
    getFileURL,
    deleteFile,
  }
}

export default useStorage
