import { db } from "api-client/config/firebase"
import { FirebaseCollection } from "api-client/enums"
import { FirebaseResponse, IMail, SendMailReq } from "api-client/types"
import { collection, doc, getDocs, limit, orderBy, query, writeBatch } from "firebase/firestore"
import _ from "lodash"
import { v4 as uid } from "uuid"

export class FirebaseMail {
  from = "Bank Owned Property USA <no-reply@bankownedpropertyusa.com>"
  collectionRef = collection(db, FirebaseCollection.Mail)
  batch = writeBatch(db)

  docRef = (id: string) => doc(db, FirebaseCollection.Mail, id)

  sendMail = async (param: SendMailReq) => {
    try {
      const { to, content, subject } = param
      _.map(to, (newsletter) => {
        return this.batch.set(this.docRef(uid()), {
          from: this.from,
          message: {
            html: content.toString(),
            subject: subject,
          },
          to: newsletter.email,
          createdAt: new Date()
        })
      })
      await this.batch.commit()
      return {
        type: "data",
        data: "Email Sent to Users",
      } as FirebaseResponse<string>
    } catch (error) {
      return {
        type: "error",
        data: error,
      } as unknown as FirebaseResponse<any>
    }
  }

  getMails = async () => {
    try {
      const result: Array<IMail> = []
      const collectionRef = collection(db, FirebaseCollection.Mail)
      const queries = [orderBy('createdAt', 'desc'), limit(500)];
      const docsQuery = query(collectionRef, ...queries );
      const snapshot = await getDocs(docsQuery);
      snapshot.forEach((doc) => {
        result.push({
          id: doc.id,
          ...doc.data() as IMail
        })
      })
      return {
        type: "data",
        data: result,
      } as FirebaseResponse<Array<IMail>>
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>
    }
  }
}
