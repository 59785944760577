import {
  Apartment,
  ContactPhone,
  CoPresent,
  Dashboard,
  FeaturedVideo,
  Group,
  LocalOffer,
  Mail,
  Settings,
  VerifiedUser,
} from "@mui/icons-material"
import { App_Routes } from "routes"

export const SIDE_BAR_APP_ROUTES = [
  {
    label: "Dashboard",
    link: App_Routes.DASHBOARD,
    icon: <Dashboard />,
  },
  {
    label: "Properties",
    link: App_Routes.PROPERTIES_PAGE,
    icon: <Apartment />,
  },
  {
    label: "Promotions",
    link: App_Routes.PROMOTIONS_VIEW,
    icon: <FeaturedVideo />,
  },
  {
    label: "Newsletter",
    link: App_Routes.NEWSLETTER_VIEW,
    icon: <Mail />,
  },
  {
    label: "Newsletter Groups",
    link: App_Routes.NEWSLETTER_GROUPS_VIEW,
    icon: <Group />,
  },
  {
    label: "Users",
    link: "/",
    icon: <ContactPhone />,
  },
  {
    label: "Offers",
    link: "/offers",
    icon: <LocalOffer />,
  },
  {
    label: "BOP Team",
    link: App_Routes.TEAM_PAGE,
    icon: <VerifiedUser />,
  },
  {
    label: "Brokers",
    link: App_Routes.BROKERS_PAGE,
    icon: <CoPresent />,
  },

  {
    label: "Settings",
    link: App_Routes.SETTINGS,
    icon: <Settings />,
  },
]

export const AWS_BUCKET_URL =
  "https://propertyimages123210-staging.s3.us-east-2.amazonaws.com/public/"
