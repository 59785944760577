/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system"
import Table from "components/Table/Table"
import TableToolBar from "components/Table/TableToolBar"
import { NewsletterGroupContext } from "contexts/NewsletterGroupContext"
import DashboardLayout from "layout/DashboardLayout"
import _ from "lodash"
import React, { useCallback, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { App_Routes } from "routes"


const NewsletterGroupsView = () => {
  const useNewsletterGroup = React.useContext(NewsletterGroupContext)
  const {selectedGroup, groups, fetchGroupMembers } = useNewsletterGroup
  const [filteredEmails, setFilteredEmails] = React.useState([])
  const [loading, setLoading] = useState(false)
  const params = useParams()

  const navigate = useNavigate()

  const updateEmails = useCallback(async () => {
    setLoading(true)
    if (!params.groupId || groups.length === 0)
      return navigate(App_Routes.NEWSLETTER_GROUPS_VIEW)
    fetchGroupMembers(params.groupId)
    setLoading(false)
  }, [params])

  React.useEffect(() => {
    updateEmails()
  }, [updateEmails])

  React.useEffect(() => {
    if(selectedGroup)setFilteredEmails(selectedGroup)
  }, [selectedGroup])

  const columns: Array<any> = [{ Header: "Email", accessor: "email", flex: 1 }]

  const handleSearch = (value: string) => {
    if (value === "") return setFilteredEmails(selectedGroup)
    setFilteredEmails(
      _.filter(filteredEmails, (email: any) => _.includes(email.email, value))
    )
  }
  
  const [value, setSearchTerm] = useState("")
  return (
    <DashboardLayout>
      <Box
        sx={{
          display: "flex !important",
          flexDirection: "column",
          height: "100%",
        }}>
        <TableToolBar onSearch={(value) => {handleSearch(value)
        setSearchTerm(value)
      }
        } searchValue={value} />
        <Table
          columns={columns}
          name='newsletter-group-email-table'
          data={filteredEmails}
          loading={loading}
          pagination
        />
      </Box>
    </DashboardLayout>
  )
}
export default NewsletterGroupsView
