import { parse } from "csv-parse/browser/esm/sync";
import _ from "lodash"

function exclude<T, Key extends keyof T | string>(
  fields: T,
  selected: Key | Key[]
): Omit<T, Key> {
  if (typeof selected === "string") {
    for (let key in fields) {
      if (typeof fields[key] === "object")
        exclude(fields[key], selected as string)

      if (fields[selected as any]) delete fields[selected as any]
    }
    return fields
  }

  _.forEach(selected as any, (selectedKey) => {
    delete fields[selectedKey as any]
  })
  return fields
}

function csvToArray(str, delimiter = ",", lowercase = false, cast = undefined) {
  const records = parse(str, {
    columns: header =>
    header.map(column => lowercase ? column.toLowerCase() : column.toUpperCase()),
    skip_empty_lines: true,
    delimiter: delimiter,
    cast: cast
  });

  return records;
}

const parseFirebaseDateTime = (date: any) => {
  const local = new Date(date.seconds * 1000);

  return `${local.toLocaleDateString()} ${local.toLocaleTimeString()}`;
};

export { exclude, csvToArray, parseFirebaseDateTime };
