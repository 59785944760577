import { alpha, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  tableContainer: {
    borderRadius: 0,
    width: "100%",
    display: "flex !important",
    flexDirection: "column",
    flex: "1",
    position: "relative",
    marginTop: theme.spacing(2),
  },

  tableHead: {
    color: theme.colors.common.white,
    width: "100%",
    height: 40,
  },

  tableRoot: {
    position: "relative",
    flex: 1,
  },
  resizeHandle: {
    "position": "absolute",
    "cursor": "col-resize",
    "zIndex": 100,
    "opacity": 0,
    "borderLeft": `1px solid ${theme.colors.common.white}`,
    "borderRight": `1px solid ${theme.colors.common.white}`,
    "height": "100%",
    "top": "0",
    "transition": "all linear 100ms",
    "right": -2,
    "width": 3,
    "&.handleActive": {
      opacity: 1,
      border: "none",
      backgroundColor: theme.colors.common.white,
      height: "calc(100% - 4px)",
      top: "2px",
      right: -1,
      width: 1,
    },
  },
  sortButton: {
    "marginLeft": theme.spacing(1),
    "& span": {
      color: theme.colors.table.grayDark,
    },
  },
  isActive: {
    "& span": {
      color: theme.colors.common.white,
    },
  },
  paper: {
    borderRadius: 0,
  },

  isloading: {
    width: "100%",
    position: "absolute",
    height: "100%",
    top: 0,
    background: alpha(theme.palette.common.white, 0.1),
    zIndex: 99,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

export default useStyles
