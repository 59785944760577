/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Paper, Switch, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import {
  getSettings,
  updateNotificationSetting,
} from "bop-entities/use-settings"
import Loading from "components/Atoms/Loading/Loading"
import DashboardLayout from "layout/DashboardLayout"
import React, { useEffect, useState } from "react"

const SettingsView = () => {
  const classes = useStyles()
  const [settings, setSettings] = useState(null)
  const [newsletter, setNewsletter] = useState(false)

  const InitSettings = React.useCallback(async () => {
    const res = await getSettings()
    setSettings(res)
  }, [])

  useEffect(() => {
    InitSettings()
  }, [])

  useEffect(() => {
    setNewsletter(settings?.newsletter)
  }, [settings])

  const handleNewsletterChange = async () => {
    setNewsletter((pre) => !pre)
    await updateNotificationSetting({
      id: settings.id,
      newsletter: !settings.newsletter,
    })
    setSettings((pre) => ({
      ...pre,
      newsletter: !pre.newsletter,
    }))
  }

  if (settings)
    return (
      <DashboardLayout>
        <Box className={classes.pageContainer}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "16px",
              backgroundColor: "#ccc",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "22px",
                fontWeight: "900",
              }}
            >
              Settings
            </Typography>
          </Box>
          <Box
            sx={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: "16px",
            }}
            component={Paper}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              sx={{
                borderBottom: "1px solid #ccc",
                padding: "12px",
              }}
            >
              <Typography>Email Notification</Typography>
              <Switch
                checked={Boolean(newsletter)}
                onChange={handleNewsletterChange}
                name="newsletter"
                value={Boolean(newsletter)}
              />
            </Grid>
          </Box>
        </Box>
      </DashboardLayout>
    )

  return <Loading />
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
    gap: "20px",
  },
  viewButton: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    width: "inherit",
  },
}))
export default SettingsView
