import { Close } from "@mui/icons-material"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { FormikProps } from "formik"
import React from "react"

interface PromotionDialogProps {
  state: boolean
  onClose(): void
  formik: FormikProps<{
    id: string
    name: any
    link: any
    image: File
    createdOn: string
    status: any
  }>
}

const PromotionDialog: React.FC<PromotionDialogProps> = ({
  state,
  onClose,
  formik,
}: PromotionDialogProps) => {
  const handleClose = () => onClose()
  const classes = useStyles()

  const handleSubmit = () => {
    formik.handleSubmit()
  }
  return (
    <Dialog open={state} onClose={handleClose}>
      <Box className={classes.root}>
        <Close className={classes.closeBtn} onClick={handleClose} />
        <Box className={classes.form}>
          <Typography>Name</Typography>
          <TextField
            sx={{
              marginBottom: "16px",
            }}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.name}
            name="name"
            disabled={formik.isSubmitting}
            fullWidth
          />
          <Typography>Link</Typography>
          <TextField
            sx={{
              marginBottom: "16px",
            }}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.link}
            name="link"
            disabled={formik.isSubmitting}
            fullWidth
          />
          <Typography>Status</Typography>
          <TextField
            sx={{
              marginBottom: "16px",
            }}
            variant="outlined"
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.status}
            name="status"
            disabled={formik.isSubmitting}
            select
          >
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">In Active</MenuItem>
          </TextField>

          <input
            type="file"
            name="image"
            accept="image/*"
            disabled={formik.isSubmitting}
            onChange={(event) => {
              formik.setFieldValue("image", event.currentTarget.files[0])
            }}
          />
          <Box
            sx={{
              paddingTop: "16px",
              marginTop: "16px",
              display: "flex",
              flexDirection: "column",
              justifyItems: "flex-end",
              borderTop: "1px solid #ccc",
            }}
          >
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <CircularProgress color="secondary" />
              ) : (
                <>Save</>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    minHeight: 400,
    display: "flex",

    position: "relative",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  closeBtn: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 999,
    cursor: "pointer",
  },
}))

export default PromotionDialog
