import React from "react";
import { Box, Hidden, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IOffer } from "api-client/types";

interface OfferTableProps {
  offer: IOffer;
}

const OfferTableRow: React.FC<OfferTableProps> = ({
  offer,
}: OfferTableProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.tableRow} >
      <Box className={classes.tableRowCell}>{offer.propertyAddress}</Box>
      <Box className={classes.tableRowCell}>${offer.offerAmount}</Box>
      <Box className={classes.tableRowCell}>{offer.offerType}</Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    display: "flex",
    width: "100%",
    minHeight: 80,
    marginTop: theme.spacing(2),
    borderBottom: "1px solid #ccccd2"
  },
  tableRowCell: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flex: 1,
    padding: theme.spacing(1),
    color: theme.colors.overlayBlack,
    textTransform: "uppercase",
  },
}));
export default OfferTableRow;
