/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material"
import { Box } from "@mui/system"
import { IPagination } from "api-client/utils"
import AddNewsletterGroupForm from "components/AddNewsletterGroupForm"
import ActionCell from "components/Table/ActionCell"
import Table from "components/Table/Table"
import TableToolBar from "components/Table/TableToolBar"
import { NewsletterGroupContext } from "contexts/NewsletterGroupContext"
import DashboardLayout from "layout/DashboardLayout"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

let timer

const NewsletterGroupsView = () => {
  const [modalState, setModalState] = React.useState(false)
  const navigate = useNavigate()
  const [pagination, setPagination] = React.useState<IPagination>({
    pageSize: 10,
  })

  const useNewsletterGroup = React.useContext(NewsletterGroupContext)
  const {
    groups,
    loading,
    filteredGroups,
    setFilteredGroups,
    fetchGroups,
    searchGroupsByTitle,
    deleteGroupById,
  } = useNewsletterGroup

  React.useLayoutEffect(() => {
    if (groups.length !== 0) {
      setFilteredGroups(groups)
      return
    }
    fetchGroups()
  }, [groups])

  const handleSearch = (value: string) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(
      () => searchGroupsByTitle(value, pagination.pageSize),
      750
    )
  }

  const handleDelete = async (id: string) => deleteGroupById(id)

  const columns: Array<any> = [
    { Header: "ID", accessor: "id", width: 100 },
    { Header: "Label", accessor: "label", flex: 1 },
    {
      Header: "Action",
      width: 140,
      disableSortBy: true,
      disableFilters: true,
      disableResizing: true,
      maxWidth: 160,
      Cell: ({ row }: any) => (
        <ActionCell row={row} onDelete={() => handleDelete(row.original.id)} />
      ),
    },
    {
      Header: " ",
      width: 140,
      disableSortBy: true,
      disableFilters: true,
      disableResizing: true,
      maxWidth: 160,
      Cell: ({ row }: any) => (
        <Button
          variant='contained'
          onClick={() => navigate(`/newsletter/group/${row.original.id}`)}>
          View
        </Button>
      ),
    },
  ]
  const [value, setSearchTerm] = useState("")
  return (
    <DashboardLayout>
      <Box
        sx={{
          display: "flex !important",
          flexDirection: "column",
          height: "100%",
        }}>
        <TableToolBar
          onSearch={(value) => {
            setSearchTerm(value)
            handleSearch(value)
          }}
          onAdd={() => setModalState(true)}
          searchValue={value}
        />
        <Table
          columns={columns}
          name='newsletter-group-table'
          data={loading ? [] : filteredGroups}
          loading={loading}
          onSelect={() => undefined}
          pagination
          onLoadMore={(pagination) => {
            setPagination(pagination)
          }}
        />
      </Box>
      {modalState && (
        <AddNewsletterGroupForm
          onClose={() => setModalState(false)}
          state={modalState}
        />
      )}
    </DashboardLayout>
  )
}
export default NewsletterGroupsView
