import { LoanType } from "api-client/enums";
import { ILendingInquiry } from "api-client/types";

export class LendingInquiry implements ILendingInquiry {
  id: string;
  name: string;
  email: string;
  phone: string;
  loanType: LoanType;
  propertyId: string;
  propertyAddress: string;
  propertyAsking: string;
  message: string;

  constructor(p: ILendingInquiry) {
    for (const key in p) {
        this[key as keyof this] = p[key as keyof typeof p] as any;
      }
  }
}
