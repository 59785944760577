import {
  Button,
  Dialog,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box, Theme } from "@mui/system"
import { PROPERTY_STATUS } from "CONSTANTS/property"
import { Status } from "interfaces"
import React from "react"
type EditPropertiesDialogProps = {
  state: boolean
  onClose(): void
  onUpdate(status: Status): void
}

const EditPropertiesDialog: React.FC<EditPropertiesDialogProps> = ({
  state,
  onClose,
  onUpdate,
}: EditPropertiesDialogProps) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(Status.PUBLISHED)

  return (
    <Dialog open={state} maxWidth="md" onClose={onClose}>
      <Box component={Paper} className={classes.root}>
        <Typography variant="h5">Update Properties Status</Typography>
        <TextField
          value={value}
          onChange={(e) => {
            setValue(e.target.value as Status)
          }}
          className={classes.root}
          variant="filled"
          fullWidth
          select
        >
          {PROPERTY_STATUS.map((status, index) => (
            <MenuItem value={status.value} key={index}>
              {status.label}
            </MenuItem>
          ))}
        </TextField>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onUpdate(value)}
          fullWidth
        >
          Update
        </Button>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 150,
    padding: theme.spacing(4),
    width: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    marginTop: `${theme.spacing(2)} !important`,
  },
}))

export default EditPropertiesDialog
