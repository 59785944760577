import React from "react"

type AuthProviderProps = {
  user: unknown
  setUser: React.Dispatch<any>
}

const initialState: AuthProviderProps = {
  user: undefined,
  setUser: undefined,
}

export const UserContext = React.createContext(initialState)

const { Consumer: UserConsumer, Provider } = UserContext

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState(initialState.user)
  const values = {
    user,
    setUser,
  }

  return <Provider value={values}>{children}</Provider>
}

export { UserConsumer, UserProvider }
