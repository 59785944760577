import { FirebaseCollection } from "api-client/enums";
import { db } from "api-client/config/firebase";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  limit,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { writeBatch } from "firebase/firestore";
import {
  ErrorResponse,
  FirebaseResponse,
  INewsletter,
  INewsletterGroup,
  SuccessResponse,
} from "api-client/types";
import { NewsletterGroup } from "api-client/models/NewsletterGroup";
import { generateCollectionPath, generateDocPath } from "api-client/utils";
import { Newsletter } from "api-client/models/Newsletter";
import { v4 as uuidv4 } from "uuid";

export class FirebaseNewsletterGroup {
  id?: string;

  createNewsletterGroup = async (payload: INewsletterGroup) => {
    try {
      const collectionRef = collection(db, FirebaseCollection.NewsletterGroups);
      const docRef = await addDoc(collectionRef, payload);
      return {
        type: "data",
        data: new NewsletterGroup({
          id: docRef.id,
          label: payload.label,
        }),
      } as FirebaseResponse<NewsletterGroup>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };

  deleteNewsletterFromGroup = async (groupId: string, email: string) => {
    try {
      const collectionRef = collection(db,
        FirebaseCollection.NewsletterGroups,
        groupId,
        FirebaseCollection.NewsletterGroupMembers,
      );
      const docRef = doc(collectionRef, email);
      await deleteDoc(docRef);
      return {
        type: "data",
        data: {},
      } as FirebaseResponse<NewsletterGroup>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  }

  getNewsletterGroup = async (groupId: string) => {
    try {
      const collectionRef = collection(db, FirebaseCollection.NewsletterGroups);
      const temp = doc(collectionRef, groupId);
      const snapshot = await getDoc(temp);
      const newsletterGroup = new NewsletterGroup({
          id: snapshot.id,
          label: snapshot.data()?.label,
      });
      return {
        type: "data",
        data: newsletterGroup,
      } as FirebaseResponse<NewsletterGroup>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };

  getNewsletterGroups = async () => {
    try {
      const result: Array<NewsletterGroup> = [];
      const collectionRef = collection(db, FirebaseCollection.NewsletterGroups);
      const snapshot = await getDocs(collectionRef);
      snapshot.forEach((doc) => {
        const newsletterGroup = new NewsletterGroup({
          id: doc.id,
          label: doc.data()?.label,
        });
        result.push(newsletterGroup);
      });
      return {
        type: "data",
        data: result,
      } as FirebaseResponse<Array<NewsletterGroup>>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };

  deleteNewsletterGroup = async (id: string) => {
    try {
      const docRef = doc(
        db,
        generateDocPath(FirebaseCollection.NewsletterGroups, id)
      );
      await deleteDoc(docRef);

      return {
        type: "data",
        data: "Group Deleted Successfully",
      } as FirebaseResponse<String>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };

  getGroupMembers = async (id: string): Promise<ErrorResponse<any> | SuccessResponse<Array<Newsletter>>> => {
    try {
      const result: Array<Newsletter> = [];
      const path = generateCollectionPath([
        FirebaseCollection.NewsletterGroups,
        id,
        FirebaseCollection.NewsletterGroupMembers,
      ]);
      const collectionRef = collection(db, path);
      const snapshot = await getDocs(collectionRef);
      snapshot.forEach((doc) => {
        const newsletter = new Newsletter({
          ...(doc.data() as INewsletter),
        });
        result.push(newsletter);
      });
      return {
        type: "data",
        data: result,
      } as FirebaseResponse<Array<Newsletter>>;
    } catch (error) {
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };

  addNewsletterToGroup = async (payload: Array<INewsletter>, id: string) => {
    try {
      const path = generateCollectionPath([
        FirebaseCollection.NewsletterGroups,
        id,
        FirebaseCollection.NewsletterGroupMembers,
      ]);
      const batch = writeBatch(db);
      const collectionRef = collection(db, path);
      payload.map((item) => {
        const docRef = doc(collectionRef, item.email.toLowerCase());
        return batch.set(docRef, {
          id: uuidv4(),
          email: item.email.toLowerCase(),
        });
      });
      await batch.commit();
      return {
        type: "data",
        data: "Emails successfully added to group",
      } as FirebaseResponse<string>;
    } catch (error) {
      return {
        type: "error",
        data: error,
      } as unknown as FirebaseResponse<any>;
    }
  };

  /* Firebase text search is case-sensitive */
  searchGroupsByTitle = async (title: string, pageSize: number) => {
    try {
      const result: Array<NewsletterGroup> = [];
      const collectionRef = collection(db, FirebaseCollection.NewsletterGroups);
      const docsQuery = query(
        collectionRef,
        where("label", ">=", title),
        where("label", "<=", title + "\uf8ff"),
        limit(pageSize)
      );
      const snapshot = await getDocs(docsQuery);
      snapshot.forEach((doc) => {
        const newsletterGroup = new NewsletterGroup({
          id: doc.id,
          label: doc.data()?.label,
        });
        result.push(newsletterGroup);
      });
      return {
        type: "data",
        data: result,
      } as FirebaseResponse<Array<NewsletterGroup>>;
    } catch (error) {
      console.log(error);
      return {
        type: "error",
        error: error,
      } as FirebaseResponse<any>;
    }
  };
}
