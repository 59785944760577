import { IBroker } from "api-client/types";
import React, { useCallback, useEffect } from "react";
import { SnackbarContext } from "./UseSnackbar";
import _ from "lodash";
import { FirebaseBroker } from "api-client/firebase/broker";

type BrokerContextProps = {
  brokersList: Array<IBroker>;
  isLoadingBrokers: boolean;
  getBrokers?(): void;
  deleteBroker?(brokerId: string): Promise<any>;
  createBroker?(values: IBroker): Promise<any>;
  updateBroker?(values: IBroker): Promise<any>;
};

const initialState: BrokerContextProps = {
  brokersList: [],
  isLoadingBrokers: false,
};

const brokerApi = new FirebaseBroker();

export const BrokerContext = React.createContext(initialState);

const { Consumer: BrokerConsumer, Provider } = BrokerContext;

const BrokerProvider: React.FC = ({ children }) => {
  const [brokersList, setBrokersList] = React.useState(
    initialState.brokersList
  );
  const [isLoadingBrokers, setIsLoadingBrokers] = React.useState(
    initialState.isLoadingBrokers
  );

  const { openSnackbar } = React.useContext(SnackbarContext);

  const getBrokers = useCallback(async () => {
    try {
      setIsLoadingBrokers(true);
      const res = await brokerApi.readBrokers();
      if (res.type === "error") {
        throw Error();
      }

      setBrokersList(res.data);
      setIsLoadingBrokers(false);
    } catch (error) {
      setIsLoadingBrokers(false);
      openSnackbar({
        type: "error",
        message: "Error fetching brokers",
      });
    }
  }, [setIsLoadingBrokers, setBrokersList, openSnackbar]);

  useEffect(() => {
    getBrokers();
  }, [getBrokers]);

  const deleteBroker = async (brokerId: string) => {
    try {
      const res = await brokerApi.deleteBroker(brokerId);
      if (res.type === "error") throw Error(res.error as string);
      openSnackbar({ type: "success", message: "Broker Deleted" });
      setBrokersList(_.filter(brokersList, (item) => item.id !== brokerId));
    } catch (error) {
      openSnackbar({ type: "success", message: "Error deleting broker" });
    }
  };

  const createBroker = async (values: any) => {
    const res = await brokerApi.createBroker(values);
    if (res.type === "error") throw Error(res.error as string);

    openSnackbar({ type: "success", message: "Broker Created" });
    brokersList.push(res.data as IBroker);
    setBrokersList(brokersList);
  };

  const updateBroker = async (values: any) => {
    const res = await brokerApi.updateBroker(values);
    if (res.type === "error") throw Error(res.error as string);

    openSnackbar({ type: "success", message: "Broker Updated" });
    const index = _.findIndex(brokersList, (broker) => broker.id === values.id);
    brokersList[index] = values;
    setBrokersList(brokersList);
  };

  const values = {
    brokersList,
    isLoadingBrokers,
    getBrokers,
    deleteBroker,
    createBroker,
    updateBroker,
  };

  return <Provider value={values}>{children}</Provider>;
};

export { BrokerConsumer, BrokerProvider };
