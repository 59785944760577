import "@aws-amplify/ui-react/styles.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { App_Routes } from "routes";
import AddPropertyView from "views/AddPropertyView";
import BrokersView from "views/BrokersView/BrokersView";
import DashboardView from "views/Dashboard";
import EditPropertyView from "views/EditPropertyView";
import EmailGroupView from "views/EmailGroupView";
import EmailTemplateView from "views/EmailTemplateView";
import FormView from "views/FormView";
import LendingInquiryView from "views/LendingInquiryView";
import MailView from "views/MailView";
import NewsletterGroupsView from "views/NewsletterGroupsView";
import NewsletterView from "views/NewsletterView";
import OffersView from "views/Offers";
import PromotionsView from "views/PromotionsView";
import PropertiesView from "views/PropertiesView";
import PropertyView from "views/propertyView";
import ProtectedRoute from "views/ProtectedRoute";
import SettingsView from "views/SettingsView/Index";
import TeamView from "views/Team";
import UsersView from "views/UsersView";
import LoginView from "./views/Login";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={App_Routes.LOGIN} element={<LoginView />} />
        {/* <Route
          path={App_Routes.SET_NEW_PASSWORD}
          element={<SetNewPasswordView />}
        /> */}
        <Route
          path={App_Routes.DASHBOARD}
          element={
            <ProtectedRoute>
              <DashboardView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.PROPERTIES_PAGE}
          element={
            <ProtectedRoute>
              <PropertiesView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.ADD_PROPERTY}
          element={
            <ProtectedRoute>
              <AddPropertyView />
            </ProtectedRoute>
          }
        />

        <Route
          path={App_Routes.EDIT_PROPERTY}
          element={
            <ProtectedRoute>
              <EditPropertyView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.VIEW_PROPERTY}
          element={
            <ProtectedRoute>
              <PropertyView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.BROKERS_PAGE}
          element={
            <ProtectedRoute>
              <BrokersView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.FORMS_PAGE}
          element={
            <ProtectedRoute>
              <FormView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.TEAM_PAGE}
          element={
            <ProtectedRoute>
              <TeamView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.PROMOTIONS_VIEW}
          element={
            <ProtectedRoute>
              <PromotionsView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.NEWSLETTER_VIEW}
          element={
            <ProtectedRoute>
              <NewsletterView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.NEWSLETTER_GROUPS_VIEW}
          element={
            <ProtectedRoute>
              <NewsletterGroupsView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.NEWSLETTER_GROUP_EMAILS}
          element={
            <ProtectedRoute>
              <EmailGroupView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.SETTINGS}
          element={
            <ProtectedRoute>
              <SettingsView />
            </ProtectedRoute>
          }
        />
        {/*TODO: Create Page Not Found View*/}
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <DashboardView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.EMAIL_TEMPLATE_VIEW}
          element={
            <ProtectedRoute>
              <EmailTemplateView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.OFFERS}
          element={
            <ProtectedRoute>
              <OffersView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.USERS_PAGE}
          element={
            <ProtectedRoute>
              <UsersView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.MAIL_VIEW}
          element={
            <ProtectedRoute>
              <MailView />
            </ProtectedRoute>
          }
        />
        <Route
          path={App_Routes.LENDING_INQUIRY}
          element={
            <ProtectedRoute>
              <LendingInquiryView />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
