import { ArrowBackIos, Update } from "@mui/icons-material"
import { Button, IconButton, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import { FirebaseProperty } from "api-client/firebase/property"
import { IProperty } from "api-client/types"
import { EditIcon } from "components/Atoms/Icons"
import EditPropertyForm from "components/EditPropertyForm"
import { SnackbarContext } from "contexts/UseSnackbar"
import DashboardLayout from "layout/DashboardLayout"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"

const propertyApi = new FirebaseProperty()


const EditPropertyView = () => {
  const classes = useStyles()
  const [isEditing, setIsEditing] = React.useState(false)

  const useSnackbar = React.useContext(SnackbarContext);
  const { openSnackbar } = useSnackbar;

  const { id } = useParams()
  const nav = useNavigate()
  const [property, setProperty] = React.useState<IProperty | null>(null)

  const fetch = async () => {
    try {
      const res = await propertyApi.readProperty({id: id});
      if(res.type == 'error'){
        return openSnackbar({
          type: "error",
          message: "Something went wrong",
        });
      }

      setProperty(res.data);
    } catch (error) {
      return openSnackbar({
        type: "error",
        message: error?.message ?? "Something went wrong",
      });
    }
  }
  React.useEffect(() => {
    fetch()
  }, [id])

  const handleUpdate = () => {
    const button = document.getElementById("edit-form-submit-button")
    button.click()
  }

  const handleBack = () => nav(-1)

  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box className={classes.appBar}>
          <Box className={classes.titleSection}>
            <IconButton disableRipple onClick={handleBack}>
              <ArrowBackIos />
            </IconButton>
            <Typography variant="h4">Property</Typography>
          </Box>

          <Box className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsEditing(false)}
              disabled={!isEditing}

              endIcon={<EditIcon />}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              endIcon={<Update />}
              // disabled={Boolean(
              //   document.getElementById("edit-form-submit-button")
              // )}
            >
              Update
            </Button>
          </Box>
        </Box>
        <Box className={classes.formContainer}>
          {property && (
            <EditPropertyForm
              property={property}
              isEditing={isEditing}
              name={"edit-form"}
              setProperty={setProperty}
              setIsEditing={setIsEditing}
            />
          )}
        </Box>
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
  },
  appBar: {
    position: "relative",
    top: "0",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
    zIndex: 9,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonsContainer: {
    width: 300,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  formContainer: {
    padding: theme.spacing(2),
    position: "relative",
    height: "100%",
    overflowX: "hidden",
  },
  titleSection: {
    display: "flex",
  },
}))

export default EditPropertyView
