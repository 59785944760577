import { TableCellProps, Theme } from "@mui/material";
import MuiTableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { CSSProperties } from "react";

type CN = { className?: string; style?: any | never | unknown | CSSProperties };

const TableCell: React.FC<Partial<TableCellProps> & Partial<CN>> = React.memo(
  ({ children, className, ...rest }) => {
    const classes = useStyles();
    return (
      <MuiTableCell className={clsx(className, classes.tableCell)} {...rest}>
        {children}
      </MuiTableCell>
    );
  }
);

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    display: "flex !important",
    alignItems: "center",
    minWidth: 80,
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: theme.spacing(0, 2),
    whiteSpace: "nowrap",
    fontSize: "1rem !important",
    background: "white",
  },
}));

// const useStyles = makeStyles((theme: Theme) => ({
//   tableCell: {
//     display: "flex",
//     alignItems: "center",
//     minWidth: 100,
//     overflow: "hidden",
//     padding: theme.spacing(0),
//     '&[data-sticky-td="true"]': {
//       background: "inherit",
//     },
//     '&[data-sticky-last-left-td="true"]': {
//       background: "inherit",
//       borderRight: `1px solid ${theme.colors.common.black}`,
//     },
//   },
// }))

export default TableCell;
