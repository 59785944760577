export enum Status {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  SOLD = "SOLD",
  LIVE = "LIVE",
  CLOSED = "CLOSED",
  PRIVATE = "PRIVATE",
}

export enum PropertyType {
  REO = "REO",
  SHORT_SALE = "SHORT_SALE",
  FORECLOSURE = "FORECLOSURE",
  ESTATE_SALE = "ESTATE_SALE",
  LAND = "LAND",
  MORTGAGE = "MORTGAGE",
}

export interface Property {
  id?: string | null
  propertyTitle?: string | null
  propertyFeaturedImage?: string | null
  propertyImages?: Array<string | null> | null
  propertyVideo?: string | null
  propertyAskingPrice?: string | null
  PropertyDetails: PropertyDetails
  propertyStreetAddress?: string | null
  propertyLocation?: string | null
  propertyState?: string | null
  addedOn?: string | null
  brokerID?: string | null
  _version?: number | null
  status?: Status
}

export interface PropertyDetails {
  propertyStatus: PropertyStatus
  lotSize?: string | null
  bedRooms?: string | null
  bathRooms?: string | null
  swimmingPool?: boolean | null
  sunRoom?: boolean | null
  garage?: boolean | null
  privateDriveway?: boolean | null
  squareFootage?: string | null
}

export enum PropertyStatus {
  VACANT = "VACANT",
  OCCUPIED = "OCCUPIED",
}
