import { Button, Theme } from "@mui/material"
import { red } from "@mui/material/colors"
import { makeStyles } from "@mui/styles"
import FormModel from "bop-entities/form/formModel"
import useStorage from "bop-entities/use-storage"
import { DeleteIcon } from "components/Atoms/Icons"
import React from "react"

type FormCard = {
  form: FormModel
  handleDelete(): void
}
const FromCard: React.FC<FormCard> = ({ form, handleDelete }: FormCard) => {
  const classes = useStyles()
  const { getFileURL } = useStorage()

  const [url, setURL] = React.useState("")
  const getURL = async () => {
    const url = await getFileURL(form.formFile.key)
    setURL(url)
  }
  React.useEffect(() => {
    getURL()
  }, [])
  const handleDownloadForm = () => {
    const link: any = document.createElement("a")
    link.id = "download-form"
    link.setAttribute("href", url)
    link.setAttribute("target", "_blank")
    document.body.appendChild(link)
    document.getElementById("download-form").click()
    document.getElementById("download-form").remove()
  }
  return (
    <div className={classes.root}>
      <DeleteIcon className={classes.deleteButton} onClick={handleDelete} />
      <div className={classes.content}>
        <div className={classes.contentTitle}>{form.formTitle}</div>
        <div className={classes.contentDescription}>
          {form.formDescription.toLowerCase()}
        </div>
      </div>
      <div className={classes.action}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadForm}
        >
          Download Form
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 300,
    height: 200,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: theme.colors.white,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 3, 0, 1),
    flex: 1,
  },
  contentTitle: {
    fontSize: "1rem",
    fontWeight: 900,
    textTransform: "uppercase",
  },
  contentDescription: {
    fontSize: "1rem",
    marginTop: theme.spacing(1),
    textTransform: "capitalize",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  action: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: theme.spacing(2),
  },
  deleteButton: {
    position: "absolute",
    top: 10,
    right: 10,
    color: red[900],
    cursor: "pointer",
    width: 24,
    height: 24,
  },
}))

export default FromCard
