import { Button, Dialog, Divider, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { FirebaseNewsletter } from "api-client/firebase/newsletter"
import { INewsletter } from "api-client/types"
import { NewsletterContext } from "contexts/NewsletterContext"
import { SnackbarContext } from "contexts/UseSnackbar"
import { useFormik } from "formik"
import React from "react"
import * as yup from "yup"

interface AddNewsletterFormProps {
  onClose(): void
  state: boolean
}

const AddNewsletterForm: React.FC<AddNewsletterFormProps> = ({
  onClose,
  state,
}: AddNewsletterFormProps) => {

  const useNewsletter = React.useContext(NewsletterContext);
  const {addNewsletter} = useNewsletter;

  const handleFormSubmit = async (value: INewsletter ) => {
    await addNewsletter(value);
    onClose()
  }

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  })

  return (
    <Dialog open={state} onClose={onClose}>
      <Box
        sx={{
          minWidth: 400,
          display: "flex",
          padding: "1rem",
          flexDirection: "column",
        }}>
        <Typography
          sx={{
            padding: "1rem",
            fontSize: "1.4rem",
          }}>
          Add New Subscriber
        </Typography>
        <Divider />
        <Typography
          sx={{
            marginTop: "1rem",
          }}>
          Email
        </Typography>
        <TextField
          fullWidth
          onChange={formik.handleChange}
          name='email'
          value={formik.values.email}
        />
        <Button
          sx={{
            marginTop: "1rem",
          }}
          variant='contained'
          onClick={() => formik.handleSubmit()}>
          Submit
        </Button>
      </Box>
    </Dialog>
  )
}

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
})

export default AddNewsletterForm
