import { Table as MuiTableTable, TableTypeMap, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import React, { CSSProperties } from "react"
type CN = { className?: string; style?: any | never | unknown | CSSProperties }

export const TableTable: React.FC<Partial<TableTypeMap> & CN> = ({
  children,
  className,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <MuiTableTable className={clsx(className, classes.tableTable)} {...rest}>
      {children}
    </MuiTableTable>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  tableTable: {
    border: `1px solid ${theme.colors.common.whiteDark}`,
    width: "100%",
  },
}))

export default TableTable
