import { TableRow as MuiTableRow, TableRowTypeMap, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import React, { CSSProperties } from "react"

type CN = { className?: string; style?: any | never | unknown | CSSProperties }

const TableHeadRow: React.FC<Partial<TableRowTypeMap> & CN> = ({
  children,
  className,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <MuiTableRow className={clsx(className, classes.tableHeadRow)} {...rest}>
      {children}
    </MuiTableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  tableHeadRow: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
  },
}))

export default TableHeadRow
