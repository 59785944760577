/* eslint-disable quotes */
import { grey } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"
import { Theme } from "@mui/system"

declare module "@mui/material/styles" {
  interface Theme {
    bodyFont: {
      family: string
      size: string | number
    }
    colors?: {
      black?: string
      grey?: string
      white?: string
      yellow?: string
      overlayBlack?: string
      lightGrey: string
      darkGrey: string
      lightBlack: string
      background: {
        default?: string
        paper?: string
        light?: string
        grayLight?: string
        grayMedium?: string
        grayDark?: string
        blueLight?: string
        blue?: string
        black?: string
      }
      common: {
        black?: string
        grayLight?: string
        grayMedium?: string
        grayMediumDark?: string
        grayDark?: string
        red?: string
        redDark?: string
        redLight?: string
        white?: string
        whiteDark?: string
        whiteLight?: string
        blue?: string
        blueLight?: string
        blueSky?: string
        blueDark?: string
        skeleton?: string
        green?: string
        greenLight?: string
        greenLighter?: string
        yellowLight?: string
      }
      table: {
        whiteDark?: string
        blueDark?: string
        blue?: string
        blueLight?: string
        linkedTextblue?: string
        grayDark?: string
      }
      primary: {
        light?: string
        main?: string
        dark?: string
        contrastText?: string
      }
      secondary: {
        main?: string
        contrastText?: string
      }
    }
    headerHeight: number | string
    footerHeight: number | string
    dashboardWidth: number | string
  }
  interface ThemeOptions {
    bodyFont?: {
      family?: string
      size?: string | number
    }
    colors?: {
      black?: string
      grey?: string
      white?: string
      yellow?: string
      overlayBlack?: string
      lightGrey: string
      darkGrey: string
      lightBlack: string
      background: {
        default?: string
        paper?: string
        light?: string
        grayLight?: string
        grayMedium?: string
        grayDark?: string
        blueLight?: string
        blue?: string
        black?: string
      }
      common: {
        black?: string
        grayLight?: string
        grayMedium?: string
        grayMediumDark?: string
        grayDark?: string
        red?: string
        redDark?: string
        redLight?: string
        white?: string
        whiteDark?: string
        whiteLight?: string
        blue?: string
        blueLight?: string
        blueSky?: string
        blueDark?: string
        skeleton?: string
        green?: string
        greenLight?: string
        greenLighter?: string
        yellowLight?: string
      }
      table: {
        whiteDark?: string
        blueDark?: string
        blue?: string
        blueLight?: string
        linkedTextblue?: string
        grayDark?: string
      }
      primary: {
        light?: string
        main?: string
        dark?: string
        contrastText?: string
      }
      secondary: {
        main?: string
        contrastText?: string
      }
    }
    headerHeight?: number | string
    footerHeight?: number | string
    dashboardWidth: number | string
  }
}

const theme: Theme = createTheme({
  colors: {
    black: "#000",
    grey: grey[200],
    darkGrey: grey[900],
    lightGrey: "#eee",
    white: "#fff",
    yellow: "#f6cc32",
    lightBlack: "rgba(0,0,0,0.6)",
    overlayBlack: "rgba(0,0,0,0.6)",
    background: {
      default: "#e5e5e5",
      paper: "#ffffff",
      light: "#f2f2f2",
      grayLight: "#f5f1f0",
      grayMedium: "#515151",
      grayDark: "#3D3D3D",
      blueLight: "#e5effe",
      blue: "#d1e3ff",
      black: "#000",
    },
    common: {
      black: "#333333",
      grayLight: "#BDBDBD",
      grayMedium: "#828282",
      grayMediumDark: "#666666",
      grayDark: "#4F4F4F",
      red: "#E25141",
      redDark: " #D6300F",
      redLight: "#F7D6CF",
      white: "#ffffff",
      whiteDark: "#E0E0E0",
      whiteLight: "#FAFBFC",
      blueLight: "#005FEF",
      blue: "#034896",
      blueSky: "#5ABDF6",
      blueDark: "#000E35",
      skeleton: "rgba(0, 0, 0, 0.11)",
      green: "#1EB871",
      greenLight: "#e2ece5",
      greenLighter: "#D2F1E3",
      yellowLight: "#FDD504",
    },
    table: {
      whiteDark: "#FAFBFC",
      blueDark: "#034896",
      blue: "#E8F2FE",
      blueLight: "#DDEBFC",
      linkedTextblue: "#1C83F9",
      grayDark: "#999EA4",
    },
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#f6cc32",
    },
  },
  bodyFont: {
    family: "'Noto Sans', sans-serif",
    size: "1rem",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 991,
      lg: 1200,
      xl: 1536,
    },
  },
  headerHeight: 80,
  footerHeight: 40,
  dashboardWidth: 60,
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#f6cc32",
    },
  },
  typography: {
    h1: {
      fontFamily: "'Montserrat', sans-serif",
    },
    h2: {
      fontFamily: "'Montserrat', sans-serif",
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {},
        disabled: {},
        focusVisible: {},
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 0,
        },
      },
    },
  },
})

export default theme
