import { IProperty } from "api-client/types"
import Form from "components/Atoms/Form"
import Loading from "components/Atoms/Loading/Loading"
import { PropertyContext } from "contexts/PropertyContext"
import { SnackbarContext } from "contexts/UseSnackbar"
import React from "react"
import * as yup from "yup"
import PropertyUpdatedDialog from "./Atoms/SubmissionCompleted"
import useAddPropertyFormData from "./Form"

type EditPropertyProps = {
  property: IProperty
  isEditing: boolean
  setIsEditing(state: boolean): void
  setProperty(property: IProperty): void
  name: string
}

const EditPropertyForm: React.FC<EditPropertyProps> = ({
  property,
  isEditing,
  setIsEditing,
  setProperty,
  name,
}: EditPropertyProps) => {
  const { AddNewPropertyFormData, loading } = useAddPropertyFormData()

  const [initialValues, setInitialValues] =
    React.useState<IProperty | null>()
  const [formSubmited, setFormSubmited] = React.useState(false)
  const [failed, setFailed] = React.useState(false)

  const { openSnackbar } = React.useContext(SnackbarContext);

  const { updateProperty } = React.useContext(PropertyContext);
  
  React.useEffect(() => {
    if (property !== null)
      setInitialValues(property)  
  }, [property])

  const handleSubmit = async (values: any) => {
    try {
      await updateProperty(values)
      setProperty(values);
      setFormSubmited(true)
      setIsEditing(true)
      
    } catch (error) {
      openSnackbar({
        type: "error",
        message: "Error updating property",
      });
      setFailed(true)
      setFormSubmited(true)
    }
  }

  if (initialValues !== undefined && !loading)
    return (
      <React.Fragment>
        <Form
          formikProps={{
            validationSchema: ValidationScheme,
            initialValues: initialValues,
            onSubmit: async (values) => {
              await handleSubmit(values)
            },
          }}
          formData={AddNewPropertyFormData}
          submitLabel={"Update"}
          fieldsDisabled={isEditing}
          hideButton={true}
          name={name}
        />
        <PropertyUpdatedDialog
          state={formSubmited}
          onClose={() => setFormSubmited(false)}
          onRetry={() => setFormSubmited(false)}
          error={failed}
        />
      </React.Fragment>
    )
  return <Loading />
}

const ValidationScheme = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  askingPrice: yup.number().required(),
  lotSize: yup.string().required(),
  zipCode: yup.string().required(),
})


export default EditPropertyForm
