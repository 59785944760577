import { db } from "api-client/config/firebase";
import { FirebaseCollection } from "api-client/enums";
import { Promotion } from "api-client/models/Promotion";
import { FirebaseResponse, IPromotion } from "api-client/types";
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";

export class FirebasePromotion {

  fetchPromotions = async (): Promise<FirebaseResponse<Array<IPromotion>>> => {
    try {
      const promotions: Array<IPromotion> = [];
      const collectionRef = collection(db, FirebaseCollection.Promotions);
      const snapshot = await getDocs(collectionRef);
      snapshot.docs.forEach((doc) => {
        promotions.push(
          new Promotion({ ...doc.data(), id: doc.id } as IPromotion)
        );
      });
      return {
        type: "data",
        data: promotions,
      };
    } catch (e) {
      return {
        type: "error",
        error: [],
      };
    }
  };

  createPromotion = async (
    promotion: IPromotion
  ): Promise<FirebaseResponse<string>> => {
    try {
      const collectionRef = collection(db, FirebaseCollection.Promotions);
      await addDoc(collectionRef, promotion);
      return {
        type: "data",
        data: "Promotion added successfully",
      };
    } catch (e) {
        console.log(e);
      return {
        type: "error",
        error: "Error saving promotion",
      };
    }
  };

  updatePromotion = async (promotion: IPromotion) => {
    try{
        const collectionRef = collection(db, FirebaseCollection.Promotions);
        const docRef = doc(collectionRef, promotion.id);
        await updateDoc(docRef, {...promotion});
        return {
            type: 'success',
            data: 'Promotion updated'
        }
    }catch(e){
        return {
            type: 'error',
            error: 'Error updating promotion'
        }
    }
  }

  deletePromotion = async (promotionId: string) : Promise<FirebaseResponse<string>> => {
    try{
        const collectionRef = collection(db, FirebaseCollection.Promotions);
        const docRef = doc(collectionRef, promotionId);
        await deleteDoc(docRef);
        return {
            type: 'data',
            data: 'Promotion deleted successfully'
        }
    }catch(e){
        return {
            type: 'error',
            error: 'Error deleting promotion'
        }
    }
  }

}
