import { TableRow as MuiTableRow, TableRowTypeMap, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { CSSProperties, PropsWithChildren } from "react";

type CN = { className?: string; style?: any | never | unknown | CSSProperties };

const TableRow: React.FC<PropsWithChildren<Partial<TableRowTypeMap>> & CN> = ({ children, className, ...rest }) => {
  const classes = useStyles();
  return (
    <MuiTableRow className={clsx(className, classes.tableRow)} {...rest}>
      {children}
    </MuiTableRow>
  );
};

const MemoizedTableRow = React.memo(TableRow, (prev, next) => {
  return prev.className === next.className;
});

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    color: "inherit",
    height: 48,
    outline: 0,
    verticalAlign: "middle",
    backgroundColor: theme.colors.common.white,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colors.table.whiteDark,
    },
    "&.rowSelected": {
      backgroundColor: theme.colors.table.blue,
      "&:hover": {
        backgroundColor: theme.colors.table.blueLight,
      },
    },
    "&.clickable": {
      cursor: "pointer",
    },
  },
}));

export { MemoizedTableRow as TableRow, TableRow as NonMemoizedTableRow };
