import { Button } from "@aws-amplify/ui-react"
import { Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import CreateBrokerDialog from "components/Brokers/createBrokerDialog"
import EditBrokerDialog from "components/Brokers/EditBrokerDialog"
import ActionCell from "components/Table/ActionCell"
import Table from "components/Table/Table"
import { BrokerContext } from "contexts/BrokerContext"
import DashboardLayout from "layout/DashboardLayout"
import React from "react"
import { Column } from "react-table"

const BrokersView = () => {

  const [createBrokerDialogState, setCreateBrokerDialogState] =
    React.useState(false)
  const classes = useStyles()
  const handleCloseCreateBrokerDialog = () => setCreateBrokerDialogState(false)
  const handleOpenCreateBrokerDialog = () => setCreateBrokerDialogState(true)
  const [editBrokerState, setEditBrokerState] = React.useState(false)
  const [editBroker, setEditBroker] = React.useState(undefined)

  const handleEditBrokerOpen = (broker) => {
    setEditBroker(broker)
    setEditBrokerState(true)
  }

  const {brokersList, isLoadingBrokers, deleteBroker} = React.useContext(BrokerContext);


  const columns: Array<Column<any>> = [
    { Header: "Name", accessor: "name" },
    { Header: "Title", width: 140, accessor: "title" },
    { Header: "Brokerage Name", width: 140, accessor: "brokerageName" },
    { Header: "Email", width: 140, accessor: "email" },
    { Header: "Phone Number", width: 140, accessor: "phoneNumber" },

    {
      Header: "Actions",
      width: 140,
      disableSortBy: true,
      disableFilters: true,
      disableResizing: true,
      maxWidth: 160,
      Cell: ({ row }: any) => (
        <ActionCell
          row={row}
          onEdit={() => handleEditBrokerOpen(row.original)}
          onDelete={() => deleteBroker(row.original.id)}
        />
      ),
    },
  ]

  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box className={classes.appBar}>
          <Typography component={"h2"} variant="h4">
            Brokers
          </Typography>
          <Box className={classes.appBarAction}>
            <Button
              backgroundColor="black"
              color="white"
              onClick={handleOpenCreateBrokerDialog}
            >
              Create Broker
            </Button>
          </Box>
        </Box>
        <Box className={classes.table}>
          <Table
            columns={columns}
            name="brokers-table"
            data={brokersList}
            loading={isLoadingBrokers}
            pagination
          />
        </Box>
      </Box>
      <CreateBrokerDialog
        state={createBrokerDialogState}
        onClose={handleCloseCreateBrokerDialog}
      />
      {editBrokerState && editBroker && (
        <EditBrokerDialog
          state={editBrokerState}
          broker={editBroker}
          onClose={() => setEditBrokerState(false)}
        />
      )}
    </DashboardLayout>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(0, 2, 4),
  },
  appBar: {
    position: "absolute",
    top: `${theme.headerHeight}px`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[2],
    zIndex: 9,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    width: `calc(100% - ${theme.dashboardWidth}px)`,
    marginLeft: "-24px",
    height: 80,
  },
  appBarAction: {
    "& *": {
      marginRight: theme.spacing(2),
    },
  },
  table: {
    marginTop: 90,
    height: "100%",
    display: "flex",
    overflowX: "hidden",
    overflowY: "auto",
  },
  viewButton: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    width: "inherit",
  },
}))
export default BrokersView
