import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { MailType } from "api-client/enums"
import { IEmailTemplate } from "api-client/types"
import { handleSendEmails } from "api-client/utils"
import SendEmailToGroupForm from "components/SendEmailToGroupForm"
import ActionCell from "components/Table/ActionCell"
import { EmailTemplatesContext } from "contexts/EmailTemplates"
import { NewsletterGroupContext } from "contexts/NewsletterGroupContext"
import DashboardLayout from "layout/DashboardLayout"
import React, { useState } from "react"
import { parseFirebaseDateTime } from "utils"

const EmailTemplateView = () => {
  const { templates } = React.useContext(EmailTemplatesContext)
  const [sendEmailModal, setSendEmailModal] = React.useState(false)
  const { groups, fetchGroups } = React.useContext(NewsletterGroupContext)
  const [selectedEmailContent, setSelectedMailContent] = useState<string>("")

  React.useEffect(() => {
    if (groups.length === 0) fetchGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: GridColDef<IEmailTemplate>[] = [
    { field: "id", headerName: "ID", width: 300 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: ({ row }) => <>{row?.name}</>,
    },
    {
      field: "createdAt",
      headerName: "Date/Time",
      width: 300,
      renderCell: ({ row }) => <>{parseFirebaseDateTime(row.createdAt)}</>,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 300,
      renderCell: ({ row }) => (
        <ActionCell
          onSendMail={() => {
            setSendEmailModal(true)
            setSelectedMailContent(row.content)
          }}
          row={row as any}
        />
      ),
    },
  ]

  return (
    <DashboardLayout>
      <DataGrid
        rows={templates}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25]}
        disableSelectionOnClick
        loading={false}
      />
      {sendEmailModal && (
        <SendEmailToGroupForm
          state={sendEmailModal}
          onClose={() => setSendEmailModal(false)}
          groups={groups}
          onSubmit={async ({ group }) => {
            await handleSendEmails({
              type: MailType.Template,
              content: {
                content: selectedEmailContent,
                to: {
                  type: "group",
                  data: group,
                },
              },
            })
            setSendEmailModal(false)
          }}
        />
      )}
    </DashboardLayout>
  )
}

export default EmailTemplateView
