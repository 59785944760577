import { Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
type TableHeadCell = {
    children:
        | Array<JSX.Element>
        | JSX.Element
        | React.ReactChildren
        | React.ReactChild
}

const TableHeadCell: React.FC<TableHeadCell> = ({
    children,
}: TableHeadCell) => {
    const classes = useStyles()
    return <Box className={classes.root}>{children}</Box>
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'center',
    },
}))

export default TableHeadCell
