import { API, Auth } from "aws-amplify"
import { createSettings, updateSettings } from "graphql/mutations"
import { searchSettings } from "graphql/queries"

const getSettings = async () => {
  try {
    const token = (await Auth.currentSession()).getAccessToken()
    const res: any = await API.graphql({
      query: searchSettings,
      authToken: token.getJwtToken(),
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
    const data = res?.data?.searchSettings?.items[0]
    if (data) {
      return data
    } else {
      const token = (await Auth.currentSession()).getAccessToken()
      const res: any = await API.graphql({
        query: createSettings,
        variables: {
          input: {
            newsletter: false,
          },
        },
        authToken: token.getJwtToken(),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
      return res?.data?.searchSettings?.items[0]
    }
  } catch (error) {
    console.log(error)
  }
}

const updateNotificationSetting = async (value: {
  newsletter: boolean
  id: string
}) => {
  try {
    const token = (await Auth.currentSession()).getAccessToken()
    await API.graphql({
      query: updateSettings,
      variables: {
        input: {
          id: value.id,
          newsletter: value.newsletter,
        },
      },
      authToken: token.getJwtToken(),
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  } catch (error) {}
}

export { getSettings, updateNotificationSetting }
