import { MenuItem, TextField, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import { MailType } from "api-client/enums"
import { Property } from "api-client/models/Property"
import { IProperty } from "api-client/types"
import { handleSendEmails } from "api-client/utils"
import EditPropertiesDialog from "components/PropertiesView/EditPropertiesDialog"
import SendEmailToGroupForm from "components/SendEmailToGroupForm"
import SendPropertiesEmailToGroupForm from "components/SendPropertiesEmailToGroupForm"
import ActionCell from "components/Table/ActionCell"
import Table from "components/Table/Table"
import TableToolBar from "components/Table/TableToolBar"
import { Status } from "interfaces"
import DashboardLayout from "layout/DashboardLayout"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Column, Row } from "react-table"
import { App_Routes } from "routes"
import { PropertyContext } from "contexts/PropertyContext"
import { NewsletterGroupContext } from "contexts/NewsletterGroupContext"
import { parseFirebaseDateTime } from "utils"
import { PROPERTY_STATUS } from "CONSTANTS/property"

const PropertiesView = () => {
  const navigate = useNavigate()
  const handleAddProperty = () => navigate(App_Routes.ADD_PROPERTY)
  const {
    filtered,
    isLoading,
    getProperties,
    republishProperty,
    copyProperty,
    deleteProperty,
    deleteProperties,
    importFromFile,
    updatePropertiesStatus,
    searchProperties,
    searchTerm,
  } = React.useContext(PropertyContext)

  const { groups, fetchGroups } = React.useContext(NewsletterGroupContext)

  const [editPropertiesDialogState, setEditPropertiesDialogState] =
    React.useState(false)

  const [selectedProperty, setSelectedProperty] = React.useState<
    Property | undefined
  >(undefined)

  const [selected, setSelected] = React.useState<Array<Row<any>>>([])
  const [status, setStatus] = React.useState('all');

  // MODAL STATES
  const [sendEmailModal, setSendEmailModal] = React.useState(false)
  const [sendPropertiesEmailModal, setSendPropertiesEmailModal] =
    React.useState(false)
  const classes = useStyles()

  React.useEffect(() => {
    if (filtered.length === 0) getProperties(status)
    if (groups.length === 0) fetchGroups()
  }, [])

  const handleSearch = (v) => {
    searchProperties(v, 10)
  }

  const handleOnImportClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]
    if (!file) return

    importFromFile(file)
  }

  const handleEdit = (property: IProperty) => {
    return navigate(`/edit-property/${property.id}`)
  }
  const handleView = (property: IProperty) => {
    return navigate(`/property/${property.id}`)
  }

  const handleSendEmail = (property: Property) => {
    setSelectedProperty(property)
    setSendEmailModal(true)
  }
  const closeEditPropertiesDialog = () => {
    setEditPropertiesDialogState(false)
  }

  const handleSelectedEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    selected: Array<Row<IProperty>>
  ) => {
    e.preventDefault()
    setSelected(selected)
    setEditPropertiesDialogState(true)
  }

  const columns: Array<Column<Property>> = [
    {
      Header: "Title",
      Cell: ({ row }: any) => <>{row.original.title}</>,
      width: 300,
    },
    { Header: "Asking Price", width: 140, accessor: "askingPrice" },
    {
      Header: "Source Name",
      width: 140,
      Cell: ({ row }: any) => (
        <>{row.original.sourceName ? row.original.sourceName : "N/A"}</>
      ),
    },
    {
      Header: "Source PH #",
      width: 140,
      Cell: ({ row }: any) => (
        <>
          {row.original.sourcePhoneNumber
            ? row.original.sourcePhoneNumber
            : "N/A"}
        </>
      ),
    },
    {
      Header: "Purchase Price",
      width: 140,
      Cell: ({ row }: any) => (
        <>{row.original.purchasePrice ? row.original.purchasePrice : "N/A"}</>
      ),
    },
    {
      Header: "Sold Price",
      width: 140,
      Cell: ({ row }: any) => (
        <>{row.original.soldPrice ? row.original.soldPrice : "N/A"}</>
      ),
    },
    {
      Header: "Status",
      width: 140,
      Cell: ({ row }: any) => <>{row.original.status}</>,
    },
    {
      Header: "Created At",
      width: 160,
      Cell: ({ row }: any) => {
        return <>{parseFirebaseDateTime(row.original.createdAt)}</>
      },
    },
    {
      Header: "Actions",
      width: 300,
      disableSortBy: true,
      disableFilters: true,
      disableResizing: true,
      maxWidth: 300,
      Cell: ({ row }: any) => (
        <ActionCell
          row={row}
          onEdit={() => handleEdit(row.original)}
          onDelete={() => deleteProperty(row.original)}
          onCopy={() => copyProperty(row.original)}
          onView={() => handleView(row.original)}
          onRepublish={() => republishProperty(row.original.id)}
          onSendMail={() => handleSendEmail(row.original)}
        />
      ),
    },
  ]

  const handleSelectedDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    selected: Array<Row<IProperty>>
  ) => {
    e.preventDefault()
    deleteProperties(selected)
    getProperties(status)
  }

  const handleSelectedStatusUpdate = (status: Status) => {
    closeEditPropertiesDialog()
    updatePropertiesStatus(selected, status)
    getProperties(status)
  }

  const onStatusFilterChange = (e) => {
    const newStatus = e.target.value;
    if(status === newStatus) return;
    setStatus(newStatus);
    getProperties(newStatus)
  }

  return (
    <DashboardLayout>
      <Box className={classes.pageContainer}>
        <Box>
          <Typography component={"h2"} variant='h4'>
            Properties
          </Typography>
        </Box>
        <TableToolBar
          onSearch={(v) => handleSearch(v)}
          onAdd={handleAddProperty}
          onImport={(v) => handleOnImportClick(v)}
          searchValue={searchTerm}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "350px",
            }}>
            <Box>Filter Properties by Status</Box>
            <TextField value={status} select onChange={onStatusFilterChange}>
              <MenuItem value='all'>All</MenuItem>
              {PROPERTY_STATUS.map((status) => (
                <MenuItem value={status.value}>{status.label}</MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <Table
          columns={columns}
          name='properties-table'
          data={filtered}
          loading={isLoading}
          pagination
          onSelectedDelete={handleSelectedDelete}
          onSelectedEdit={handleSelectedEdit}
          onSendEmail={(selected) => {
            setSendPropertiesEmailModal(true)
            setSelected(selected)
          }}
        />
        <EditPropertiesDialog
          state={editPropertiesDialogState}
          onClose={() => closeEditPropertiesDialog()}
          onUpdate={(status) => handleSelectedStatusUpdate(status)}
        />
      </Box>
      {sendEmailModal && (
        <SendEmailToGroupForm
          state={sendEmailModal}
          onClose={() => setSendEmailModal(false)}
          groups={groups}
          onSubmit={async ({ group }) => {
            await handleSendEmails({
              type: MailType.Property,
              content: {
                property: selectedProperty,
                to: {
                  type: "group",
                  data: group,
                },
              },
            })
            setSendEmailModal(false)
          }}
        />
      )}
      {sendPropertiesEmailModal && (
        <SendPropertiesEmailToGroupForm
          state={sendPropertiesEmailModal}
          onClose={() => setSendPropertiesEmailModal(false)}
          groups={groups}
          onSubmit={async ({ group }) => {
            await handleSendEmails({
              type: MailType.Properties,
              content: {
                properties: selected,
                to: {
                  type: "group",
                  data: group,
                },
              },
            })
            setSendPropertiesEmailModal(false)
          }}
        />
      )}
    </DashboardLayout>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(0, 2),
  },
  viewButton: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    gap: 10,
    width: "inherit",
  },
}))
export default PropertiesView
