import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
} from "@firebase/firestore";
import { db } from "api-client/config/firebase";
import { FirebaseCollection } from "api-client/enums";
import { IOffer, FirebaseResponse } from "api-client/types";
import { where } from "firebase/firestore";

export class FirebasePropertyOffer {
  getLatestOffers = async (): Promise<
    FirebaseResponse<Array<IOffer>>
  > => {
    try {
      const collectionRef = collection(db, FirebaseCollection.Offers);
      const queryRef = query(
        collectionRef,
        orderBy("createdAt", "desc"),
        limit(30)
      );
      const snapshot = await getDocs(queryRef);
      const offers = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as IOffer;
      });
      return {
        type: "data",
        data: offers,
      };
    } catch (error) {
        console.log(error)
      return {
        type: "error",
        error: error,
      };
    }
  };

  getOffersForProperty = async (
    propertyId: string
  ): Promise<FirebaseResponse<Array<IOffer>>> => {
    try {
      const collectionRef = collection(
        db,
        FirebaseCollection.Offers
      );
      const queryRef = query(collectionRef, where('propertyId', '==', propertyId));
      const snapshot = await getDocs(queryRef);
      const offers = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as IOffer;
      });
      return {
        type: "data",
        data: offers,
      };
    } catch (error) {
      return {
        type: "error",
        error: error,
      };
    }
  };
}
