import { FirebaseMail } from "api-client/firebase/mail";
import { IMail } from "api-client/types";
import React from "react";

type MailProviderProps = {
  mails: IMail[];
  isLoading: boolean;
  fetchAllMails?(): void;
};

const initialState: MailProviderProps = {
  mails: [],
  isLoading: false,
};

export const MailContext = React.createContext(initialState);

const { Consumer: MailConsumer, Provider } = MailContext;

const mailAPI = new FirebaseMail();

const MailProvider: React.FC = ({ children }) => {
  const [mails, setMails] = React.useState(initialState.mails);
  const [isLoading, setLoading] = React.useState(initialState.isLoading);

  const fetchAllMails = async () => {
    setLoading(true);
    const res = await mailAPI.getMails();
    if (res.type === "error") return;
    setMails(res.data);
    setLoading(false);
  };

  const values = {
    mails,
    isLoading,
    fetchAllMails,
  };

  return <Provider value={values}>{children}</Provider>;
};

export { MailConsumer, MailProvider };
